/* eslint-disable no-param-reassign */
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { BackendError, errorMessage } from '@services';

import { getToken } from './utils';

export const Axios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});
Axios.defaults.headers.common['Content-Type'] = 'application/json';

export const setAuthToken = async () => {
  const token = await getToken();
  Axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

Axios.defaults.validateStatus = (status) => {
  if (status === 401 || status === 403) {
    Auth.signOut();
  }

  if (status >= 400) {
    return false;
  }

  return true;
};

Axios.interceptors.response.use(undefined, (e) => {
  const error = e as BackendError;
  // let { message } = error;

  if (error.response?.data) {
    const { messages } = error.response.data;
    // message = Array.isArray(messages) ? messages.join('\n') : messages;
    if (Array.isArray(messages)) messages.map((item) => errorMessage(item));
    else errorMessage(messages);
  }

  // if (message) errorMessage(message);

  throw error;
});

Axios.interceptors.request.use(
  (config) =>
    new Promise((resolve) => {
      Auth.currentSession()
        .then((session) => {
          const idTokenExpire = session.getIdToken().getExpiration();
          const refreshToken = session.getRefreshToken();
          const currentTimeSeconds = Math.round(+new Date() / 1000);
          const accessToken = `Bearer ${session.getAccessToken().getJwtToken()}`;
          if (idTokenExpire < currentTimeSeconds) {
            Auth.currentAuthenticatedUser().then((res) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              res.refreshSession(refreshToken, (err) => {
                if (err) {
                  Auth.signOut();
                } else {
                  if (!config.headers) {
                    config.headers = {};
                  }
                  config.headers.Authorization = accessToken;
                  resolve(config);
                }
              });
            });
          } else {
            if (!config.headers) {
              config.headers = {};
            }
            config.headers.Authorization = accessToken;
            resolve(config);
          }
        })
        .catch(() => {
          // No logged-in user: don't set auth header
          resolve(config);
        });
    }),
);
