import { FC } from 'react';
import cn from 'classnames';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { Axios } from '@helpers';
import { Box, InputLabel, SelectChangeEvent, SelectProps } from '@mui/material';

import { LinkStatuses } from '@services';
import { LINK_STATUS_CONTENT } from '@constants';

import { SelectStyled, MenuItemStyled } from '../Filters/styles';

interface Props extends SelectProps {
  value?: LinkStatuses | null | 'mixed';
  links: string[];
  refetch: () => void;
}

export const LinkStatusSelect: FC<Props> = ({ value, links, refetch, className, ...props }) => {
  const { assetId } = useParams();

  const { mutate: changeStatus } = useMutation<
    { links: string[]; status: LinkStatuses },
    unknown,
    unknown
  >((data) => Axios.patch(`/admin/models/${assetId}/links/status`, data), {
    onSuccess: refetch,
  });

  const handleOnChange = (event: SelectChangeEvent<unknown>) => {
    event.stopPropagation();
    changeStatus({ links, status: event.target.value as LinkStatuses });
  };

  return (
    <Box display="flex" alignItems="center" width="285px">
      <InputLabel sx={{ marginBottom: '0', width: '60px' }}>Status: </InputLabel>
      <SelectStyled
        placeholder="Select option"
        sx={{ width: '220px', height: '40px' }}
        color={value ? LINK_STATUS_CONTENT[value]?.color : undefined}
        value={value}
        onChange={handleOnChange}
        className={cn(className, 'small')}
        {...props}
      >
        {Object.entries(LinkStatuses).map(([, key]) => {
          if (key === LinkStatuses.Invalid) {
            return value === LinkStatuses.Invalid ? (
              <MenuItemStyled key={key} value={key} color={LINK_STATUS_CONTENT[key].color}>
                {LINK_STATUS_CONTENT[key].label}
              </MenuItemStyled>
            ) : null;
          }
          return (
            <MenuItemStyled key={key} value={key} color={LINK_STATUS_CONTENT[key].color}>
              {LINK_STATUS_CONTENT[key].label}
            </MenuItemStyled>
          );
        })}
        {value === 'mixed' && (
          <MenuItemStyled value="mixed" color="black">
            Mixed
          </MenuItemStyled>
        )}
      </SelectStyled>
    </Box>
  );
};
