import { useState, useCallback, SetStateAction, Dispatch } from 'react';

export const useModalState = (
  initialState: boolean,
): [boolean, Dispatch<SetStateAction<boolean>>, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(initialState);

  const closeModal = useCallback(() => setIsOpen(false), []);

  const openModal = useCallback(() => setIsOpen(true), []);

  return [isOpen, setIsOpen, openModal, closeModal];
};
