import { FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { InviteUserModal } from '@organisms';

import { Button } from './styles';

export const UsersHeader: FC = () => {
  const [inviteOpen, setInviteOpen] = useState(false);

  return (
    <Box mb="16px" display="flex" justifyContent="space-between">
      <Typography variant="h4">Users</Typography>
      <Button variant="contained" onClick={() => setInviteOpen(true)}>
        <PersonAddIcon />
        Invite user
      </Button>
      {inviteOpen && <InviteUserModal open={inviteOpen} onClose={() => setInviteOpen(false)} />}
    </Box>
  );
};
