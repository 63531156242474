import {
  AssetsType,
  BookAssetProperties,
  GameAssetProperties,
  InputTypes,
  ModelAssetProperties,
  MovieAssetProperties,
  MusicAssetProperties,
  NFTAssetProperties,
  Plans,
  SoftwareAssetProperties,
  TVAssetProperties,
} from '@services';
import * as yup from 'yup';
import { PLANS_LABEL } from './constants';

export const assetsConfig = {
  [AssetsType.Games]: {
    placeholder: 'Video game name',
    fields: [
      {
        key: GameAssetProperties.Publisher,
        type: InputTypes.Input,
        label: 'Video game publisher',
        placeholder: 'Add publisher',
        required: true,
      },
      {
        key: GameAssetProperties.Distributor,
        type: InputTypes.Input,
        label: 'Video game distributor',
        placeholder: 'Add distributor',
        required: true,
      },
    ],
    defaultValues: {
      [GameAssetProperties.Publisher]: '',
      [GameAssetProperties.Distributor]: '',
    },
    validationObjectSchema: {
      [GameAssetProperties.Publisher]: yup.string().label('Publisher').required().min(2),
      [GameAssetProperties.Distributor]: yup.string().label('Distributor').required().min(2),
    },
  },
  [AssetsType.Books]: {
    placeholder: 'Book name',
    fields: [
      {
        type: InputTypes.Input,
        key: BookAssetProperties.AuthorName,
        label: 'Author name',
        placeholder: 'Add author name',
        required: true,
      },
      {
        type: InputTypes.Input,
        key: BookAssetProperties.Publisher,
        label: 'Publisher',
        placeholder: 'Add publisher',
        required: true,
      },
      {
        type: InputTypes.Input,
        key: BookAssetProperties.Genre,
        label: 'Genre',
        placeholder: 'Add genre',
        required: true,
      },
      {
        type: InputTypes.Date,
        key: BookAssetProperties.DateOfPublishing,
        label: 'Date of publishing',
        placeholder: 'Choose date of publishing',
        required: true,
      },
    ],
    defaultValues: {
      [BookAssetProperties.AuthorName]: '',
      [BookAssetProperties.Genre]: '',
      [BookAssetProperties.Publisher]: '',
      [BookAssetProperties.DateOfPublishing]: null,
    },
    validationObjectSchema: {
      [BookAssetProperties.AuthorName]: yup.string().label('Author').required().min(2),
      [BookAssetProperties.Genre]: yup.string().label('Genre').required().min(2),
      [BookAssetProperties.Publisher]: yup.string().label('Publisher').required().min(2),
      [BookAssetProperties.DateOfPublishing]: yup.date().label('Date').required(),
    },
  },
  [AssetsType.Movies]: {
    placeholder: 'Movie title',
    fields: [
      {
        type: InputTypes.Input,
        key: MovieAssetProperties.Director,
        label: 'Movie director',
        placeholder: 'Add movie director',
        required: true,
      },
      {
        type: InputTypes.Input,
        key: MovieAssetProperties.Producer,
        label: 'Movie producer',
        placeholder: 'Add movie producer',
        required: true,
      },
      {
        type: InputTypes.Multiselect,
        key: MovieAssetProperties.Released,
        label: 'Where will the Movie be released?',
        required: true,
        options: [
          {
            label: 'Physical release',
            value: 'physical',
          },
          {
            label: 'Cinema',
            value: 'cinema',
          },
          {
            label: 'Streaming',
            value: 'streaming',
          },
        ],
      },
    ],
    defaultValues: {
      [MovieAssetProperties.Director]: '',
      [MovieAssetProperties.Producer]: '',
      [MovieAssetProperties.Released]: [],
    },
    validationObjectSchema: {
      [MovieAssetProperties.Director]: yup.string().label('Director').required().min(2),
      [MovieAssetProperties.Producer]: yup.string().label('Producer').required().min(2),
      [MovieAssetProperties.Released]: yup
        .array()
        .label('Filter Removals')
        .required()
        .of(yup.string().min(2))
        .min(1),
    },
  },
  [AssetsType.Music]: {
    placeholder: 'Artist name',
    fields: [
      {
        type: InputTypes.Input,
        key: MusicAssetProperties.Label,
        label: 'Label',
        placeholder: 'Add label',
        required: true,
      },
      {
        type: InputTypes.Input,
        key: MusicAssetProperties.AlbumTitle,
        label: 'Title of Album',
        placeholder: 'Add title of album',
        required: true,
      },
      {
        type: InputTypes.Input,
        key: MusicAssetProperties.SongTitle,
        label: 'Title of Song',
        placeholder: 'Add title of song',
        required: true,
      },
    ],
    defaultValues: {
      [MusicAssetProperties.Label]: '',
      [MusicAssetProperties.AlbumTitle]: '',
      [MusicAssetProperties.SongTitle]: '',
    },
    validationObjectSchema: {
      [MusicAssetProperties.Label]: yup.string().label('Label').required().min(2),
      [MusicAssetProperties.AlbumTitle]: yup.string().label('Title of Album').required().min(2),
      [MusicAssetProperties.SongTitle]: yup.string().label('Title of Song').required().min(2),
    },
  },
  [AssetsType.NFT]: {
    placeholder: 'NFT title',
    fields: [
      {
        type: InputTypes.Input,
        key: NFTAssetProperties.Id,
        label: 'NFT ID',
        placeholder: 'Add NFT ID',
        required: true,
      },
      {
        type: InputTypes.Input,
        key: NFTAssetProperties.Creator,
        label: 'NFT creator',
        placeholder: 'Add NFT creator',
        required: true,
      },
      {
        type: InputTypes.Input,
        key: NFTAssetProperties.OriginalStore,
        label: 'NFT original store',
        placeholder: 'Add NFT original store',
        required: true,
      },
    ],
    defaultValues: {
      [NFTAssetProperties.Id]: '',
      [NFTAssetProperties.Creator]: '',
      [NFTAssetProperties.OriginalStore]: '',
    },
    validationObjectSchema: {
      [NFTAssetProperties.Id]: yup.string().label('ID').required().min(2),
      [NFTAssetProperties.Creator]: yup.string().label('Creator').required().min(2),
      [NFTAssetProperties.OriginalStore]: yup.string().label('Original store').required().min(2),
    },
  },
  [AssetsType.Software]: {
    placeholder: 'Software name',
    fields: [
      {
        type: InputTypes.Input,
        key: SoftwareAssetProperties.Type,
        label: 'Type ot software',
        placeholder: 'Add type ot software',
        required: true,
      },
      {
        type: InputTypes.Input,
        key: SoftwareAssetProperties.Creator,
        label: 'Software creator',
        placeholder: 'Add software creator',
        required: true,
      },
      {
        type: InputTypes.Input,
        key: SoftwareAssetProperties.Version,
        label: 'Software version',
        placeholder: 'Add software version',
        required: true,
      },
      {
        type: InputTypes.Input,
        key: SoftwareAssetProperties.License,
        label: 'License',
        placeholder: 'Add license',
        required: true,
      },
    ],
    defaultValues: {
      [SoftwareAssetProperties.Type]: '',
      [SoftwareAssetProperties.Creator]: '',
      [SoftwareAssetProperties.Version]: '',
      [SoftwareAssetProperties.License]: '',
    },
    validationObjectSchema: {
      [SoftwareAssetProperties.Type]: yup.string().label('Type').required().min(2),
      [SoftwareAssetProperties.Creator]: yup.string().label('Creator').required().min(2),
      [SoftwareAssetProperties.Version]: yup.string().label('Version').required().min(2),
      [SoftwareAssetProperties.License]: yup.string().label('License').required().min(2),
    },
  },
  [AssetsType.TV]: {
    placeholder: 'Name of Show',
    fields: [
      {
        type: InputTypes.Input,
        key: TVAssetProperties.Publisher,
        label: 'Name of publisher',
        placeholder: 'Add name of publisher',
        required: true,
      },
      {
        type: InputTypes.Input,
        key: TVAssetProperties.StudioName,
        label: 'Name of studio',
        placeholder: 'Add name of studio',
        required: true,
      },
      {
        type: InputTypes.Input,
        key: TVAssetProperties.ReleaseYear,
        label: 'Year of release',
        placeholder: 'An example: 2010',
        required: true,
      },
    ],
    defaultValues: {
      [TVAssetProperties.Publisher]: '',
      [TVAssetProperties.StudioName]: '',
      [TVAssetProperties.ReleaseYear]: '',
    },
    validationObjectSchema: {
      [TVAssetProperties.Publisher]: yup.string().label('Publisher').required().min(2),
      [TVAssetProperties.StudioName]: yup.string().label('Studio').required().min(2),
      [TVAssetProperties.ReleaseYear]: yup
        .string()
        .label('Release year')
        .required()
        .test('len', 'Must be valid year', (val) => Number(val) > 1800 && Number(val) < 2100),
    },
  },
  [AssetsType.Models]: {
    placeholder: 'Creator name',
    defaultValues: {
      [ModelAssetProperties.SocialMedia]: [],
      [ModelAssetProperties.Plan]: '',
    },
    fields: [
      {
        type: InputTypes.Radio,
        key: ModelAssetProperties.Plan,
        label: 'Select plan',
        placeholder: 'Select plan',
        options: [
          {
            value: Plans.Star,
            label: PLANS_LABEL[Plans.Star],
          },
          {
            value: Plans.Elite,
            label: PLANS_LABEL[Plans.Elite],
          },
          {
            value: Plans.Vip,
            label: PLANS_LABEL[Plans.Vip],
          },
          {
            value: Plans.Platinum,
            label: PLANS_LABEL[Plans.Platinum],
          },
        ],
      },
      {
        type: InputTypes.AddSocialMediaAccounts,
        label: 'Social Media',
        placeholder: 'Social media',
        key: ModelAssetProperties.SocialMedia,
      },
    ],
    validationObjectSchema: {
      [ModelAssetProperties.SocialMedia]: yup.array().notRequired(),
      [ModelAssetProperties.Plan]: yup.string().required(),
    },
  },
  [AssetsType.Talents]: {
    placeholder: 'Talent name',
    defaultValues: {},
    fields: [],
    validationObjectSchema: {},
  },
  [AssetsType.TVPLE]: {
    placeholder: 'Event name',
    defaultValues: {},
    fields: [],
    validationObjectSchema: {},
  },
  [AssetsType.Merchandise]: {
    placeholder: 'Merchanise name',
    defaultValues: {},
    fields: [],
    validationObjectSchema: {},
  },
};

export const ccMusicAssetConfig = {
  fields: [
    {
      type: InputTypes.Input,
      key: MusicAssetProperties.Label,
      label: 'Label',
      placeholder: 'Add label',
      required: true,
    },
    {
      type: InputTypes.Input,
      key: MusicAssetProperties.AlbumTitle,
      label: 'Title of Album',
      placeholder: 'Add title of album',
      required: true,
    },
  ],
  validationObjectSchema: {
    [MusicAssetProperties.Label]: yup.string().label('Label').required().min(2),
    [MusicAssetProperties.AlbumTitle]: yup.string().label('Title of Album').required().min(2),
  },
};
