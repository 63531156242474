import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import { CompanyType, ModelType, successMessage } from '@services';
import { ROUTES } from '@constants';
import { useConfirmationModal } from '@hooks';
import { Axios } from '@helpers';

import { Table } from '@molecules';
import { ConfirmModal } from '@organisms/Modals';

import { getColumns } from './utils';

export const AccountsTable: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [toDeleteRow, setDeleteRow] = useState<ModelType | null>(null);

  const { mutate: deleteAsset, isLoading } = useMutation<unknown, unknown, string>(
    (assetId) => Axios.delete(`/admin/accounts/${id}/models/${assetId}`),
    {
      onSuccess: () => {
        successMessage(`${toDeleteRow?.username} has been removed!`);
        setDeleteRow(null);
      },
    },
  );

  const submitDelete = () => {
    if (toDeleteRow) {
      deleteAsset(toDeleteRow.id);
    }
  };

  const [open, onOpen, onCancel, onSubmit] = useConfirmationModal(false, submitDelete);

  const onEdit = useCallback(({ id: assetId, isRemoved }) => {
    if (!isRemoved) {
      sessionStorage.setItem('id-accounts-table', window.location.search || '');
      navigate(
        `/${ROUTES.AssetAccount}/${CompanyType.Business}/${id}/${assetId}/${ROUTES.AccountDetails}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = useCallback(
    (row) => {
      setDeleteRow(row);
      onOpen();
    },
    [onOpen],
  );

  const columns = useMemo(
    () =>
      getColumns({
        onEdit,
        onDelete,
      }),
    [onEdit, onDelete],
  );

  return (
    <>
      {!isLoading && (
        <Table<ModelType>
          queryName={`business-account-models-${id}`}
          columns={columns}
          apiRoute={`/admin/accounts/${id}/models`}
          onRowClick={onEdit}
          refetchQueryName={`business-account-models-${id}`}
          // withoutSearch
        />
      )}
      <ConfirmModal
        open={open}
        onCancel={onCancel}
        onSubmit={onSubmit}
        title={`Are you sure you want to delete\n${toDeleteRow?.username}?`}
        submitText="Yes, delete"
        submitColor="error"
      />
    </>
  );
};
