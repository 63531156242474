import { FC } from 'react';
import { ScanHistoryItemResponse } from '@services';
import { Table } from '@molecules';
import { columns } from './utils';

interface Props {
  id: string;
  assetId: string;
}

export const ScanHistoryTable: FC<Props> = ({ id, assetId }) => (
  <Table<ScanHistoryItemResponse>
    queryName={`scan-history-table/${id}/${assetId}`}
    apiRoute={`/admin/companies/${id}/assets/${assetId}/scan-history`}
    columns={columns}
    withoutSearch
  />
);
