import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';
import { ScanHistoryItemResponse } from '@services';
import { capitalize } from '@helpers';
import { DATE_FORMAT } from '@constants';
import { Chip, Typography } from '@mui/material';

const STATUS_CONFIG = {
  success: 'Succesess',
  fail: 'Failed',
  zero_results: 'Zero Results',
};

const handleChipColor = (value: string) => {
  switch (value) {
    case 'fail':
      return 'error';
    case 'success':
      return 'success';
    case 'zero_results':
      return 'primary';
    default:
      return 'default';
  }
};

export const columns: GridColDef<ScanHistoryItemResponse>[] = [
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 110,
    flex: 1,
    renderCell: ({ row: { scannedAt } }) => (
      <Typography variant="body2">{dayjs(scannedAt).format(DATE_FORMAT)}</Typography>
    ),
  },
  {
    field: 'atSource',
    headerName: 'At Source',
    minWidth: 150,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'googleImages',
    headerName: 'Google images',
    minWidth: 169,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'googleWeb',
    headerName: 'Google web',
    minWidth: 169,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'runType',
    headerName: 'Run type',
    minWidth: 169,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: ({ value }) => capitalize(value),
  },
  {
    field: 'publicityLevel',
    headerName: 'Scan type',
    minWidth: 185,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: ({ value }) => (value ? capitalize(value) : undefined),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 122,
    renderCell: ({ value }) => (
      <Chip
        label={STATUS_CONFIG[value as 'success' | 'fail' | 'zero_results']}
        color={handleChipColor(value)}
      />
    ),
  },
];
