import { GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro';
import { MenuAction } from '@services';
import { ActionsButtonMenu } from '@atoms';

// Note: this is a function, not a component
export const renderActionsButtonMenu =
  (actionsCreator: (row: GridRowModel) => MenuAction[]) =>
  ({ row }: GridRenderCellParams) => {
    const options = actionsCreator(row);
    if (!options.length || row.isRemoved) {
      return null;
    }

    return <ActionsButtonMenu options={options} />;
  };
