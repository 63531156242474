import { useCallback, useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import isEqual from 'lodash.isequal';
import { AnyObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStateWithReset } from '@hooks/useStateWithReset';
import { User } from '@services';

interface Props<T extends FieldValues> {
  schema: AnyObjectSchema;
  defaultValues: T;
  defaultImage: string;
  onSubmit: (data: FieldValues, file: File | null) => void;
}

export const useFormWithPhoto = <T extends FieldValues>({
  schema,
  defaultValues,
  defaultImage,
  onSubmit,
}: Props<T>) => {
  const [{ previewImg, file }, setSrc, resetSrc] = useStateWithReset<{
    previewImg?: string | ArrayBuffer;
    file: File | null;
  }>({ previewImg: defaultImage, file: null }, { previewImg: defaultImage, file: null });
  const {
    handleSubmit,
    control,
    reset: resetFields,
    formState: { errors, isValid },
    watch,
  } = useForm<User>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onChange',
    delayError: 700,
  });

  const values = watch();
  const isDirtyValues = !isEqual(values, defaultValues);
  const isDirtyImage = Boolean(file);

  const onUpload = useCallback(
    async (newFile: File) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(newFile);
      fileReader.onload = (e: ProgressEvent<FileReader>) => {
        if (e?.target?.result) {
          const newImage = e.target.result;
          setSrc({
            previewImg: newImage,
            file: newFile,
          });
        }
      };
    },
    [setSrc],
  );

  const reset = () => {
    resetSrc();
    resetFields(defaultValues);
  };

  const handleSubmitWithFile = async (data: FieldValues) => {
    await onSubmit(data, file);
    setSrc({ previewImg, file: null });
  };

  useEffect(() => {
    resetFields(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return {
    isDirtyValues,
    isDirtyImage,
    isDirty: isDirtyValues || isDirtyImage,
    errors,
    isValid,
    previewImg,
    control,
    resetSrc,
    resetFields,
    reset,
    watch,
    onUpload,
    handleSubmit: handleSubmit(handleSubmitWithFile),
  };
};
