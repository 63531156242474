import { Box, styled } from '@mui/material';

export const StyledCollapseTableChildWrapper = styled(Box)`
  display: flex;
  margin: 8px 0 8px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  flex-direction: column;
`;
export const StyledCollapseTableChild = styled(Box)`
  padding: 8px;
  padding-left: 12px;
  border-bottom: 1px solid #e0e0e0;
  gap: 4px;
  &:last-child {
    border-bottom: unset;
  }
`;
export const StyledCollapseTableCell = styled(Box)`
  align-items: center;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
`;
