import { FC, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { Loader } from '@atoms';
import { Axios, getToken, queryClient } from '@helpers';
import { ROUTES, HAS_SOCIAL_ERROR } from '@constants';

export const SocialAuth: FC = () => {
  const navigate = useNavigate();

  const redirectToLogin = async () => {
    sessionStorage.setItem(HAS_SOCIAL_ERROR, 'true');
    Auth.signOut();
  };

  const { mutate: onSubmit, isLoading } = useMutation<unknown, unknown, string>(
    (accessToken) =>
      Axios.post('/auth/google/sign-in', { accessToken, platform: 'admin' }).then(
        (res) => res.data,
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries('profile');
        navigate(`/${ROUTES.Users}`);
      },
      onError: () => {
        redirectToLogin();
      },
    },
  );

  useEffect(() => {
    getToken()
      .then((accessToken) => {
        onSubmit(accessToken);
      })
      .catch(() => {
        redirectToLogin();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader isLoading={isLoading} />;
};
