import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ActiveStatus, TableUser } from '@services';
import { ROUTES } from '@constants';
import { Table } from '@molecules';

import { columns } from './utils';

export const UserTable: FC = () => {
  const navigate = useNavigate();

  return (
    <Table<TableUser>
      columns={columns}
      apiRoute="/admin/users"
      queryName="users"
      onRowClick={({ id, status }) => {
        if (status !== ActiveStatus.EmailSent) {
          sessionStorage.setItem('users-table', window.location.search || '');
          navigate(`/${ROUTES.Users}/${id}`);
        }
      }}
    />
  );
};
