import { Box, styled } from '@mui/material';

export const UserPaymentStatusWrap = styled(Box)`
  height: 32px;
  padding-right: 10px;
  padding-left: 7px;
  display: flex;
  align-items: center;
  background-color: #44aefb;
  border-radius: 16px;
  color: ${(props) => props.theme.palette.common.white};

  &.deleted {
    background-color: rgba(0, 0, 0, 0.26);
  }

  svg {
    margin-right: 6px;
  }
`;
