import { useEffect, useRef } from 'react';

interface IUseIntersectionObserver {
  /**
   * Must wrapped in `useCallback`, only fetch data when `!loading` and `hasMore`
   * @returns undefined
   */
  loadMore: () => void;
}

export const DEFAULT_THRESHOLD = 200;
export const DEFAULT_THRESHOLD_STRING = `${DEFAULT_THRESHOLD}px`;

// eslint-disable-next-line no-undef
const options: IntersectionObserverInit = {
  root: null,
  rootMargin: DEFAULT_THRESHOLD_STRING,
  threshold: 1,
};

export const useIntersectionObserver = ({ loadMore }: IUseIntersectionObserver) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((observerEntry) => {
      if (observerEntry[0].isIntersecting) loadMore();
    }, options);

    const el = ref;

    if (el.current) observer.observe(el.current);

    return () => {
      if (el.current) observer.unobserve(el.current);
    };
  }, [loadMore, ref]);

  return { ref };
};
