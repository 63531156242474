import { styled, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CloseButton = styled(CloseIcon)`
  align-self: center;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.54);
  margin-top: 3px;
  margin-left: 12px;
  cursor: pointer;
  &:hover {
    color: black;
  }
`;

export const Container = styled(Button)`
  border-radius: 12px;
  width: 300px;
  min-width: 300px;
  display: flex;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  position: relative;
`;

export const NameText = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const ExtensionText = styled(Typography)`
  text-transform: uppercase;
`;
