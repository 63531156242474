import { FC } from 'react';
import cn from 'classnames';
import { useMutation } from 'react-query';
import { Axios } from '@helpers';
import { Box, InputLabel, SelectChangeEvent, SelectProps } from '@mui/material';

import { COPYRIGHT_STATUS_CONTENT } from '@constants';

import { SelectStyled, MenuItemStyled } from '../Filters/styles';
import { CopyrightContentStatus } from '@services/types/copyright';

interface Props extends SelectProps {
  value?: CopyrightContentStatus | null | 'mixed';
  links: string[];
  refetch: () => void;
}

export const CopyrightStatusSelect: FC<Props> = ({
  value,
  links,
  refetch,
  className,
  ...props
}) => {
  const { mutate: changeStatus } = useMutation<
    { ids: string[]; status: CopyrightContentStatus },
    unknown,
    unknown
  >((data) => Axios.put(`/admin/copyright-contents/status`, data), {
    onSuccess: refetch,
  });

  const handleOnChange = (event: SelectChangeEvent<unknown>) => {
    event.stopPropagation();
    changeStatus({ ids: links, status: event.target.value as CopyrightContentStatus });
  };

  return (
    <Box display="flex" alignItems="center" width="375px">
      <InputLabel sx={{ marginBottom: '0', width: '150px' }}>Copyright Status: </InputLabel>
      <SelectStyled
        placeholder="Select option"
        sx={{ width: '220px', height: '40px' }}
        color={value ? COPYRIGHT_STATUS_CONTENT[value]?.color : undefined}
        value={value}
        onChange={handleOnChange}
        className={cn(className, 'small')}
        {...props}
      >
        {Object.entries(CopyrightContentStatus).map(([, key]) => {
          return (
            <MenuItemStyled key={key} value={key} color={COPYRIGHT_STATUS_CONTENT[key].color}>
              {COPYRIGHT_STATUS_CONTENT[key].label}
            </MenuItemStyled>
          );
        })}
      </SelectStyled>
    </Box>
  );
};
