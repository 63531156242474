import { FC, SyntheticEvent, useState } from 'react';
import { Button } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { CloseButton } from './styles';

interface Props {
  onChange?: (file: File | null) => void;
}

export const AttachFileButton: FC<Props> = ({ onChange }) => {
  const [file, setFile] = useState<File | null>(null);

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    const [newFile] = input.files;
    setFile(newFile);
    if (onChange) onChange(newFile);
  };

  const resetFile = (e: SyntheticEvent<unknown>) => {
    e.preventDefault();
    setFile(null);
    if (onChange) onChange(null);
  };

  const text = file?.name || 'Attach CSV file';

  return (
    <Button
      variant={file ? 'text' : 'outlined'}
      component="label"
      color="black"
      sx={{
        width: 'fit-content',
        background: file ? 'white' : '#EDEDED',
        padding: file ? '0' : '0 22px 0px 18px',
        marginTop: '16px',
      }}
      startIcon={<AttachFileIcon />}
      endIcon={file && <CloseButton onClick={resetFile} />}
    >
      {text}
      <input
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={handleOnChange}
        hidden
      />
    </Button>
  );
};

export default AttachFileButton;
