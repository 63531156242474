import { toast } from 'react-toastify';

export const successMessage = (message: string): void => {
  toast.success(message, { autoClose: 2500 });
};

export const errorMessage = (message: string): void => {
  toast.error(message);
};

export const warningMessage = (message: string): void => {
  toast.warn(message);
};
