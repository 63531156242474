import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Box, InputLabel } from '@mui/material';
import { ChipsInput } from './ChipsInput';
import { MuiChipsInputProps } from './types';
import { getErrorMessage } from './utils';

interface Props<T extends FieldValues> extends MuiChipsInputProps {
  control: Control<T, object>;
  name: Path<T>;
  label: string;
}

export const ControlledChipsInput = <T extends object>({
  control,
  name,
  label,
  disabled,
  required,
  ...props
}: Props<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field, formState: { errors } }) => {
      const { errorIds, errorText } = getErrorMessage(errors, name);

      return (
        <Box width="100%" sx={{ pointerEvents: disabled ? 'none' : '' }}>
          <InputLabel htmlFor={name}>
            {label}
            {required && <span>&nbsp;*</span>}
          </InputLabel>
          <ChipsInput
            error={!!errorText}
            helperText={errorText}
            placeholder="Add keywords"
            disabled={disabled}
            errorIds={errorIds}
            {...field}
            {...props}
          />
        </Box>
      );
    }}
  />
);
