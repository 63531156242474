import { Box, IconButton } from '@mui/material';
import {
  SelectCopyrightStatuses,
  SelectLinkStatuses,
  SourceType,
  TableSelectType,
} from '@services';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {
  AssignMemberSelect,
  ChangeStatusSelect,
  DocumentDeleteSelected,
  InvoiceDeleteSelected,
  LinkDeleteSelected,
  LinkSourceTypeSelect,
  LinkStatusSelect,
} from '@molecules';
import { CopyrightStatusSelect } from '@molecules/Selects/CopyrightStatusSelect';

const Wrap = styled(Box)`
  display: flex;
  align-items: center;
  background: #daeffe;
  border: 1px solid #b4dffd;
  border-radius: 8px;
  height: 70px;
  justify-content: space-between;
  padding: 0 16px;
  margin-bottom: 16px;
`;

interface SelectedRowProps<T> {
  items: T[];
  count: number;
  cancel: () => void;
  refetch: () => void;
  value?: string;
  multipleValue?: any;
  type?: TableSelectType;
}

export const SelectedRow = <T extends string>({
  items,
  count,
  cancel,
  value,
  multipleValue,
  refetch,
  type = TableSelectType.Link,
}: SelectedRowProps<T>) => {
  const parseData = (data: T[]) => data.map((str) => JSON.parse(str)).flat();
  const renderSelect = () => {
    switch (type) {
      case TableSelectType.Account:
        return <AssignMemberSelect value={value} memberIds={items} refetch={refetch} />;
      case TableSelectType.Assign:
        return null;
      case TableSelectType.ChangeStatus:
        return <ChangeStatusSelect value={value} linkList={items} refetch={refetch} />;
      case TableSelectType.Document:
        return (
          <Box display="flex" alignItems="center">
            <DocumentDeleteSelected documents={items} refetch={refetch} />
          </Box>
        );
      case TableSelectType.Invoices:
        return (
          <Box display="flex" alignItems="center">
            <InvoiceDeleteSelected invoices={items} refetch={refetch} />
          </Box>
        );
      case TableSelectType.Copyright:
        return (
          <Box display="flex" alignItems="center">
            <CopyrightStatusSelect
              value={value as SelectCopyrightStatuses}
              links={items}
              refetch={refetch}
            />
          </Box>
        );
      default:
        return (
          <Box display="flex" alignItems="center">
            <LinkSourceTypeSelect
              value={multipleValue?.sourceType as SourceType | null | 'mixed'}
              links={parseData(items)}
              refetch={refetch}
            />
            <LinkStatusSelect
              value={multipleValue?.status as SelectLinkStatuses}
              links={parseData(items)}
              refetch={refetch}
            />
            <LinkDeleteSelected links={parseData(items)} refetch={refetch} />
          </Box>
        );
    }
  };

  return (
    <Wrap>
      <Box display="flex" alignItems="center">
        <IconButton sx={{ mr: '32px' }} onClick={cancel}>
          <CloseIcon />
        </IconButton>
        {count} items selected
      </Box>
      {renderSelect()}
    </Wrap>
  );
};
