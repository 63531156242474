import { ReactNode } from 'react';
import { AssignStatusType, FilterItem } from '@services';
import { SelectChangeEvent } from '@mui/material';
import { DefaultFilter } from '@molecules';
import { ASSIGN_STATUS_OPTIONS } from '@constants';

const key = 'assignStatus';

export const assignStatusFilter: FilterItem = {
  name: 'Status',
  key,
  getFilter: (onChange, onClose, setFilters, filter): ReactNode => (
    <DefaultFilter
      key={key}
      name="Status"
      value={filter[key] as AssignStatusType}
      items={ASSIGN_STATUS_OPTIONS}
      onChange={(event: SelectChangeEvent<unknown>) =>
        setFilters({ ...filter, [key]: event.target.value as string })
      }
      onClose={onClose}
    />
  ),
};
