/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */
import { Box, Button, Link, Typography } from '@mui/material';
import FilterTiltShiftIcon from '@mui/icons-material/FilterTiltShift';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getParams } from '@helpers';
import { BtnGroup } from './ESignPage.styles';
import ESignTemplates from './ESignTemplates';
import ESignSubmissions from './ESignSubmissions';

enum EType {
  SUBMISSIONS = 'Submissions',
  TEMPLATES = 'Document Templates',
}

const docusealUrl = process.env.REACT_APP_DOCUSEAL_URL;

const Header: FC<{ type?: EType; onChangeType?: (type: EType) => void }> = ({
  type = EType.TEMPLATES,
  onChangeType,
}) => {
  const GRBtn = [
    { type: EType.SUBMISSIONS, icon: <DnsRoundedIcon /> },
    { type: EType.TEMPLATES, icon: <GridViewRoundedIcon /> },
  ];

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <BtnGroup variant="contained" aria-label="Basic button group">
          {GRBtn.map((btn) => (
            <Button
              key={btn.type}
              variant={type === btn.type ? 'contained' : 'text'}
              onClick={() => onChangeType && onChangeType(btn.type)}
            >
              {btn.icon}
            </Button>
          ))}
        </BtnGroup>

        <Typography sx={{ fontSize: 36, lineHeight: '40px', fontWeight: 700 }}>{type}</Typography>
      </Box>

      <Link href={docusealUrl} target="_blank">
        <Button variant="contained" startIcon={<FilterTiltShiftIcon />}>
          Go to DocuSeal
        </Button>
      </Link>
    </Box>
  );
};

export const ESignPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = getParams(searchParams) as { screen?: EType };
  const [screen, setScreen] = useState<EType>(params.screen ?? EType.SUBMISSIONS);

  useEffect(() => {
    setScreen(params.screen ?? EType.SUBMISSIONS);
  }, [params.screen]);

  return (
    <>
      <Header
        type={screen}
        onChangeType={(_screen: EType) => {
          setSearchParams({ screen: _screen });
        }}
      />

      {screen === EType.SUBMISSIONS && <ESignSubmissions />}
      {screen === EType.TEMPLATES && <ESignTemplates />}
    </>
  );
};
