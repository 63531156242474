import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import isEqual from 'lodash.isequal';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { BUSINESS_PLANS_OPTIONS, createAccountValidation } from '@constants';
import { CompanyType, Plans } from '@services';
import { Axios, queryClient } from '@helpers';
import { useConfirmationModal, useModalState } from '@hooks';

import { Typography, Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Modal, TextField } from '@atoms';
import { ConfirmModal } from '@organisms/Modals';
import { SelectFabric } from '@molecules';

interface Props {
  onClose: () => void;
  open: boolean;
  companyType: CompanyType;
  isBusiness?: boolean;
}

interface FormData {
  companyType: CompanyType;
  firstName: string;
  lastName: string;
  companyName?: string;
  email: string;
  plan?: Plans;
  slots?: number;
}

export const CreateAccountModal: FC<Props> = ({ onClose, open, companyType, isBusiness }) => {
  const defaultValues = useMemo(
    () => ({
      companyType,
      firstName: '',
      lastName: '',
      email: '',
    }),
    [companyType],
  );

  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
  } = useForm<FormData>({
    resolver: yupResolver(createAccountValidation),
    defaultValues,
    mode: 'onChange',
    delayError: 700,
  });

  const values = watch();
  const isDirty = !isEqual(values, defaultValues);

  const [isConfirmOpen, onOpenConfirm, onConfirmCancel, onSubmitConfirm] = useConfirmationModal(
    false,
    onClose,
  );
  const [isSuccessOpen, , openSuccessModal, closeSuccessModal] = useModalState(false);
  const closeAllModal = () => {
    queryClient.refetchQueries(isBusiness ? 'business-accounts' : 'content-creators');
    closeSuccessModal();
    onClose();
  };

  const { mutate: invite, isLoading } = useMutation<unknown, unknown, unknown>(
    (data) => Axios.post('/admin/companies/invite', data),
    {
      onSuccess: () => {
        openSuccessModal();
      },
    },
  );
  const onFinalSubmit = (data: FormData) => invite(data);

  return (
    <>
      <Modal
        title="Add new account"
        open={open && !(isConfirmOpen || isSuccessOpen)}
        onClose={isDirty ? onOpenConfirm : onClose}
      >
        <Box
          component="form"
          display="flex"
          flexDirection="column"
          onSubmit={handleSubmit(onFinalSubmit)}
        >
          <Typography variant="body2" mt="8px" color="text.secondary" mb="40px">
            Here you can invite new business account.
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gap="24px"
            mb="24px"
            justifyContent="space-between"
          >
            <TextField
              name="firstName"
              label="First name"
              placeholder="First User name"
              control={control}
              required
            />
            <TextField
              name="lastName"
              label="Last name"
              placeholder="Last User name"
              control={control}
              required
            />
          </Box>
          <TextField
            name="companyName"
            label="Company name"
            placeholder="Add company name"
            control={control}
            required
          />
          <Box my="24px">
            <TextField
              name="email"
              label="Email"
              placeholder="name@ceartas.io"
              control={control}
              required
            />
          </Box>
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap="24px" mb="24px">
            <SelectFabric
              label="Subscription plan"
              placeholder="No plan"
              control={control}
              name="plan"
              options={BUSINESS_PLANS_OPTIONS}
              required
            />
            <TextField control={control} label="Slots" name="slots" type="number" required />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button color="secondary" onClick={isDirty ? onOpenConfirm : onClose}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={!isValid || !isDirty}
              loading={isLoading}
            >
              Send invite
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
      <ConfirmModal
        open={isConfirmOpen}
        onCancel={onConfirmCancel}
        onSubmit={onSubmitConfirm}
        title="Are you sure you want to close modal?"
        submitText="Yes, close"
      />
      <Modal title="" open={isSuccessOpen} onClose={closeAllModal}>
        <Box
          width="200px"
          margin="auto"
          display="flex"
          alignItems="center"
          flexDirection="column"
          pb="12px"
        >
          <CheckCircleIcon color="info" sx={{ fontSize: 83 }} />
          <Typography mt="16px" mb="48px" variant="body1" textAlign="center">
            Invite has been sent to
            <br />
            <b>{values.email}</b>
          </Typography>
          <Button color="info" variant="contained" onClick={closeAllModal} fullWidth>
            Got it
          </Button>
        </Box>
      </Modal>
    </>
  );
};
