import { Button, styled } from '@mui/material';

export const StyledButton = styled(Button)`
  border-radius: 8px;
  min-width: 150px;
  padding: 0 22px;
  transition: all 0.2s;

  &.active {
    color: ${(props) => props.theme.palette.primary.main};
    background: ${(props) => props.theme.palette.primary.light};
    border: 1px solid #b4dffd;
  }
`;
