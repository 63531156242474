import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, Chip, Tooltip } from '@mui/material';

export const renderChipsCell =
  (config?: Record<string, string>) =>
  ({ value }: GridRenderCellParams<string[]>) => {
    if (!value?.length) {
      return null;
    }
    const labels = config ? value.map((it) => config[it]) : value;
    const [first, ...rest] = labels;

    return (
      <Box display="flex" gap="8px">
        <Chip label={first} variant="outlined" />
        {!!rest.length && (
          <Tooltip title={rest.join(', ')}>
            <Chip label={`+${rest.length}`} variant="outlined" />
          </Tooltip>
        )}
      </Box>
    );
  };
