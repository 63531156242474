import { FC } from 'react';
import { SvgIconProps, useTheme } from '@mui/material';

import { BaseIcon } from '../BaseIcon';

export interface CheckIconProps extends SvgIconProps {
  checked: boolean;
}

export const CheckIcon: FC<CheckIconProps> = ({ checked, ...props }) => {
  const theme = useTheme();

  return (
    <BaseIcon {...props}>
      <path
        d="M8.99999 0.666687C4.39999 0.666687 0.666656 4.40002 0.666656 9.00002C0.666656 13.6 4.39999 17.3334 8.99999 17.3334C13.6 17.3334 17.3333 13.6 17.3333 9.00002C17.3333 4.40002 13.6 0.666687 8.99999 0.666687ZM7.33332 13.1667L3.16666 9.00002L4.34166 7.82502L7.33332 10.8084L13.6583 4.48335L14.8333 5.66669L7.33332 13.1667Z"
        fill={checked ? theme.palette.primary.main : theme.palette.text.disabled}
      />
    </BaseIcon>
  );
};
