import { styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CloseButton = styled(CloseIcon)`
  align-self: center;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.54);
  margin-top: 3px;
  margin-left: 12px;
  cursor: pointer;

  &:hover {
    color: black;
  }
`;
