import React, { FC } from 'react';
import { Control } from 'react-hook-form';

import { Box, Typography } from '@mui/material';
import { AttachFileButton, TextField } from '@atoms';

interface Props {
  control: Control<{ links: string }, object>;
  errors: number[];
  onUpload: (file: File | null) => void;
}

export const AddLinkStep: FC<Props> = ({ control, errors, onUpload }) => (
  <Box display="flex" flexDirection="column">
    <TextField
      name="links"
      label="List of links"
      placeholder="Add a list of links here..."
      control={control}
      rows={7}
      multiline
    />
    {!!errors.length && (
      <Typography variant="caption" color="error" mt="4px">
        {`${errors.join('. Link is not valid, ')}. Link is not valid`}
      </Typography>
    )}
    <AttachFileButton onChange={onUpload} />
  </Box>
);
