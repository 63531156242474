/* eslint-disable */
import { useState, useCallback, ReactNode, useMemo, useEffect } from 'react';
import { DataGridPro, GridSortModel, GridColDef, DataGridProProps } from '@mui/x-data-grid-pro';
import { useQuery } from 'react-query';

import { Box, TablePagination } from '@mui/material';
import { SxProps } from '@mui/system';

import { FilterButton, TableSearch } from '@molecules';
import { Axios, formatQueries, queryClient, queryFormatted } from '@helpers';
import { FilterItem, Queries, TableSelectType } from '@services';
import { Loader, NoRowsOverlay } from '@atoms';
import { useTableQueries } from '@hooks';
import { TableWrap } from '@templates';
import { SelectedRow } from './SelectedRow';
import CollapseTableRow from './CollapseTableRow/CollapseTableRow';

interface TableProps<T> extends Omit<DataGridProProps, 'onRowClick' | 'rows'> {
  columns: any;
  queryName?: string;
  apiRoute?: string;
  filtersList?: FilterItem[];
  onRowClick?: (row: T) => void;
  selectedRowType?: TableSelectType;
  getSelectedValue?: (items: T[], selectedItems: string[]) => string;
  getSelectedMultipleValue?: (items: T[], selectedItems: string[]) => any;
  checkboxSelection?: boolean;
  refetch?: () => void;
  refetchQueryName?: string;
  searchPlaceholder?: string;
  withoutSearch?: boolean;
  preSelectedFilters?: { [key in string]: string | undefined };
  tableSx?: SxProps;
  renderAdditionalControl?: ReactNode;
  transformData?: any;
  defaultPageSize?: number;
  renderRowPerPageOptions?: boolean;
}

export const CollapseTable = <T extends object>({
  queryName = 'name',
  apiRoute = '',
  columns,
  filtersList,
  onRowClick,
  selectedRowType,
  getSelectedValue = () => '',
  // eslint-disable-next-line
  getSelectedMultipleValue = () => {},
  checkboxSelection,
  refetch: addictionalRefetch,
  refetchQueryName,
  searchPlaceholder,
  withoutSearch,
  preSelectedFilters,
  tableSx,
  renderAdditionalControl,
  defaultPageSize,
  renderRowPerPageOptions = true,
  transformData,
}: TableProps<T>) => {
  const [selectedItems, setSelectedItems] = useState<Array<string>>([]);
  const [selectedItemsRecord, setSelectedItemsRecord] = useState<Record<string, string[]>>({});

  useEffect(() => {
    setSelectedItems(
      Object.entries(selectedItemsRecord)
        .map(([key, value]) => {
          return [...value];
        })
        .flat(),
    );
  }, [selectedItemsRecord]);
  const {
    queries,
    setQueries,
    filterChange,
    resetFilter,
    filters,
    setFilters,
    selectedFilters,
    setSelectedFilters,
  } = useTableQueries(preSelectedFilters);

  const { data, isLoading, refetch } = useQuery<
    unknown,
    unknown,
    { items: T[]; totalCount: number }
  >(
    [queryName, queries, filters],
    () =>
      apiRoute &&
      Axios.get(`${apiRoute}${queryFormatted(formatQueries({ ...queries, ...filters }))}`).then(
        (res) => res.data,
      ),
  );

  const totalCount = data?.totalCount || 0;
  const rowsPerPageOptions = totalCount
    ? [10, 20, 50, 100, totalCount]
        .filter((item) => item <= totalCount)
        .filter((v, i, a) => a.indexOf(v) === i) // get unique
    : [10, 20, 50, 100];

  const handleRefetch = () => {
    refetch();
    setSelectedItems([]);
    setSelectedItemsRecord({});
    // eslint-disable-next-line no-unused-expressions
    addictionalRefetch && addictionalRefetch();
    if (refetchQueryName) {
      queryClient.refetchQueries(refetchQueryName);
    }
  };

  const noRows = useCallback(
    () => (
      <NoRowsOverlay
        filterText={queries.filterText}
        hasSelectedFilters={!!selectedFilters.length}
      />
    ),
    [queries.filterText, selectedFilters],
  );

  const items = useMemo(
    () =>
      (transformData && transformData(data?.items)) ||
      data?.items?.map((item: any, index) => ({ id: index, ...item })) ||
      [],
    [transformData, data?.items],
  ); // TODO handle all ids from BE
  const hasSelectedFilters = filtersList?.some(({ key }) => selectedFilters.includes(key));
  const selected = getSelectedValue(items, selectedItems);
  const multipleSelected = getSelectedMultipleValue(items, selectedItems);
  const pageSize = queries.size > totalCount ? totalCount : queries.size;

  return (
    <>
      {!withoutSearch && (
        <Box display="flex" gap="24px" mb="24px">
          <TableSearch
            placeholder={searchPlaceholder}
            defaultSearch={queries.filterText}
            onSearch={(filterText) =>
              setQueries((prev: Queries) => ({
                ...prev,
                filterText,
              }))
            }
          />
          {filtersList && (
            <FilterButton
              options={filtersList.filter(({ key }) => !selectedFilters.includes(key))}
              onSelect={(key) => setSelectedFilters([...selectedFilters, key])}
              hasSelected={hasSelectedFilters}
              disabled={!filtersList.filter(({ key }) => !selectedFilters.includes(key)).length}
            />
          )}
          {renderAdditionalControl}
        </Box>
      )}
      {filtersList && !!selectedFilters?.length && (
        <Box display="flex" gap="24px" mb="24px">
          {filtersList?.map(({ key, getFilter, resetFields }) => {
            if (selectedFilters?.includes(key)) {
              return getFilter(
                filterChange,
                () => {
                  resetFilter(resetFields || [key]);
                  setSelectedFilters(selectedFilters.filter((f) => f !== key));
                },
                setFilters,
                filters,
              );
            }

            return null;
          })}
        </Box>
      )}
      {!!selectedItems?.length && (
        <SelectedRow
          value={selected}
          multipleValue={multipleSelected}
          items={selectedItems}
          count={selectedItems.length}
          cancel={() => {
            setSelectedItemsRecord({});
            setSelectedItems([]);
          }}
          refetch={handleRefetch}
          type={selectedRowType}
        />
      )}
      <TableWrap
        height="550px"
        sx={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          ...tableSx,
        }}
      >
        <Loader isLoading={isLoading} sx={{ minHeight: 'unset' }}>
          <>
            {items.map((item: any, index: number) => (
              <CollapseTableRow
                key={index}
                row={item}
                columns={columns}
                onRowClick={onRowClick}
                checkboxSelection={checkboxSelection}
                setSelectedItemsRecord={setSelectedItemsRecord}
                selectedItems={selectedItemsRecord?.[item?.id]}
                selectedRowIndex={index}
                items={items}
                selectedItemsRecord={selectedItemsRecord}
              />
            ))}
          </>
        </Loader>
      </TableWrap>
      <TablePagination
        sx={{
          marginTop: '8px',
        }}
        component="div"
        count={data?.totalCount || 0}
        page={queries.page}
        onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, page: number) =>
          setQueries({ ...queries, page })
        }
        rowsPerPage={defaultPageSize || pageSize}
        rowsPerPageOptions={renderRowPerPageOptions ? rowsPerPageOptions : []}
      />
    </>
  );
};
