import { FC, useState, MouseEvent } from 'react';
import { MenuAction } from '@services';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface Props {
  options: MenuAction[];
}

export const ActionsButtonMenu: FC<Props> = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box marginLeft="auto">
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map(({ name, onClick }) => (
          <MenuItem
            key={name}
            onClick={(e) => {
              onClick(e);
              handleClose();
            }}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
