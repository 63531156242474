import { FilterItem } from '@services';
import { renderDateFilter } from './renderDateFilter';

export const joinDateFilter: FilterItem = {
  name: 'Join date',
  key: 'joinDateFilter',
  resetFields: ['joinedStartDate', 'joinedEndDate'],
  getFilter: renderDateFilter({
    label: 'Join date',
    key: 'joinDateFilter',
    resetFields: ['joinedStartDate', 'joinedEndDate'],
  }),
};
