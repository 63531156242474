import { Controller, Control, Path, useFormState, FieldValues } from 'react-hook-form';
import get from 'lodash.get';
import {
  InputLabel,
  Box,
  TextField as MuiTextField,
  StandardTextFieldProps as MuiTextFieldProps,
} from '@mui/material';

export interface TextFieldProps<T extends FieldValues> extends MuiTextFieldProps {
  control: Control<T, object>;
  name: Path<T>;
  outsideError?: string;
  label?: string;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  returnNumber?: boolean;
}

export const TextField = <T extends object>({
  name,
  control,
  label,
  placeholder,
  type,
  disabled,
  outsideError,
  sx,
  required,
  returnNumber,
  ...props
}: TextFieldProps<T>) => {
  const { errors } = useFormState({ control });
  const error = (get(errors, name) as any)?.message || outsideError;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box width="100%" sx={sx}>
          {label && (
            <InputLabel htmlFor={name}>
              {label}
              {required && <span>&nbsp;*</span>}
            </InputLabel>
          )}
          <MuiTextField
            {...field}
            disabled={disabled}
            type={type}
            id={name}
            placeholder={placeholder}
            error={!!error}
            variant="outlined"
            helperText={error}
            onChange={(e) =>
              field.onChange(returnNumber ? parseInt(e?.target?.value, 10) : e?.target?.value)
            }
            {...props}
          />
        </Box>
      )}
    />
  );
};
