import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { AssetsType, FilterItem, TableContentCreator } from '@services';
import { ROUTES } from '@constants';

import { Table } from '@molecules';
import { lastScanningFilter, paymentStatusFilter, planFilter } from '@molecules/Table/filters';
import { InnerView } from '@templates';

import { columns } from './utils';

const FILTERS_LIST: FilterItem[] = [
  planFilter(),
  {
    ...paymentStatusFilter,
    name: 'Status',
  },
  lastScanningFilter,
];

export const ContentCreatorTable: FC = () => {
  const navigate = useNavigate();

  return (
    <InnerView>
      <Table<TableContentCreator>
        queryName="content-creators"
        columns={columns}
        apiRoute="/admin/accounts/content-creator"
        onRowClick={({ id, modelId, assetType, isRemoved }) => {
          if (id && !isRemoved) {
            sessionStorage.setItem('content-creator-table', window.location.search || '');
            const routeTo =
              assetType === AssetsType.Models
                ? `/${ROUTES.ContentCreators}/${id}/${modelId}/${ROUTES.ModelDetails}`
                : `/${ROUTES.ContentCreators}/${id}/${ROUTES.AssetDetails}`;
            navigate(routeTo);
          }
        }}
        filtersList={FILTERS_LIST}
      />
    </InnerView>
  );
};
