import { Box, styled, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { ButtonDatePicker } from '@atoms';

export const StyledBlock = styled(Box)<{ borderColor: string }>`
  width: 100%;
  padding: 24px;
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 12px;
`;

export const StyledTitle = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.15px;
`;

export const StyledValue = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 123.5%;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
`;

export const StyledDatePicker = styled(ButtonDatePicker)`
  & .MuiInputBase-input {
    padding: 14px 0 14px 13px !important;
  }

  & .MuiOutlinedInput-root {
    padding: 0;
  }

  & .MuiInputAdornment-root {
    margin-right: 14px;
  }
`;

export const datePickerSx: SxProps = {
  marginTop: '8px',
  borderRadius: '6px',
  boxShadow:
    '0px 0px 0px 1px rgba(25, 37, 50, 0.1), 0px 3px 7px -3px rgba(25, 37, 50, 0.1), 0px 6px 12px -2px rgba(25, 37, 50, 0.1)',
  '&.MuiPaper-root .MuiPickersCalendarHeader-root': {
    maxHeight: 'initial',
    padding: '14px 16px',
    margin: '0',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    background: '#F6F6F6',
  },
  '&.MuiPaper-root .MuiMonthPicker-root': {
    borderBottom: '1px solid #E0E0E0;',
  },
};
