import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Axios } from '@helpers';
import { ROUTES } from '@constants';
import { BusinessDetailsType } from '@services';
import { SubmitButtonContext } from '@contexts/SubmitButton';

import { Box } from '@mui/material';
import { Loader } from '@atoms';
import { AccountHeader, Tabs } from '@molecules';
import { InnerView } from '@templates';
import { Helmet } from 'react-helmet';

const links = [
  { label: 'Account details', to: ROUTES.AccountDetails },
  { label: 'Workspace', to: ROUTES.Workspace },
  // { label: 'Billing', to: ROUTES.Billing },
  { label: 'Whitelist', to: ROUTES.Whitelist },
  { label: 'Notifications', to: ROUTES.Notification },
];

const PARAMS_ID = 'business-accounts-table';

export const BusinessAccountsTabs: FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useQuery<unknown, unknown, BusinessDetailsType>(
    `business-details/${id}`,
    () => Axios.get(`/admin/accounts/${id}`).then((res) => res.data),
  );

  return (
    <Loader isLoading={isLoading}>
      <SubmitButtonContext>
        {data?.companyName && (
          <Helmet>
            <title>{data?.companyName}</title>
          </Helmet>
        )}
        <AccountHeader
          name={data?.companyName || ''}
          paramsId={PARAMS_ID}
          route={`/${ROUTES.Accounts}/${ROUTES.BusinessAccounts}`}
        />
        <InnerView autoHeight>
          <Box mt="20px" mb="24px">
            <Tabs links={links} modified={data?.lastModified} />
          </Box>
          <Outlet />
        </InnerView>
      </SubmitButtonContext>
    </Loader>
  );
};
