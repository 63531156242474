import { FC } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { ROUTES } from '@constants';
import { InnerView } from '@templates';
import { UserDetailsType } from '@services';
import { Axios, onSubmitButtonClick } from '@helpers';
import { useSubmitButtonState } from '@contexts/SubmitButton';

import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Loader } from '@atoms';
import { AccountHeader } from '@molecules';
import { UserDetailsForm } from '@organisms';

export const UserDetails: FC = () => {
  const { userId } = useParams();
  const { disabled, loading } = useSubmitButtonState();

  const { data, isLoading } = useQuery<unknown, unknown, UserDetailsType>(
    `user-detailed/${userId}`,
    () => Axios.get(`/admin/users/${userId}`).then((res) => res.data),
  );

  if (!data && !isLoading) {
    return null;
  }

  return (
    <Loader isLoading={isLoading}>
      <>
        <Box mb="16px" display="flex" justifyContent="space-between">
          <AccountHeader
            name={`${data?.firstName || ''} ${data?.lastName || ''}`}
            paramsId="users-table"
            route={`/${ROUTES.Users}`}
          />
          <LoadingButton
            variant="contained"
            onClick={onSubmitButtonClick}
            disabled={disabled}
            startIcon={<SaveIcon />}
            loading={loading}
          >
            Save changes
          </LoadingButton>
        </Box>
        <InnerView>
          <UserDetailsForm data={data as UserDetailsType} />
        </InnerView>
      </>
    </Loader>
  );
};
