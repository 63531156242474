import { FC } from 'react';
import { MenuItem, Select, SelectProps } from '@mui/material';
import { FILTER_BUSINESS_PLANS_OPTIONS, FILTER_CREATOR_PLANS_OPTIONS } from '@constants';

import { FilterCloseWrap } from './FilterCloseWrap';

interface Props extends SelectProps {
  onClose?: () => void;
  isBusiness?: boolean;
  allType?: boolean;
}

export const PlanFilter: FC<Props> = ({ onClose, isBusiness, allType, ...props }) => {
  let options = FILTER_CREATOR_PLANS_OPTIONS;
  if (allType) {
    options = [...FILTER_CREATOR_PLANS_OPTIONS, ...FILTER_BUSINESS_PLANS_OPTIONS];
  } else if (isBusiness) {
    options = FILTER_BUSINESS_PLANS_OPTIONS;
  }

  return (
    <FilterCloseWrap label="Plan" onClose={onClose}>
      <Select sx={{ height: '50px' }} placeholder="Select option" {...props}>
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FilterCloseWrap>
  );
};
