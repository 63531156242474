import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentType, TableSelectType } from '@services';
import { Table } from '@molecules';

import { getColumns } from './utils';

export const UploadInvoiceTable: FC<{ refetch?: () => void }> = (props) => {
  const { id } = useParams();
  const columns = useMemo(() => getColumns(), []);

  return (
    <Table<DocumentType>
      queryName={`upload-invoices-${id}`}
      columns={columns}
      withoutSearch
      selectedRowType={TableSelectType.Invoices}
      apiRoute={`/admin/companies/${id}/invoices`}
      checkboxSelection
      refetchQueryName={`upload-invoices-${id}`}
      {...props}
    />
  );
};
