import { FC } from 'react';
import { Box } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';

import { InnerView } from '@templates';
import { AccountHeader, Tabs } from '@molecules';
import { ROUTES } from '@constants';

const links = [
  { label: 'RAW', to: ROUTES.Raw },
  { label: 'INFRINGING', to: ROUTES.Infringing },
  { label: 'FALSE POSITIVE', to: ROUTES.FalsePositive },
];

export const WorkingMode: FC = () => {
  const { assetId, id } = useParams();

  return (
    <>
      <AccountHeader
        name="User related links"
        paramsId="working-mode"
        route={`/${ROUTES.ContentCreators}/${id}/${assetId}/${ROUTES.LinksManagement}`}
      />
      <InnerView autoHeight>
        <Box position="relative">
          <Tabs links={links} />
          <Box mt={2}>
            <Outlet />
          </Box>
        </Box>
      </InnerView>
    </>
  );
};
