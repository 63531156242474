import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import numeral from 'numeral';
import isEqual from 'lodash.isequal';
import dayjs, { Dayjs } from 'dayjs';

import { GeneralLinkInfoData, successMessage } from '@services';
import { generalLinkInfoValidation } from '@constants';
import { Axios, getParams } from '@helpers';

import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FakeInput, TextField } from '@atoms';

import { StyledBlock, StyledTitle, StyledValue, StyledDatePicker, datePickerSx } from './styles';

const getNum = (num = 0) => numeral(num).format('0,0');

const getDateQuery = (date: Dayjs | null) =>
  date ? `?month=${date.month() + 1}&year=${date.year()}` : '';

interface FormData {
  overrideGoogleWeb: string;
  overrideAtSource: string;
}

interface ReqFormData {
  overrideGoogleWeb: string;
  overrideAtSource: string;
  year: string;
  month: string;
}

export const GeneralLinkInfo: FC<{ minDate?: string }> = ({ minDate }) => {
  const { id, assetId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { month, year } = getParams(searchParams) as { month?: string; year?: string };
  const [date, setDate] = useState<Dayjs | null>(year && month ? dayjs(`${year}-${month}`) : null);

  const { data, refetch } = useQuery<unknown, unknown, GeneralLinkInfoData>(
    [`content-creator-general/${assetId}`, date],
    () =>
      Axios.get(`/admin/models/${assetId}/links/general${getDateQuery(date)}`).then(
        (res) => res.data,
      ),
  );

  const { delistedFromGoogle, removedFromSource, overrides, totalMedia, value } = data || {};

  const defaultValues = useMemo(
    () =>
      overrides && year && month
        ? {
            overrideGoogleWeb:
              (overrides?.[year]?.[month]?.googleWeb && String(overrides[year][month].googleWeb)) ||
              '',
            overrideAtSource:
              (overrides?.[year]?.[month]?.atSource && String(overrides[year][month].atSource)) ||
              '',
          }
        : { overrideGoogleWeb: '', overrideAtSource: '' },
    [overrides, month, year],
  );

  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(generalLinkInfoValidation),
    defaultValues,
    mode: 'onChange',
    delayError: 700,
  });

  const { mutate: onSubmit, isLoading } = useMutation<unknown, unknown, ReqFormData>(
    (formData) => Axios.put(`/admin/companies/${id}/assets/${assetId}`, formData),
    {
      onSuccess: () => {
        successMessage('Values have been changed!');
        refetch();
      },
    },
  );

  const values = watch();
  const isDirty = !isEqual(values, defaultValues);

  const finalSubmit = (formData: FormData) => {
    if (year && month) {
      onSubmit({ ...formData, year, month });
    }
  };

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const onChangeDate = (newDate: Dayjs | null) => {
    setDate(newDate);
    const newParams = newDate ? { month: newDate.month() + 1, year: newDate.year() } : {};
    setSearchParams(newParams as any);
  };

  const transformedMinDate = dayjs(dayjs(minDate).format('YYYY/MM'));

  return (
    <Box display="flex" flexDirection="column" mt="24px" mb="40px">
      <Box display="flex">
        <Box width="100%">
          <Typography variant="h5" mb="8px">
            Analytics
          </Typography>
          <Typography variant="body2" color="text.secondary" mb="16px">
            Here is you can check the analytics by all period or by month.
          </Typography>
        </Box>
        <Box sx={{ alignSelf: 'end', marginBottom: 'auto' }}>
          <StyledDatePicker
            views={['month', 'year']}
            placeholder="All period"
            value={date}
            onChange={onChangeDate}
            componentsProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
            PaperProps={{ sx: datePickerSx }}
            minDate={transformedMinDate}
            disableFuture
          />
        </Box>
      </Box>
      <Box display="flex" gap="24px">
        <StyledBlock borderColor="#00000099">
          <StyledTitle>Delisted (Google)</StyledTitle>
          <StyledValue>{getNum(delistedFromGoogle)}</StyledValue>
        </StyledBlock>
        <StyledBlock borderColor="#FF484880">
          <StyledTitle>Total Reported</StyledTitle>
          <StyledValue>{getNum(removedFromSource)}</StyledValue>
        </StyledBlock>
        <StyledBlock borderColor="#9DD049">
          <StyledTitle>Total Media Found</StyledTitle>
          <StyledValue>{getNum(totalMedia)}</StyledValue>
        </StyledBlock>
        <StyledBlock borderColor="#2196F3">
          <StyledTitle>Value</StyledTitle>
          <StyledValue>{getNum(value)}</StyledValue>
        </StyledBlock>
      </Box>
      <Box
        display="flex"
        gap="12px"
        mt="12px"
        width="100%"
        component="form"
        onSubmit={handleSubmit(finalSubmit)}
      >
        <FakeInput label="Override values">
          {date ? dayjs(date).format('MMMM YYYY') : 'All period'}
        </FakeInput>
        <TextField
          label="Google"
          type="number"
          placeholder="Override google value"
          control={control}
          name="overrideGoogleWeb"
          disabled={!date}
        />
        <TextField
          label="Source"
          type="number"
          placeholder="Override source value"
          control={control}
          name="overrideAtSource"
          disabled={!date}
        />
        <LoadingButton
          variant="contained"
          type="submit"
          disabled={!isValid || !isDirty || !date}
          sx={{ alignSelf: 'end', minWidth: '180px' }}
          loading={isLoading}
        >
          Save changes
        </LoadingButton>
      </Box>
    </Box>
  );
};
