import { styled, Box } from '@mui/material';

export const TableWrap = styled(Box)<{ height?: string }>`
  height: ${({ height }) => height || 'calc(100% - 74px)'};

  .disabled-row {
    opacity: 0.4;
  }

  .show-on-hover {
    opacity: 0;
  }

  .MuiDataGrid-row:hover {
    .show-on-hover {
      opacity: 1;
    }
  }
  .MuiDataGrid-cellCheckbox:focus-within,
  .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
  }
`;
