import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActiveStatus, TableUser } from '@services';
import { ROUTES } from '@constants';
import { Avatar, AvatarGroup, Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  data: TableUser;
}

export const AssignModelControl: FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const { id, status, assignedAccounts } = data;

  if (status !== ActiveStatus.Active) {
    return (
      <IconButton color="primary" size="small" disabled>
        <AddIcon />
      </IconButton>
    );
  }

  // if (role === UserRole.Admin) {
  //   return <Typography variant="body2">Full access</Typography>;
  // }

  const handleOpenAssignModal = () => {
    sessionStorage.setItem('assign-close-path', `/${ROUTES.Users}${window.location.search || ''}`);
    navigate(`${id}/${ROUTES.Assign}`);
  };

  return (
    <Box display="flex" gap="8px" onClick={(e) => e.stopPropagation()}>
      {!!assignedAccounts?.length && (
        <AvatarGroup max={3}>
          {assignedAccounts?.map(({ name, logo, id: accId }) => (
            <Avatar key={accId} alt={name} src={logo || ''} sx={{ width: 30, height: 30 }}>
              {name[0].toUpperCase()}
            </Avatar>
          ))}
        </AvatarGroup>
      )}
      <IconButton color="primary" size="small" onClick={handleOpenAssignModal}>
        <AddIcon />
      </IconButton>
    </Box>
  );
};
