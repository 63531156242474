import { ReactNode } from 'react';
import dayjs from 'dayjs';
import { FilterItem } from '@services';
import { DateFilter } from '@molecules';
import { DateFilterProps } from '@molecules/Filters/DateFilter';

interface Props extends Omit<DateFilterProps, 'onChange' | 'onClose'> {
  key: string;
  resetFields: [string, string];
}

export const renderDateFilter = ({ resetFields, ...props }: Props): FilterItem['getFilter'] => {
  const startDateName = resetFields[0];
  const endDateName = resetFields[1];

  return (onChange, onClose, setFilters, filters): ReactNode => (
    <DateFilter
      defaultStartDate={filters[startDateName] ? new Date(filters[startDateName] as string) : null}
      defaultEndDate={filters[endDateName] ? new Date(filters[endDateName] as string) : null}
      onChange={([startDate, endDate]) =>
        setFilters({
          ...filters,
          [startDateName]: startDate ? dayjs(startDate).format() : null,
          [endDateName]: endDate ? dayjs(endDate).format() : null,
        })
      }
      onClose={onClose}
      {...props}
    />
  );
};
