import dayjs from 'dayjs';
import { GridColDef, GridRowModel } from '@mui/x-data-grid';
import { Typography, Chip } from '@mui/material';

import { DATE_FORMAT } from '@constants';
import { BusinessDetailsType, CreatorPaymentStatus, ModelType } from '@services';
import { getStatus, removedCellClassName as cellClassName } from '@helpers';
import { InfoCell } from '@atoms';
import { renderActionsButtonMenu } from '@molecules/Table/cells/renderActionsButton';

interface Props {
  onEdit: (row: GridRowModel) => void;
  onDelete: (row: GridRowModel) => void;
}

export const getColumns = ({ onEdit, onDelete }: Props): GridColDef<ModelType>[] => {
  const actionsCreator = (row: GridRowModel) => [
    { name: 'Edit', onClick: () => onEdit(row) },
    { name: 'Delete', onClick: () => onDelete(row) },
  ];

  return [
    {
      field: 'username',
      headerName: 'Username',
      width: 230,
      cellClassName,
      renderCell: ({ row: { username, photo_url } }) => (
        <InfoCell name={username} avatarUrl={photo_url} />
      ),
    },
    {
      field: 'links',
      headerName: 'Links found / deleted',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      cellClassName,
      renderCell: ({ value: { found, deleted } }) => (
        <>
          <Typography variant="subtitle2" color="error.main">
            {found}
          </Typography>
          <Typography ml="3px" mr="3px" variant="subtitle2" color="text.disabled">
            /
          </Typography>
          <Typography variant="subtitle2" color="success.main">
            {deleted}
          </Typography>
        </>
      ),
    },
    {
      field: 'lastModified',
      headerName: 'Last modified',
      width: 140,
      cellClassName,
      renderCell: ({ value }) => <Chip label={dayjs(value).format(DATE_FORMAT)} color="success" />,
    },
    {
      field: 'joinDate',
      headerName: 'Join date',
      width: 110,
      cellClassName,
      renderCell: ({ value }) => dayjs(value).format(DATE_FORMAT),
    },
    {
      field: 'isRemoved',
      headerName: 'Status',
      width: 140,
      cellClassName,
      renderCell: ({ value }) => (
        <Chip label={value ? 'Deleted' : 'Active'} color={value ? 'secondary' : 'success'} />
      ),
    },
    {
      field: 'action',
      headerName: '',
      width: 54,
      flex: 1,
      cellClassName,
      renderCell: renderActionsButtonMenu(actionsCreator),
    },
  ];
};

export type TransformedBusinessData = Omit<BusinessDetailsType, 'status'> & {
  status: CreatorPaymentStatus; // TMP
};

export const transformBusinessData = (data: BusinessDetailsType): TransformedBusinessData => ({
  ...data,
  status: getStatus(data.status, data.plan, data.isRemoved),
});

export const toFixed = (a: number, b: number) => Number((a / b).toFixed(2));
