import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';

import { ManagedAccount } from '@services';
import { removedCellClassName as cellClassName, removedCellClassName } from '@helpers';
import { DATE_FORMAT } from '@constants';

import { InfoCell } from '@atoms';
import { Chip } from '@mui/material';

export const columns: GridColDef<ManagedAccount>[] = [
  {
    field: 'username',
    headerName: 'Content creator',
    minWidth: 250,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { username, photoUrl } }) => (
      <InfoCell name={username} avatarUrl={photoUrl} />
    ),
  },
  {
    field: 'joinDate',
    headerName: 'Join date',
    width: 250,
    cellClassName,
    renderCell: ({ value }) => dayjs(value).format(DATE_FORMAT),
  },
  {
    field: 'isRemoved',
    headerName: 'Status',
    width: 140,
    cellClassName,
    renderCell: ({ value }) => (
      <Chip label={value ? 'Deleted' : 'Active'} color={value ? 'secondary' : 'success'} />
    ),
  },
];
