import { Typography, Box, Avatar, Tooltip } from '@mui/material';

interface Props {
  name: string;
  avatarUrl?: string;
  email?: string;
  role?: string;
}

export const InfoCell = ({ name, email, role, avatarUrl }: Props) => (
  <Box display="flex" alignItems="center" width="100%">
    <Avatar alt={name} sx={{ width: 40, height: 40, marginRight: '16px' }} src={avatarUrl}>
      {name && name[0].toUpperCase()}
    </Avatar>
    <Box className="ellipsis">
      {role && (
        <Typography variant="caption" color="text.disabled">
          {role}
        </Typography>
      )}
      <Tooltip title={name} placement="top-start">
        <Typography variant="body2" className="ellipsis">
          {name}
        </Typography>
      </Tooltip>
      {email && (
        <Tooltip title={email} placement="bottom">
          <Typography variant="caption" color="text.disabled">
            {email}
          </Typography>
        </Tooltip>
      )}
    </Box>
  </Box>
);
