import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from '@constants';
import { useProfile } from '@hooks/useProfile';

export const AdminRoute: FC = () => {
  const { isAdmin } = useProfile();

  if (!isAdmin) {
    return <Navigate to={`${ROUTES.Accounts}/${ROUTES.ContentCreators}`} />;
  }

  return <Outlet />;
};
