import { FC } from 'react';
import { BoxProps, InputLabel, Box } from '@mui/material';
import { InputBorder } from './styles';

interface Props extends BoxProps {
  label: string;
}

export const FakeInput: FC<Props> = ({ label, children, ...props }) => (
  <Box width="100%" {...props}>
    <InputLabel>{label}</InputLabel>
    <InputBorder>{children}</InputBorder>
  </Box>
);
