import pick from 'object.pick';
import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';

import { SUBMIT_BUTTON_ID, updateUserValidation, USER_ROLES_OPTIONS } from '@constants';
import { ActiveStatus, successMessage, UserDetailsType, UserRole } from '@services';
import { useFormEqual } from '@hooks';
import { Axios, queryClient } from '@helpers';
import { useSubmitButtonState } from '@contexts/SubmitButton';

import { Avatar, Box, Button, InputLabel, MenuItem, Select } from '@mui/material';
import { FakeInput, TextField } from '@atoms';
import { SelectFabric } from '@molecules';
import { ManagedAccountsTable } from '@organisms';

interface FormData {
  firstName: string;
  lastName: string;
  role: UserRole;
  status: ActiveStatus;
}

export const UserDetailsForm: FC<{ data: UserDetailsType }> = ({ data }) => {
  const { userId } = useParams();
  const { setDisabled, disabled, setLoading } = useSubmitButtonState();

  const defaultValues = useMemo(
    () => pick(data, ['firstName', 'lastName', 'role', 'status']),
    [data],
  );

  const { handleSubmit, control, register, watch, reset } = useForm<FormData>({
    resolver: yupResolver(updateUserValidation),
    defaultValues,
    mode: 'onChange',
    delayError: 700,
  });

  useFormEqual<FormData>({
    setDisabled,
    disabled,
    data: defaultValues,
    reset,
    watch,
  });

  const { mutate: updateUser, isLoading } = useMutation<unknown, unknown, FormData>(
    (formData) => Axios.put(`/admin/users/${userId}`, formData).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries(`user-detailed/${userId}`);
        successMessage('User has been edited successfully!');
      },
    },
  );

  useEffect(() => {
    setLoading(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSubmit = (formData: FormData) => updateUser(formData);

  return (
    <Box>
      <Box component="form" width="664px" onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" gap="24px" mb="24px">
          <Avatar alt={data.firstName} sx={{ width: 82, height: 82 }} src={data.avatar || ''}>
            {data.firstName[0].toUpperCase()}
          </Avatar>
          <TextField
            name="firstName"
            control={control}
            label="First name"
            placeholder="First User name"
          />
          <TextField
            name="lastName"
            control={control}
            label="Last name"
            placeholder="Last User name"
          />
        </Box>
        <FakeInput label="Email">{data.email}</FakeInput>
        <Box mt="24px" display="flex" gap="24px">
          <SelectFabric
            label="Permission Type"
            control={control}
            name="role"
            options={USER_ROLES_OPTIONS}
          />
          <Box width="100%">
            <InputLabel htmlFor="status">Status</InputLabel>
            <Select {...register('status')} defaultValue={data.status}>
              <MenuItem value={ActiveStatus.Active}>
                <Box display="flex" alignItems="center" color="success.main">
                  <CheckCircleIcon sx={{ marginRight: '8px' }} /> Active
                </Box>
              </MenuItem>
              <MenuItem value={ActiveStatus.Disabled}>
                <Box display="flex" alignItems="center" color="error.main">
                  <DoDisturbAltIcon sx={{ marginRight: '8px' }} /> Disabled
                </Box>
              </MenuItem>
            </Select>
          </Box>
        </Box>
        <Button style={{ opacity: 0, height: 0 }} type="submit" id={SUBMIT_BUTTON_ID} />
      </Box>
      <ManagedAccountsTable />
    </Box>
  );
};
