import { FC, useRef, useState } from 'react';
import { Typography } from '@mui/material';

import { InnerView } from '@templates';
import { AccountInfo } from '@molecules';
import { AccountSettingsForm } from '@organisms';

import { AccountSettingsHeader } from './AccountSettingsHeader';

export const AccountSettings: FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <AccountSettingsHeader
        onSave={() => submitButtonRef?.current?.click()}
        isEditing={isEditing}
        toggleEdit={() => setIsEditing(!isEditing)}
        saveButtonDisabled={saveButtonDisabled}
      />
      <InnerView autoHeight>
        <Typography mt="24px" mb="8px" variant="h5">
          General information
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Below is general information about the user and their account.
        </Typography>

        {isEditing ? (
          <AccountSettingsForm
            setSaveButtonDisabled={setSaveButtonDisabled}
            submitButtonRef={submitButtonRef}
            setIsEditing={setIsEditing}
          />
        ) : (
          <AccountInfo />
        )}
      </InnerView>
    </>
  );
};
