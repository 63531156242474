import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import { MouseEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import cn from 'classnames';
import { useMutation, useQuery } from 'react-query';
import { Axios } from '@helpers';
import { NotificationResponseType, successMessage } from '@services';
import { useSocket } from '@hooks/useSocket';

import NotificationsIcon from '@mui/icons-material/Notifications';
import { NotificationMessage } from '@atoms';
import {
  Button,
  IconButtonProps,
  Typography,
  Box,
  LinearProgress,
  Fade,
  Popover,
} from '@mui/material';
import { StyledIconButton, IconWrapper, StyledCard } from './styled';

export type NotificationRefProps = {
  handleOnOpen: (event: MouseEvent) => void;
  handleOnClose: () => void;
};

type NotificationProps = {
  isHideIcon?: boolean;
} & IconButtonProps;

export const Notification = forwardRef<NotificationRefProps, NotificationProps>(
  ({ isHideIcon = false, ...props }, ref) => {
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const socket = useSocket();

    const { data, isLoading, refetch } = useQuery<void, void, NotificationResponseType>(
      'notifications',
      () => Axios.get('/user/notifications').then((res) => res.data),
    );

    const { mutate: readMessage, isLoading: isLoadingRead } = useMutation<
      void,
      void,
      string | undefined
    >((id) => Axios.post(id ? `/user/notifications/read/${id}` : '/user/notifications/read'), {
      onSuccess: () => {
        refetch();
      },
    });

    const hasUnread = data?.items?.some((item) => !item.isRead) || false;
    const hasMessages = Boolean(data?.items?.length);

    const handleOnOpen = (event: MouseEvent<HTMLButtonElement>) => {
      setOpen(true);
      setAnchorEl(event.currentTarget);
    };

    const handelOnClose = () => {
      setOpen(false);
      setAnchorEl(null);
    };

    useEffect(() => {
      if (socket) {
        socket.on('notification', () => {
          successMessage("You've received new message!");
          refetch();
        });
      }
    }, [socket, refetch]);
    useImperativeHandle(ref, (): any => ({ handleOnOpen, handelOnClose, hasUnread }));

    return (
      <>
        {!isHideIcon && (
          <StyledIconButton
            sx={{ height: 40, position: 'relative' }}
            disableRipple
            {...props}
            onClick={handleOnOpen}
          >
            <IconWrapper className={cn({ unread: hasUnread, open: isOpen })}>
              <NotificationsIcon fontSize="medium" />
            </IconWrapper>
          </StyledIconButton>
        )}
        <div>
          <Popover
            id="notification"
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handelOnClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <StyledCard>
              <Fade in={isLoading || isLoadingRead}>
                <LinearProgress sx={{ height: 4 }} />
              </Fade>
              <Box display="flex" justifyContent="space-between" alignItems="center" px="16px">
                {/* <Typography variant="h6" component="p">
                  Notifications
                </Typography> */}
                <Box>
                  <Button
                    variant="text"
                    color="primary"
                    sx={{ p: 0, marginRight: 1 }}
                    disableRipple
                    onClick={() => {
                      navigate(`/${ROUTES.Notifications}`);
                      handelOnClose();
                    }}
                  >
                    See all
                  </Button>
                  {hasMessages && (
                    <Button
                      variant="text"
                      color="primary"
                      sx={{ p: 0 }}
                      disableRipple
                      disabled={!hasUnread}
                      onClick={() => readMessage('')}
                    >
                      Mark as read
                    </Button>
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                overflow="scroll"
                height="430px"
                sx={{
                  overflowX: 'hidden',
                }}
              >
                {hasMessages ? (
                  data?.items?.map((item) => (
                    <NotificationMessage
                      key={item.id}
                      {...item}
                      onClick={item.isRead ? undefined : () => readMessage(item.id)}
                    />
                  ))
                ) : (
                  <Typography variant="subtitle1" sx={{ margin: '45px auto 0 auto' }}>
                    You have no notifications yet
                  </Typography>
                )}
              </Box>
            </StyledCard>
          </Popover>
        </div>
      </>
    );
  },
);
