import { FC } from 'react';
import { PAYMENT_STATUS } from '@constants';
import { CreatorPaymentStatus } from '@services';
import { Typography } from '@mui/material';

import { UserPaymentStatusWrap } from './styles';

export const UserPaymentStatus: FC<{ status: CreatorPaymentStatus }> = ({ status }) => (
  <UserPaymentStatusWrap className={status === CreatorPaymentStatus.Deleted ? 'deleted' : ''}>
    {PAYMENT_STATUS[status]?.icon}
    <Typography variant="caption">{PAYMENT_STATUS[status]?.label}</Typography>
  </UserPaymentStatusWrap>
);
