import React, { SyntheticEvent, useState } from 'react';
import UploadIcon from '@mui/icons-material/Upload';

import { Box, Typography, Divider, Button } from '@mui/material';
import { FileItem } from '@atoms';
import { useMutation } from 'react-query';
import { successMessage } from '@services';
import { Axios, queryClient } from '@helpers';
import { useParams } from 'react-router-dom';
import { UploadInvoiceTable } from '@organisms/Tables/UploadInvoiceTable';

export const Billing: React.FC = () => {
  const { id } = useParams();
  const [files, setFiles] = useState<File[] | null>(null);

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    setFiles([...input.files]);
  };

  const removeFile = (e: React.SyntheticEvent, fileIndex?: number) => {
    if (files?.filter((file: File, index: number) => index !== fileIndex).length === 0) {
      setFiles(null);
    } else setFiles(files?.filter((file: File, index: number) => index !== fileIndex) || null);
    e.stopPropagation();
  };

  const { mutate: uploadInvoices } = useMutation<void, void, File[]>(
    (filesData: File[]) => {
      const formData = new FormData();
      for (let index = 0; index < filesData.length; index += 1) {
        formData.append('file', filesData[index]);
      }
      return Axios.post(`admin/companies/${id}/invoices`, formData);
    },
    {
      onSuccess: () => {
        successMessage('Invoices have been uploaded!');
        queryClient.refetchQueries(`upload-invoices-${id}`);
        setFiles(null);
      },
    },
  );

  return (
    <>
      <Box display="flex" mb="16px" justifyContent="space-between">
        <Box>
          <Typography variant="h5" mb="8px">
            Upload invoices
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Below is list invoices of this account business.
          </Typography>
        </Box>
        <Box display="flex" gap="9px" justifyContent="flex-end">
          {files && files?.length > 0 && (
            <Button
              variant="outlined"
              onClick={() => {
                if (files) uploadInvoices(files);
              }}
            >
              Submit
            </Button>
          )}
          <Button variant="outlined" component="label" color="black" startIcon={<UploadIcon />}>
            Upload invoices
            <input type="file" accept="application/pdf" onChange={handleOnChange} hidden multiple />
          </Button>
        </Box>
      </Box>
      <Divider sx={{ my: '16px' }} />
      <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap', padding: '16px 0' }}>
        {files?.map((item, index: number) => (
          <FileItem name={item?.name} key={item?.name} onRemove={removeFile} fileIndex={index} />
        ))}
      </Box>
      <UploadInvoiceTable />
    </>
  );
};
