import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AssetsType, CompanyType, FilterItem, ManagedAccountType } from '@services';
import { ROUTES } from '@constants';

import { Box, Typography } from '@mui/material';
import { Table } from '@molecules';
import { lastScanningFilter, paymentStatusFilter, planFilter } from '@molecules/Table/filters';
import { columns } from './utils';

const FILTERS_LIST: FilterItem[] = [
  planFilter(false, true),
  {
    ...paymentStatusFilter,
    name: 'Status',
  },
  lastScanningFilter,
];

export const ManagedAccountsTable: FC = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const onRowClick = useCallback(
    ({ isRemoved, companyType, assetType, id, modelId }: ManagedAccountType) => {
      if (!isRemoved && id) {
        let routeTo = '';
        if (companyType === CompanyType.Business) {
          sessionStorage.setItem('business-accounts-table-back-to', window.location.pathname || '');
          sessionStorage.setItem('business-accounts-table', window.location.search || '');
          routeTo = `/${ROUTES.BusinessAccounts}/${id}/${ROUTES.AccountDetails}`;
        } else if (companyType === CompanyType.ContentCreator) {
          sessionStorage.setItem('content-creator-table-back-to', window.location.pathname || '');
          sessionStorage.setItem('content-creator-table', window.location.search || '');
          routeTo =
            assetType === AssetsType.Models
              ? `/${ROUTES.ContentCreators}/${id}/${modelId}/${ROUTES.ModelDetails}`
              : `/${ROUTES.ContentCreators}/${id}/${ROUTES.AssetDetails}`;
        }
        navigate(routeTo);
      }
    },
    [navigate],
  );

  return (
    <Box>
      <Typography variant="h5" mt="24px">
        Managed accounts
      </Typography>
      <Typography variant="body2" mt="8px" mb="16px">
        Below is general information about the user and their account.
      </Typography>
      <Table<ManagedAccountType>
        searchPlaceholder="Search for Company name or Email"
        queryName="managed-accounts-table"
        columns={columns}
        apiRoute={`/admin/users/${userId}/assigned-companies`}
        filtersList={FILTERS_LIST}
        onRowClick={onRowClick}
      />
    </Box>
  );
};
