import { useCallback, useState } from 'react';
import { useModalState } from './useModalState';

export const useConfirmationModal = (
  initState: boolean,
  onConfirm: (props?: unknown) => void,
): [boolean, (props?: unknown) => void, () => void, () => void] => {
  const [props, setProps] = useState<undefined | unknown>(undefined);
  const [isOpen, , onOpen, onClose] = useModalState(initState);

  const handleOnOpen = useCallback(
    (newProps?: unknown) => {
      setProps(newProps);
      onOpen();
    },
    [onOpen],
  );

  // Async for loading case
  const handleOnConfirm = useCallback(
    async (confirmProps?: unknown) => {
      await onConfirm(props || confirmProps);
      onClose();
    },
    [onConfirm, onClose, props],
  );

  return [isOpen, handleOnOpen, onClose, handleOnConfirm];
};
