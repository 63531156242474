/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import pick from 'object.pick';
import isEqual from 'lodash.isequal';
import SaveIcon from '@mui/icons-material/Save';
import ReportIcon from '@mui/icons-material/Report';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  SCAN_AI_FEATURES_OPTIONS,
  SCAN_SOURCES_OPTIONS,
  scanSettingsDetailsValidation,
} from '@constants';
import { Axios, queryClient } from '@helpers';
import {
  ImageType,
  Plans,
  ScanSettingItemType,
  ScanSettingsAiFeatures,
  successMessage,
} from '@services';
import { useConfirmationModal } from '@hooks';

import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  Alert,
  Checkbox,
  Grid,
  InputLabel,
  IconButton,
  Chip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ControlledChipsInput, TextField } from '@atoms';
import { Multiselect } from '@molecules';
import { ConfirmModal } from '@organisms';
import { ScanSettingImage, ScanSettingImageBtn, VisuallyHiddenInput } from './styles';
import { UploadImageModal } from './UploadImageModal';

interface FormData {
  keywords?: string[];
  usernames?: string[];
  scanSources?: string[];
  pages?: string;
  scanFrequencyDays?: string;
  filterNames?: string[];
  filterRemovals?: string[];
  generalScanEnabled?: boolean;
  personalScanEnabled?: boolean;
  aiFeatures?: ScanSettingsAiFeatures;
}

const getTitleModal = (checked: boolean, isGeneral: boolean) =>
  `Are you sure you want to turn ${checked ? 'off' : 'on'}\n${
    isGeneral ? 'General' : 'Personal'
  } settings?`;

interface Props {
  data: ScanSettingItemType;
  firstName: string;
  isModel?: boolean;
  assetData?: any;
}

export const ScanSettingsForm: FC<Props> = ({ data, firstName, isModel, assetData }) => {
  const { id, assetId } = useParams();
  const [generalScanEnabled, setGeneralScanEnabled] = useState(data.generalScanEnabled);
  const [personalScanEnabled, setPersonalScanEnabled] = useState(data.personalScanEnabled);
  const [isGeneralScanEnabled, setIsGeneralScanEnabled] = useState(true);
  const [images, setImages] = useState<{ type: ImageType; image: File | string }[]>([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);

  const nonRemovableUsernames = useMemo(
    () => data.usernames.filter((username) => !username.canRemove).map(({ username }) => username),
    [data],
  );
  const defaultValues: FormData = useMemo(
    () => ({
      scanFrequencyDays: data.scanFrequencyDays ? String(data.scanFrequencyDays) : undefined,
      pages: data.pages ? String(data.pages) : undefined,
      usernames: data.usernames
        .filter((username) => username.canRemove)
        .map(({ username }) => username),
      ...pick(data, ['keywords', 'scanSources', 'filterNames', 'filterRemovals']),
      aiFeatures: data?.aiFeatures ?? {
        faceMatch: false,
        nsfw: false,
        objectDetect: false,
        ocrDetect: false,
        people: false,
        watermark: false,
      },
    }),
    [data],
  );

  const hasAiFeatures = !!data?.aiFeatures;

  useEffect(() => {
    if (data?.images) {
      setImages(data.images?.map((x) => ({ image: x.url, type: x.type })));
    }
  }, [data]);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(scanSettingsDetailsValidation),
    defaultValues,
    mode: 'onChange',
    delayError: 700,
  });

  const values = watch();
  const isDirty =
    !isEqual(values, defaultValues) ||
    generalScanEnabled !== data.generalScanEnabled ||
    personalScanEnabled !== data.personalScanEnabled ||
    !isEqual(data.images?.map((x) => ({ type: x.type, image: x.url })) ?? [], images);

  // Upload images
  const { mutateAsync: uploadImage, isLoading: uploadImageLoading } = useMutation<
    unknown,
    unknown,
    unknown
  >((body) => Axios.post(`/admin/companies/${id}/assets/${assetId}/images`, body));
  // Remove images
  const { mutateAsync: removeImage, isLoading: removeImageLoading } = useMutation<
    unknown,
    unknown,
    { images: { url: string }[] }
  >((body) => Axios.delete(`/admin/companies/${id}/assets/${assetId}/images`, { data: body }));

  const { mutate: onSubmit, isLoading } = useMutation<unknown, unknown, FormData>(
    (formData) =>
      Axios.put(`/admin/companies/${id}/assets/${assetId}/scan-settings`, formData).then(
        (res) => res.data,
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries(`scan-management-details/${id}`);
        queryClient.refetchQueries(`content-creator-data-asset/${id}`);
        queryClient.refetchQueries(`asset-account-details/${id}`);
        successMessage('Scan settings has been updated!');
      },
    },
  );
  const finalSubmit = async ({ usernames, ...formData }: FormData) => {
    const aiFeaturesData = formData.aiFeatures;
    if (
      aiFeaturesData &&
      !hasAiFeatures &&
      Object.values(aiFeaturesData).filter(Boolean).length === 0
    ) {
      delete formData.aiFeatures;
    }
    const newImages = images.filter((x) => typeof x.image !== 'string');
    const rmImages = data?.images?.filter((x) => !images.find((y) => y.image === x.url)) ?? [];
    if (newImages.length > 0) {
      await Promise.all(
        newImages.map(async (x) => {
          const body = new FormData();
          body.append('file', x.image);
          body.append('type', x.type);
          await uploadImage(body);
          return true;
        }),
      );
    }
    if (rmImages.length > 0) await removeImage({ images: rmImages.map((x) => ({ url: x.url })) });

    await onSubmit({
      ...formData,
      generalScanEnabled,
      personalScanEnabled,
      usernames: [...nonRemovableUsernames, ...(usernames || [])],
    });
  };

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const onChangeSettingEnable = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'generalScanEnabled') {
      setGeneralScanEnabled((prev) => !prev);
    }
    if (event.target.name === 'personalScanEnabled') {
      setPersonalScanEnabled((prev) => !prev);
    }
  };

  const [open, onOpen, onCancel, onConfirm] = useConfirmationModal(
    false,
    onChangeSettingEnable as any,
  );

  const handleOnOpen = (event: ChangeEvent<HTMLInputElement>) => {
    setIsGeneralScanEnabled(event.target.name === 'generalScanEnabled');
    onOpen(event);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(finalSubmit)}>
      <Box display="flex" width="100%" justifyContent="space-between">
        <Box mb="16px">
          <Typography variant="h5" mb="8px">
            Scan settings
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Below you can change {firstName}’s scan settings.
          </Typography>
        </Box>
        <LoadingButton
          variant="contained"
          color="black"
          type="submit"
          startIcon={<SaveIcon />}
          disabled={!isDirty}
          loading={isLoading || uploadImageLoading || removeImageLoading}
        >
          Save changes
        </LoadingButton>
      </Box>
      <Box display="flex" flexDirection="column" width="664px" gap="16px">
        <Box display="flex" gap="32px">
          <FormControlLabel
            control={<Switch checked={generalScanEnabled} onChange={handleOnOpen} />}
            name="generalScanEnabled"
            label="General scan settings"
          />
          <FormControlLabel
            control={<Switch checked={personalScanEnabled} onChange={handleOnOpen} />}
            name="personalScanEnabled"
            label="Personal scan settings"
          />
        </Box>
        {!personalScanEnabled && (
          <Alert icon={false} severity="error">
            If you want to edit personal settings fields, turn on Personal scan settings first
          </Alert>
        )}
        <ControlledChipsInput
          label="Keywords"
          placeholder="Add keywords"
          name="keywords"
          control={control}
          disabled={!personalScanEnabled}
        />
        <ControlledChipsInput
          label={isModel ? 'Usernames' : 'Assets'}
          // placeholder={`Add ${isModel ? 'model' : 'asset'} name`}
          placeholder="Add asset name"
          name="usernames"
          control={control}
          disabled={!personalScanEnabled}
          nonRemovable={nonRemovableUsernames}
        />
        <Multiselect
          label="Scan type"
          placeholder="Choose scan type(s)"
          name="scanSources"
          control={control}
          options={SCAN_SOURCES_OPTIONS}
          disabled={!personalScanEnabled}
        />

        {[Plans.Elite, Plans.Vip, Plans.Platinum].includes(assetData?.plan) && (
          <Grid container columnSpacing={1}>
            <Grid item xs={12}>
              <InputLabel>AI Features</InputLabel>
            </Grid>
            {SCAN_AI_FEATURES_OPTIONS.map(({ label, value: key }) => (
              <Grid item xs={4} key={key}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!personalScanEnabled}
                      checked={values.aiFeatures?.[key] || false}
                      {...control.register(`aiFeatures.${key}`)}
                    />
                  }
                  label={label}
                />
              </Grid>
            ))}
          </Grid>
        )}

        <ControlledChipsInput
          label="Filter names"
          placeholder="Add filter names"
          name="filterNames"
          control={control}
          disabled={!personalScanEnabled}
        />
        <ControlledChipsInput
          label="Filter removals"
          placeholder="Add filter removals"
          name="filterRemovals"
          control={control}
          disabled={!personalScanEnabled}
        />
        <Box display="flex" gap="24px">
          <TextField
            label="Number of pages"
            control={control}
            name="pages"
            placeholder="Add number of pages searched"
            disabled={!personalScanEnabled}
          />
          <TextField
            label="Schedule every (days)"
            control={control}
            name="scanFrequencyDays"
            placeholder="Add days quantity"
            disabled={!personalScanEnabled}
          />
        </Box>
        <Box width="100%">
          <InputLabel>Images</InputLabel>
          <Grid container spacing={2}>
            {images.map((x, i) => {
              const url = typeof x.image === 'string' ? x.image : URL.createObjectURL(x.image);
              return (
                <Grid key={i} item xs={4}>
                  <ScanSettingImage>
                    <img src={url} alt="scan settings" />
                    <Chip
                      size="small"
                      color="secondary"
                      label={x.type}
                      sx={{ position: 'absolute', top: 8, left: 4, fontSize: 12 }}
                    />
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        setImages((state) => state.filter((_, idx) => idx !== i));
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ScanSettingImage>
                </Grid>
              );
            })}

            <Grid item xs={4}>
              <ScanSettingImageBtn onClick={() => setOpenUploadModal(true)}>
                <AddIcon />
              </ScanSettingImageBtn>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ConfirmModal
        open={open}
        onCancel={onCancel}
        onSubmit={onConfirm}
        title={getTitleModal(
          isGeneralScanEnabled ? generalScanEnabled : personalScanEnabled,
          isGeneralScanEnabled,
        )}
        submitText="Yes"
        icon={<ReportIcon color="info" sx={{ fontSize: '100px', opacity: '76%' }} />}
      />
      <UploadImageModal
        open={openUploadModal}
        onClose={() => setOpenUploadModal(false)}
        onOk={(imagData: { type: ImageType; image: File }) => {
          setImages((state) => [...state, imagData]);
        }}
      />
    </Box>
  );
};
