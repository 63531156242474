import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { TableBusinessAccount, TableContentCreator, TableModelAccount } from '@services';
import { removedCellClassName } from '@helpers';

import { Chip } from '@mui/material';
import { InfoCell } from '@atoms';
import { renderPlanCell, renderManagerCell } from '@molecules/Table/cells';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@constants';

export const columns: GridColDef<TableContentCreator | TableBusinessAccount | TableModelAccount>[] =
  [
    {
      field: 'contentCreator',
      headerName: 'Name',
      minWidth: 230,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.contentCreator || params.row.companyName,
      renderCell: ({ value, row: { email, logo } }) => (
        <InfoCell name={value} email={email} avatarUrl={logo} />
      ),
    },
    {
      field: 'plan',
      headerName: 'Plan',
      minWidth: 95,
      flex: 1,
      cellClassName: removedCellClassName,
      renderCell: renderPlanCell,
    },
    {
      field: 'manager',
      headerName: 'Manager',
      minWidth: 200,
      flex: 1,
      cellClassName: removedCellClassName,
      renderCell: renderManagerCell(true),
    },
    {
      field: 'assignStatus',
      headerName: 'Status',
      minWidth: 126,
      flex: 1,
      cellClassName: removedCellClassName,
      renderCell: ({ row: { manager } }) => (
        <Chip label={manager ? 'Assigned' : 'Unassigned'} color={manager ? 'success' : 'error'} />
      ),
    },
  ];

export const modelColumns: GridColDef<TableModelAccount>[] = [
  {
    field: 'username',
    headerName: 'Name',
    minWidth: 200,
    flex: 1,
    cellClassName: removedCellClassName,
    valueGetter: (params: GridValueGetterParams) => params.row.username,
    renderCell: ({ value, row: { email, logo } }) => (
      <InfoCell name={value} email={email} avatarUrl={logo} />
    ),
  },
  {
    field: 'joinDate',
    headerName: 'Join date',
    width: 130,
    cellClassName: removedCellClassName,
    renderCell: ({ value }) => dayjs(value).format(DATE_FORMAT),
  },
  {
    field: 'manager',
    headerName: 'Manager',
    minWidth: 200,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: renderManagerCell(true),
  },
  {
    field: 'assignStatus',
    headerName: 'Status',
    minWidth: 126,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { manager } }) => (
      <Chip label={manager ? 'Assigned' : 'Unassigned'} color={manager ? 'success' : 'error'} />
    ),
  },
];
