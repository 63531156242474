import styled from 'styled-components';

export const Wrap = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
`;

export const Content = styled.section`
  flex: 1;
  overflow: auto;
  height: 100vh;
`;

export const Inner = styled.section`
  background: ${(props) => props.theme.palette.mainBackground};
  padding: 24px 24px;
  height: calc(100% - 89px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;
