import { useState, useCallback, Dispatch } from 'react';
import { SelectChangeEvent } from '@mui/material';

import { FiltersQuery } from '@services';

interface UseFiltersParams {
  filterChange: (e: SelectChangeEvent<unknown>) => void;
  resetFilter: (names: string[]) => void;
  filters: FiltersQuery;
  setFilters: Dispatch<FiltersQuery>;
}

export const useFilters = (defaultFilters: FiltersQuery = {}): UseFiltersParams => {
  const [filters, setFilters] = useState<FiltersQuery>(defaultFilters);

  const filterChange = useCallback(
    ({ target: { value, name } }: SelectChangeEvent<unknown>) =>
      setFilters((_filters) => ({ ..._filters, [name]: String(value) })),
    [],
  );

  const resetFilter = useCallback(
    (names) => {
      const reseatedFilters = names.reduce(
        (accum: FiltersQuery, item: string) => ({ ...accum, [item]: null }),
        {},
      );

      setFilters({ ...filters, ...reseatedFilters });
    },
    [filters],
  );

  return { filterChange, resetFilter, filters, setFilters };
};
