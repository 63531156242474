import React, { FC, useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStateWithReset } from '@hooks';
import { Axios } from '@helpers';
import { linksValidation } from '@constants';
import { LinkSourceType } from '@services';

import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography, Divider, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MessageModal, TextField, AttachFileButton } from '@atoms';
import { splitLinks, validateLink } from './utils';

interface SubmitLinksModalProps {
  onClose: () => void;
  open: boolean;
  accountName?: string;
}

interface FormData {
  links: string;
}

export const SubmitLinksModal: FC<SubmitLinksModalProps> = ({
  open,
  onClose,
  accountName = '',
}) => {
  const { assetId } = useParams();
  const [isSuccessLinks, setSuccessLinks] = useState(false);
  const [isSuccessCSV, setSuccessCSV] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const isSuccess = isSuccessLinks || isSuccessCSV;
  const [errors, setErrors, resetErrors] = useStateWithReset<number[]>([], []);
  const [type, setType] = useState(LinkSourceType.AtSource);

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
  } = useForm<FormData>({
    resolver: yupResolver(linksValidation),
    mode: 'onChange',
    delayError: 700,
  });

  const currentLinks = watch('links');
  const isDirty = Boolean(currentLinks || file);
  const countLinks = splitLinks(currentLinks).length || 0;

  const { mutate: submitLinks, isLoading: isLoadingLinks } = useMutation<
    unknown,
    unknown,
    { links: string[]; sourceType: LinkSourceType }
  >((data) => Axios.post(`/admin/models/${assetId}/links`, data), {
    onSuccess: () => setSuccessLinks(true),
  });

  const { mutate: submitCSV, isLoading: isLoadingCSV } = useMutation<
    unknown,
    unknown,
    { csv: File; sourceType: LinkSourceType }
  >(
    ({ csv, sourceType }) => {
      const formData = new FormData();
      formData.append('file', csv);
      formData.append('sourceType', sourceType);

      return Axios.post(`/admin/models/${assetId}/links/csv`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    {
      onSuccess: () => setSuccessCSV(true),
    },
  );

  const onUpload = (newFile: File | null) => {
    setFile(newFile);
  };

  const onSubmit = (data: FormData) => {
    if (file) {
      submitCSV({ csv: file, sourceType: type });
    }
    if (currentLinks) {
      submitLinks({ links: splitLinks(data.links), sourceType: type });
    }
  };

  const handleChangeType = (event: React.MouseEvent<HTMLElement>, newType: LinkSourceType) => {
    setType(newType);
  };

  useEffect(() => {
    resetErrors();
    const newLinks = splitLinks(currentLinks);
    newLinks.forEach(validateLink(setErrors));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLinks, setErrors]);

  const isDisable = !isDirty || !isValid || isLoadingLinks || isLoadingCSV || !!errors.length;

  return (
    <MessageModal
      title={isSuccess ? '' : 'Upload links'}
      open={open}
      onClose={onClose}
      confirmed={isSuccess}
      description={
        isSuccess ? (
          <>
            New {countLinks > 1 ? 'links were' : 'link was'} added to <b>{accountName}</b> account
          </>
        ) : (
          ''
        )
      }
      Action={
        <Button color="info" variant="contained" onClick={onClose} fullWidth>
          Got it
        </Button>
      }
      Icon={<CheckCircleIcon sx={{ fontSize: 83, color: '#69BEFC' }} />}
    >
      <Box component="form" display="flex" flexDirection="column" onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="body2" color="text.secondary">
          Add a list of links or attach a CSV file
        </Typography>
        <Divider sx={{ my: '24px', mx: '-32px' }} />
        <ToggleButtonGroup
          color="primary"
          value={type}
          exclusive
          onChange={handleChangeType}
          sx={{ pb: '24px' }}
        >
          <ToggleButton value={LinkSourceType.AtSource}>At Source</ToggleButton>
          <ToggleButton value={LinkSourceType.GoogleWeb}>Google Web</ToggleButton>
          <ToggleButton value={LinkSourceType.GoogleImages}>Google Image</ToggleButton>
        </ToggleButtonGroup>
        <TextField
          name="links"
          label="List of links"
          placeholder="Add a list of links here..."
          control={control}
          rows={7}
          multiline
        />
        {!!errors.length && (
          <Typography
            className="always-scroll"
            variant="caption"
            color="error"
            mt="4px"
            sx={{ maxHeight: '100px', overflow: 'auto' }}
          >
            {`${errors.join('. Link is not valid, ')}. Link is not valid`}
          </Typography>
        )}
        <AttachFileButton onChange={onUpload} />
        <Box display="flex" justifyContent="flex-end" mt="24px">
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={isDisable}
            sx={{
              '& svg': {
                marginRight: '9px',
              },
            }}
            startIcon={<UploadIcon />}
          >
            Upload
          </Button>
        </Box>
      </Box>
    </MessageModal>
  );
};
