import { FC, useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';

import { Axios } from '@helpers';
import { successMessage, WhitelistType } from '@services';
import { useConfirmationModal } from '@hooks';

import { Loader } from '@atoms';
import { Table } from '@molecules/Table';
import { ConfirmModal, EditLinkModal } from '@organisms/Modals';
import { InnerView } from '@templates';

import { useParams } from 'react-router-dom';
import { getColumns } from './utils';

export const WhitelistTable: FC<{ isBusiness?: boolean }> = () => {
  const { id } = useParams();
  const [selectedRow, setSelectedRow] = useState<WhitelistType | null>(null);

  // Delete functional
  const { mutate: deleteLink, isLoading: isLoadingDelete } = useMutation<
    unknown,
    unknown,
    { whitelistDomainId: string; companyId: string }
  >(
    ({ companyId, whitelistDomainId }) =>
      Axios.delete(`/admin/whitelist-domains/${companyId}/${whitelistDomainId}`),
    {
      onSuccess: () => {
        successMessage('The link has been deleted!');
        setSelectedRow(null);
      },
    },
  );

  const submitDelete = () => {
    if (selectedRow) {
      deleteLink({
        whitelistDomainId: selectedRow.id,
        companyId: selectedRow.uploadedBy.companyId,
      });
    }
  };

  const [openDeleteModal, onOpenDeleteModal, onCancelDeleteModal, onSubmitDeleteModal] =
    useConfirmationModal(false, submitDelete);

  const onDelete = useCallback(
    (row) => {
      setSelectedRow(row);
      onOpenDeleteModal();
    },
    [onOpenDeleteModal],
  );

  // Edit functional
  const { mutate: editLink, isLoading: isLoadingEdit } = useMutation<
    unknown,
    unknown,
    { whitelistDomainId: string; domain: string; companyId: string }
  >(
    ({ whitelistDomainId, domain, companyId }) =>
      Axios.put(`/admin/whitelist-domains/${companyId}/${whitelistDomainId}`, {
        domain,
      }),
    {
      onSuccess: () => {
        successMessage('The link has been changed!');
        setSelectedRow(null);
      },
    },
  );

  const submitEdit = ({ link }: { link: string }) => {
    if (selectedRow) {
      console.log(selectedRow.uploadedBy);
      editLink({
        domain: link.replace(/http(s)?:\/\//, ''),
        whitelistDomainId: selectedRow.id,
        companyId: selectedRow.uploadedBy.companyId,
      });
    }
  };

  const [openEditModal, onOpenEditModal, onCancelEditModal, onSubmitEditModal] =
    useConfirmationModal(false, submitEdit as any);

  const onEdit = useCallback(
    (row) => {
      setSelectedRow(row);
      onOpenEditModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onOpenDeleteModal],
  );

  const columns = useMemo(
    () =>
      getColumns({
        onEdit,
        onDelete,
      }),
    [onEdit, onDelete],
  );

  const isLoading = isLoadingDelete || isLoadingEdit;
  return (
    <Loader isLoading={isLoading}>
      <InnerView autoHeight>
        {!isLoading && (
          <Table<WhitelistType>
            searchPlaceholder="Search by Name or Email"
            queryName="whitelist-domains-table"
            columns={columns}
            apiRoute={`/admin/whitelist-domains/${id}`}
          />
        )}
        <ConfirmModal
          open={openDeleteModal}
          onCancel={onCancelDeleteModal}
          onSubmit={onSubmitDeleteModal}
          title={
            <>
              Are you sure you want to delete
              <br />
              domain <b>{selectedRow?.domainRegex}</b>?
            </>
          }
          submitText="Yes, delete"
          submitColor="error"
          cancelText="No"
        />
        {selectedRow && openEditModal && (
          <EditLinkModal
            link={`https://${selectedRow.domainRegex.replace(/http(s)?:\/\//, '')}`}
            open={openEditModal}
            onCancel={onCancelEditModal}
            onSubmit={onSubmitEditModal}
          />
        )}
      </InnerView>
    </Loader>
  );
};
