import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import { Axios } from '@helpers';
import { successMessage } from '@services';
import { useConfirmationModal } from '@hooks';
import DeleteIcon from '@mui/icons-material/Delete';

import { Button, SelectProps } from '@mui/material';
import { ConfirmModal } from '@organisms/Modals';

interface Props extends SelectProps {
  documents: string[];
  refetch: () => void;
}

export const DocumentDeleteSelected: FC<Props> = ({ documents, refetch }) => {
  const { assetId } = useParams();

  const { mutate: deleteLinks } = useMutation<
    { ListModelDocumentId: string[] },
    unknown,
    { ListModelDocumentId: string[] }
  >((data) => Axios.delete(`/admin/models/${assetId}/model-documents`, { data }), {
    onSuccess: () => {
      successMessage('Documents has been removed!');
      refetch();
    },
  });

  const handleOnChange = () => {
    deleteLinks({ ListModelDocumentId: { ...documents } });
  };

  const [open, onOpen, onCancel, onSubmit] = useConfirmationModal(false, handleOnChange);

  return (
    <>
      <Button
        onClick={onOpen}
        color="error"
        sx={{ textTransform: 'uppercase' }}
        startIcon={<DeleteIcon fontSize="small" />}
      >
        Delete
      </Button>
      <ConfirmModal
        open={open}
        onCancel={onCancel}
        onSubmit={onSubmit}
        title="Are you sure you want to delete the selected documents?"
        submitText="Yes, delete"
      />
    </>
  );
};
