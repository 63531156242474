import { FC } from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Tooltip } from '@mui/material';

interface Props {
  src: string;
  width?: string;
  height?: string;
  color?: any;
}

export const LinkPreview: FC<Props> = ({
  src,
  width = '600px',
  height = '450px',
  color = 'primary',
}) => (
  <Tooltip
    className="iframe"
    placement="left"
    title={
      <iframe
        id={src}
        title={src}
        width={width}
        height={height}
        style={{ background: 'white' }}
        srcDoc={`<style>body{margin:0;} img{width:100%; height:100%; object-fit:cover;}</style><img src='${src}' alt='Content not available to preview.' />`}
      />
    }
    arrow
  >
    <a href={src} target="_blank" rel="noreferrer">
      <RemoveRedEyeIcon color={color} sx={{ marginRight: '4px' }} />
    </a>
  </Tooltip>
);
