import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

interface Props {
  defaultStartDate?: Date | null;
  defaultEndDate?: Date | null;
  onChange?: (date: [Date | null, Date | null]) => void;
}

export const useRangeDatePicker = ({
  defaultStartDate = null,
  defaultEndDate = null,
  onChange,
}: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);

  const onChangeDate = (date: [Date | null, Date | null]) => {
    const [start, end] = date;
    setStartDate(start);
    setEndDate(end ? dayjs(end).endOf('day').toDate() : end);
  };

  useEffect(() => {
    if (startDate && endDate && onChange) {
      onChange([startDate, endDate]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return { startDate, endDate, onChange: onChangeDate };
};
