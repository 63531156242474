import { FC } from 'react';
import { SelectProps } from '@mui/material';

import { ScanSourcesTypeWithoutReverseImageSearch } from '@services';
import { SCAN_SOURCES_LABEL } from '@constants';

import { SelectStyled, MenuItemStyled } from './styles';
import { FilterCloseWrap } from './FilterCloseWrap';

export const LinkSourceTypeFilter: FC<{ onClose?: () => void } & SelectProps> = ({
  onClose,
  ...props
}) => (
  <FilterCloseWrap label="Source type" onClose={onClose}>
    <SelectStyled placeholder="Select option" {...props}>
      {Object.entries(ScanSourcesTypeWithoutReverseImageSearch).map(([, key]) => (
        <MenuItemStyled key={key} value={key}>
          {SCAN_SOURCES_LABEL[key]}
        </MenuItemStyled>
      ))}
    </SelectStyled>
  </FilterCloseWrap>
);
