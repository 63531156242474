import { Dispatch, FC, useMemo } from 'react';
import pick from 'object.pick';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { useFormEqual } from '@hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { MemberRole, MemberStatus, MemberType, successMessage } from '@services';
import {
  DATE_FORMAT,
  MEMBER_ROLE_OPTIONS,
  memberAccountValidation,
  SUBMIT_BUTTON_ID,
} from '@constants';
import { Axios } from '@helpers';

import { FakeInput, MemberStatusFake, TextField } from '@atoms';
import { Box } from '@mui/material';
import { SelectFabric } from '@molecules/Selects';

interface Props {
  data: MemberType;
  disabled: boolean;
  setDisabled: Dispatch<boolean>;
  refetch: () => void;
  disabledForm?: boolean;
}

interface FormData {
  firstName: string;
  lastName: string;
  status: MemberStatus;
  role: MemberRole;
}

export const MemberDetailsForm: FC<Props> = ({
  data,
  disabled,
  setDisabled,
  disabledForm,
  refetch,
}) => {
  const { accountId, memberId } = useParams();
  const { firstName, email, companyName, status, createdAt } = data;
  const defaultValues = useMemo(
    () => ({ ...pick(data, ['firstName', 'lastName', 'status', 'role']) }),
    [data],
  );

  const { handleSubmit, control, reset, watch } = useForm<FormData>({
    resolver: yupResolver(memberAccountValidation),
    defaultValues,
    mode: 'onChange',
    delayError: 700,
  });

  useFormEqual<FormData>({
    setDisabled,
    disabled,
    data: defaultValues,
    reset,
    watch,
  });

  const { mutate: onSubmit } = useMutation<FormData, unknown, FormData>(
    (formData) => Axios.put(`/admin/accounts/business/${accountId}/member/${memberId}`, formData),
    {
      onSuccess: () => {
        successMessage(`${firstName} has been updated!`);
        refetch();
      },
    },
  );

  return (
    <Box
      width="664px"
      component="form"
      onSubmit={handleSubmit((formData: FormData) => onSubmit(formData))}
    >
      <Box display="flex" gap="24px" mb="24px" mt="24px">
        <TextField
          label="First name"
          control={control}
          name="firstName"
          disabled={disabledForm}
          required
        />
        <TextField
          label="Last name"
          control={control}
          name="lastName"
          disabled={disabledForm}
          required
        />
      </Box>
      <FakeInput label="Email" mb="24px">
        {email}
      </FakeInput>
      <FakeInput label="Company">{companyName}</FakeInput>
      <Box display="flex" gap="24px" mb="24px" mt="24px">
        <SelectFabric
          label="Role"
          control={control}
          name="role"
          options={MEMBER_ROLE_OPTIONS}
          disabled={disabledForm}
          required
        />
        <FakeInput label="Status">
          <MemberStatusFake status={status} />
        </FakeInput>
        <FakeInput label="Join date">{dayjs(createdAt).format(DATE_FORMAT)}</FakeInput>
      </Box>
      <button style={{ opacity: 0 }} type="submit" id={SUBMIT_BUTTON_ID} disabled={disabledForm} />
    </Box>
  );
};
