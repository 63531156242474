import { FC } from 'react';
import { Typography } from '@mui/material';

import { ActiveStatus } from '@services';
import { USER_STATUS_TEXT, USER_STATUS_ICONS } from '@constants';

import { UserStatusWrap } from './styles';

export interface UserStatusProps {
  status: ActiveStatus;
  text?: string;
  color?: string;
}

export const UserStatus: FC<UserStatusProps> = ({ status, text, color }) => (
  <UserStatusWrap status={status} color={color}>
    {USER_STATUS_ICONS[status]}
    <Typography fontSize="13px">
      {USER_STATUS_TEXT[status]} {text}
    </Typography>
  </UserStatusWrap>
);
