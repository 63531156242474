import { GridRenderCellParams } from '@mui/x-data-grid-pro';

export const renderSlotCell = ({ value }: GridRenderCellParams) => {
  const { available, used } = value as { available: number; used: number };
  return (
    <>
      {used || 0} / {available}
    </>
  );
};
