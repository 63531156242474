import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Typography,
  IconButton,
  Grid,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { errorMessage, ImageType } from '@services';
import DeleteIcon from '@mui/icons-material/Delete';
import { ScanSettingImage, ScanSettingImageBtn, VisuallyHiddenInput } from './styles';

const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/png'];

interface Props {
  open: boolean;
  onClose: () => void;
  onOk: (data: { type: ImageType; image: File }) => void;
}

export const UploadImageModal = ({ open, onClose, onOk }: Props) => {
  const [image, setImage] = useState<File | null>(null);
  const [imageType, setImageType] = useState<ImageType>(ImageType.WATERMARK);

  const closeModal = () => {
    setImage(null);
    setImageType(ImageType.WATERMARK);
    onClose();
  };

  const addImage = () => {
    if (!image) return;
    onOk({ image, type: imageType });
    closeModal();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle sx={{ m: 0, py: 2, px: 3, width: 400 }}>Add image</DialogTitle>
      <DialogContent dividers>
        {image ? (
          <ScanSettingImage>
            <img src={URL.createObjectURL(image)} alt="scan settings" />
            <IconButton
              aria-label="delete"
              onClick={() => {
                setImage(null);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ScanSettingImage>
        ) : (
          <ScanSettingImageBtn component="label">
            <AddIcon />
            <VisuallyHiddenInput
              type="file"
              onChange={(event) => {
                /* eslint-disable no-unused-expressions */
                const fileList = Array.from(event.target.files ?? []);
                if (!ACCEPTED_IMAGE_TYPES.includes(fileList[0].type)) {
                  return errorMessage('Only allow to upload image file (JPEG, PNG)');
                }
                return fileList[0] && setImage(() => fileList[0]);
              }}
              id="input"
              accept="image/*"
            />
          </ScanSettingImageBtn>
        )}
        <FormControl fullWidth sx={{ mt: 4 }}>
          <Typography sx={{ mb: 1, fontSize: 14 }}>Image type</Typography>
          <Select value={imageType} onChange={(e) => setImageType(e.target.value as any)}>
            <MenuItem value={ImageType.WATERMARK}>Watermark</MenuItem>
            <MenuItem value={ImageType.REVERSE_IMAGE_SEARCH}>Reverse Image Search</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ px: 3 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ height: 40, mr: 1 }}
              onClick={closeModal}
            >
              Close
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={addImage}
              disabled={!image}
              variant="contained"
              color="primary"
              sx={{ height: 40 }}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
