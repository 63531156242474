import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { InputTypes, OptionType } from '@services';

import { ControlledDatePicker, TextField } from '@atoms';
import { Multiselect } from '@molecules';
import { FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';

import SocialMediaAccountsForm from './SocialMediaAccountsForm';

interface IAssetField {
  key: string;
  label: string;
  type: InputTypes;
  placeholder?: string;
  options?: OptionType[];
  required?: boolean;
}

interface IProps {
  fields: IAssetField[];
  control: Control<any, object>;
}

const textFieldStyles = {
  flexBasis: 'calc(50% - 6px)',
};

export const AssetDetails: FC<IProps> = ({ fields, control }) => (
  <>
    {fields.map((field) => {
      const { type, key, label, placeholder, options, required } = field;
      const name = `properties.${key}`;
      if (type === InputTypes.Date) {
        return (
          <ControlledDatePicker
            label={label}
            placeholder={placeholder}
            name={name}
            key={key}
            control={control}
            sx={textFieldStyles}
            inputFormat="DD MMMM YYYY"
            required={required}
          />
        );
      }

      if (type === InputTypes.Multiselect) {
        return (
          <Multiselect
            label={label}
            control={control}
            name={name}
            key={key}
            options={options || []}
            placeholder={placeholder}
            required={required}
          />
        );
      }

      if (type === InputTypes.Radio) {
        return (
          <Controller
            key={key}
            name={name}
            control={control}
            render={(form) => (
              <FormControl>
                <FormLabel id="radio-buttons">{placeholder}</FormLabel>
                <RadioGroup {...form.field} aria-labelledby="radio-buttons" row>
                  {options?.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          />
        );
      }

      if (type === InputTypes.AddSocialMediaAccounts) {
        return <SocialMediaAccountsForm key={key} name={name} control={control} />;
      }

      return (
        <TextField
          control={control}
          name={name}
          label={label}
          placeholder={placeholder}
          key={key}
          sx={textFieldStyles}
          required={required}
        />
      );
    })}
  </>
);
