import { FC } from 'react';

import { Box } from '@mui/material';
import { ReactComponent as AllIcon } from '@assets/slots/all.svg';
import { ReactComponent as FreeIcon } from '@assets/slots/free.svg';
import { Card } from '@atoms';

interface Props {
  available: number;
  used: number;
}

export const Slots: FC<Props> = ({ available, used }) => (
  <Box display="flex" gap="24px">
    <Card number={available} label="All slots" icon={<AllIcon />} />
    <Card number={available - used} label="Free slots" icon={<FreeIcon />} />
  </Box>
);
