import { Box } from '@mui/material';
import styled from 'styled-components';

export const Wrap = styled(Box)`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
