import { FC } from 'react';
import cn from 'classnames';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { Axios } from '@helpers';
import { Box, InputLabel, SelectChangeEvent, SelectProps } from '@mui/material';

import { SourceType } from '@services';
import { SOURCE_STATUS_NAME } from '@constants';

import { SelectStyled, MenuItemStyled } from '../Filters/styles';

interface Props extends SelectProps {
  value?: SourceType | null | 'mixed';
  links: string[];
  refetch: () => void;
}

export const LinkSourceTypeSelect: FC<Props> = ({ value, links, refetch, className, ...props }) => {
  const { assetId } = useParams();

  const { mutate: changeSourceType } = useMutation<
    { links: string[]; sourceType: SourceType },
    unknown,
    any
  >(
    (data) =>
      Axios.patch(`/admin/models/${assetId}/links/source-type`, {
        ...data,
        sourceType: data.sourceType === SourceType.AtSource ? 'submitted_links' : data.sourceType,
      }),
    {
      onSuccess: refetch,
    },
  );

  const handleOnChange = (event: SelectChangeEvent<unknown>) => {
    event.stopPropagation();
    changeSourceType({ links, sourceType: event.target.value as SourceType });
  };

  return (
    <Box display="flex" alignItems="center" width="320px" marginRight="12px">
      <InputLabel sx={{ marginBottom: '0', width: '100px' }}>Source type: </InputLabel>
      <SelectStyled
        placeholder="Select option"
        sx={{ width: '220px', height: '40px' }}
        value={value}
        onChange={handleOnChange}
        className={cn(className, 'small')}
        {...props}
      >
        {Object.entries(SourceType).map(([, key]) => (
          <MenuItemStyled key={key} value={key}>
            {SOURCE_STATUS_NAME[key]}
          </MenuItemStyled>
        ))}
        {value === 'mixed' && (
          <MenuItemStyled value="mixed" color="black">
            Mixed
          </MenuItemStyled>
        )}
      </SelectStyled>
    </Box>
  );
};
