import styled from 'styled-components';
import MuiListItemButton from '@mui/material/ListItemButton';

export interface CollapseButtonProps {
  open: boolean;
}

export const SidebarWrap = styled.aside`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 8px;
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

export const CollapseButton = styled.div<CollapseButtonProps>`
  height: 48px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;

  svg {
    margin-right: 12px;
    transform: rotateZ(${(props) => (props.open ? 0 : 180)}deg);
  }
`;

export const ListItemButton = styled(MuiListItemButton)`
  border-radius: 8px !important;
  height: 48px;

  &.active {
    background-color: ${(props) => props.theme.palette.primary.light} !important;
    svg {
      fill: ${(props) => props.theme.palette.primary.main};
    }

    .MuiTypography-root {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light} !important;
    svg {
      fill: ${(props) => props.theme.palette.primary.main};
    }

    .MuiTypography-root {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;
