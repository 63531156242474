import { GridColDef, GridRowModel } from '@mui/x-data-grid';
import { Typography } from '@mui/material';

import { ModelType } from '@services';
import { removedCellClassName as cellClassName } from '@helpers';
import { InfoCell } from '@atoms';
import { renderActionsButtonMenu } from '@molecules/Table/cells/renderActionsButton';

interface Props {
  onEdit: (row: GridRowModel) => void;
  onDelete: (row: GridRowModel) => void;
}

export const getColumns = ({ onEdit, onDelete }: Props): GridColDef<ModelType>[] => {
  const actionsCreator = (row: GridRowModel) => [
    { name: 'Edit', onClick: () => onEdit(row) },
    { name: 'Delete', onClick: () => onDelete(row) },
  ];

  return [
    {
      field: 'username',
      headerName: 'Assets',
      width: 336,
      cellClassName,
      renderCell: ({ row: { username, photo_url } }) => (
        <InfoCell name={username} avatarUrl={photo_url} />
      ),
    },
    {
      field: 'links',
      headerName: 'Links found / deleted',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      cellClassName,
      renderCell: ({ value: { found, deleted } }) => (
        <>
          <Typography variant="subtitle2" color="error.main">
            {found}
          </Typography>
          <Typography ml="3px" mr="3px" variant="subtitle2" color="text.disabled">
            /
          </Typography>
          <Typography variant="subtitle2" color="success.main">
            {deleted}
          </Typography>
        </>
      ),
    },
    {
      field: 'action',
      headerName: '',
      width: 54,
      sortable: false,
      flex: 1,
      cellClassName,
      renderCell: renderActionsButtonMenu(actionsCreator),
    },
  ];
};
