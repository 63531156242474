import { FC } from 'react';
import { MenuItem, Select, SelectProps } from '@mui/material';
import { FilterCloseWrap } from './FilterCloseWrap';
import type { OptionType } from '@services';

interface Props extends SelectProps {
  accountList: OptionType[];
  onClose?: () => void;
  defaultValue?: string;
}

export const SocialAccountFilter: FC<Props> = ({
  accountList,
  onClose,
  defaultValue,
  ...props
}) => (
  <FilterCloseWrap onClose={onClose}>
    <Select sx={{ height: '50px' }} defaultValue={defaultValue} {...props}>
      {accountList.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  </FilterCloseWrap>
);
