import { FC } from 'react';
import { copyToClipboard } from '@helpers';
import { Typography, Box, Button, Tooltip } from '@mui/material';
import { Modal } from '@atoms';

interface Props {
  onClose: () => void;
  open: boolean;
  link: string;
}

export const CopyBillingLinkModal: FC<Props> = ({ onClose, open, link }) => (
  <Modal title="Copy generated link" open={open} onClose={onClose}>
    <Box>
      <Typography variant="body2" color="text.secondary" sx={{ mt: '8px', mb: '40px' }}>
        We will update the billing information in the Admin panel once
        <br />
        payment is done.
      </Typography>
      <Box
        sx={{
          border: '1px solid #0000003B',
          borderRadius: '8px',
          padding: '4px 4px 4px 12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tooltip title={<Box maxWidth="400px">{link}</Box>}>
          <Typography className="ellipsis" sx={{ width: '403px' }}>
            {link}
          </Typography>
        </Tooltip>
        <Button
          id="copy-button"
          variant="contained"
          sx={{ width: '105px', height: '42px', padding: '8px 16px' }}
          onClick={() => copyToClipboard(link)}
        >
          Copy link
        </Button>
      </Box>
    </Box>
  </Modal>
);
