import { useState } from 'react';
import { Grid, Popover } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import Markdown from 'react-markdown';
import { Box } from '@mui/system';
import { renderTagCell } from './renderTagCell';

export const renderTagsCell = (value: {
  classical_models: any;
  llava: any;
  infringing_match: {
    label: string;
    reasons: string;
    probability: number;
  };
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [anchorEl, setAnchorEl] = useState<any | null>(null);

  return (
    <Grid container gap={2} sx={{ margin: '0 24px', alignItems: 'center' }}>
      {Array.from(
        new Set([
          ...Object.keys(value?.classical_models ?? {}),
          ...Object.keys(value?.llava ?? {}),
        ]),
      ).map(
        (x) =>
          (value?.classical_models?.[x] || value?.llava?.[x]) &&
          renderTagCell(x, !!value?.classical_models?.[x] && !!value?.llava?.[x]),
      )}

      <InfoRounded
        onMouseEnter={(e) => !!value.infringing_match && setAnchorEl(e.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
        color={
          // eslint-disable-next-line no-nested-ternary
          !value.infringing_match
            ? 'disabled'
            : value.infringing_match?.probability < 80
              ? 'action'
              : 'warning'
        }
      />
      <Popover
        sx={{ pointerEvents: 'none' }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box px={2} maxWidth={600}>
          <Markdown>{value.infringing_match?.reasons}</Markdown>
        </Box>
      </Popover>
    </Grid>
  );
};
