import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';
import { DATE_FORMAT, LINK_STATUS_CONTENT, SOURCE_STATUS_NAME } from '@constants';
import { SourceType } from '@services';
import { Chip } from '@mui/material';
import { LinkPreview } from '@atoms';
import { RenderLinkCell } from '@molecules/Table/cells';

export const getColumns = (filterText?: string): GridColDef[] => [
  {
    field: 'url',
    headerName: 'Link',
    flex: 1,
    minWidth: 300,
    renderCell: ({ row: { url, id } }) => (
      <RenderLinkCell filterText={filterText} idList={JSON.parse(id)} value={url} />
    ),
  },
  {
    field: 'preview',
    maxWidth: 70,
    headerName: '',
    sortable: false,
    renderCell: ({ row: { url } }) => <LinkPreview src={url} />,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 2,
    minWidth: 180,
    renderCell: ({ row: { status } }) => {
      const statusProps = LINK_STATUS_CONTENT[status];

      return <Chip label={statusProps?.label} color={statusProps?.color} variant="filled" />;
    },
  },
  {
    field: 'sourceType',
    headerName: 'Source type',
    flex: 2,
    renderCell: ({ row: { sourceType } }) => SOURCE_STATUS_NAME[sourceType as SourceType],
  },
  {
    field: 'updatedAt',
    headerName: 'Update date',
    flex: 2,
    minWidth: 170,
    renderCell: ({ row: { updatedAt } }) => dayjs(updatedAt).format(DATE_FORMAT),
  },
  {
    field: 'createdAt',
    headerName: 'Date of addition',
    flex: 2,
    minWidth: 170,
    renderCell: ({ row: { createdAt } }) => dayjs(createdAt).format(DATE_FORMAT),
  },
];
