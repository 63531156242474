import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Axios, getNotificationRedirectUrl } from '@helpers';

import { NotificationType, NotificationResponseType } from '@services';
import { Tabs, Box, Typography, Tab, Button, LinearProgress, Fade } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { NotificationMessage } from '@atoms';
import { InnerView } from '@templates';
import InfiniteScroll from 'react-infinite-scroll-component';

enum TabValue {
  ALL,
  SUBMITTED_LINKS,
  CHANGE_PLANS,
  NEW_SIGNUPS,
  CANCELLATIONS,
  TRIAL_CUSTOMERS,
  PLATINUM_CUSTOMERS,
}
const tabList = [
  { label: 'All', value: TabValue.ALL, filter: null },
  { label: 'Submitted', value: TabValue.SUBMITTED_LINKS, filter: 'SubmittedLinks' },
  { label: 'Change plans', value: TabValue.CHANGE_PLANS, filter: 'ChangePlans' },
  { label: 'New signup', value: TabValue.NEW_SIGNUPS, filter: 'NewSignUps' },
  { label: 'Cancellations', value: TabValue.CANCELLATIONS, filter: 'Cancellations' },
  { label: 'Trial customers', value: TabValue.TRIAL_CUSTOMERS, filter: 'TrialCustomers' },
  // { label: 'Platinum customers', value: TabValue.PLATINUM_CUSTOMERS, filter: 'PlatinumCustomers' },
];

const PAGE_LENGTH = 10;

interface Props {
  title: string;
}

export const AllNotification: FC<Props> = ({ title }) => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(0);
  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setRenderData([]);
    setPage(0);
    setTabValue(newValue);
  };
  const [renderData, setRenderData] = useState<NotificationType[]>([]);

  const filterBy = tabList.find((item) => item.value === tabValue)?.filter;

  const { data, refetch, isLoading } = useQuery<void, void, NotificationResponseType>(
    [page, tabValue],
    () =>
      Axios.get(`/user/notifications?size=${PAGE_LENGTH}`, {
        params: { filterBy, page },
      }).then((res) => res.data),
  );

  const { mutate: readMessage } = useMutation<void, void, string | undefined>(
    (id) => Axios.post(id ? `/user/notifications/read/${id}` : '/user/notifications/read'),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const hasMessages = Boolean(renderData?.length);
  const hasUnread = data?.items?.some((item) => !item.isRead) || false;
  const hasMore = Boolean((page + 1) * PAGE_LENGTH <= (data?.totalCount || 0));
  useEffect(() => {
    const result = data?.items || [];
    setRenderData((prevState) => [...prevState, ...result]);
  }, [data?.items]);

  const handleClickNotificationItem = (item: NotificationType) => {
    if (!item?.isRead) readMessage(item.id);
    if (!item?.redirect) return;
    const path = getNotificationRedirectUrl(item?.redirect);
    navigate(path);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Box mt="20px">
        <Box display="flex" position="relative">
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            aria-label="nav tabs example"
            sx={{ width: '100%' }}
          >
            {tabList.map(({ label, value }) => (
              <Tab label={label} key={value} />
            ))}
          </Tabs>
        </Box>

        <InnerView
          id="notificationPageInner"
          autoHeight
          mt="32px"
          sx={{
            paddingTop: '0',
            paddingLeft: '0',
          }}
        >
          <Fade in={isLoading}>
            <LinearProgress sx={{ height: 4 }} />
          </Fade>
          {hasMessages && (
            <Button
              variant="text"
              color="primary"
              sx={{ p: 0, paddingTop: '12px', paddingLeft: '24px' }}
              disableRipple
              disabled={!hasUnread}
              onClick={() => readMessage('')}
            >
              Mark as read
            </Button>
          )}
          <Box>
            {hasMessages ? (
              <InfiniteScroll
                dataLength={data?.totalCount || 0} // This is important field to render the next data
                next={() => setPage(page + 1)}
                scrollableTarget="pageInner"
                hasMore={hasMore}
                loader={<div>loading</div>}
              >
                {renderData?.map((item) => (
                  <NotificationMessage
                    sx={{
                      cursor: 'pointer',
                      borderRadius: '8px',
                      '&:hover': {
                        background: '#eee',
                      },
                    }}
                    key={item.id}
                    {...item}
                    onClick={() => handleClickNotificationItem(item)}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <Typography variant="subtitle1" sx={{ margin: '0 auto 0 auto' }} pt={3} pl={6}>
                You have no notifications yet
              </Typography>
            )}
          </Box>
        </InnerView>
      </Box>
      <Outlet />
    </>
  );
};
