import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { Modal } from '../Modal';

interface MessageModalProps {
  children?: React.ReactNode;
  open: boolean;
  onClose: () => void;
  confirmed?: boolean;
  title?: string;
  description?: React.ReactElement | string;
  caption?: string;
  Icon?: React.ReactElement;
  Action?: React.ReactElement;
  width?: string;
}

export const MessageModal: FC<MessageModalProps> = ({
  open,
  onClose,
  children,
  title,
  confirmed,
  description,
  caption,
  width = '200px',
  Icon,
  Action,
}) => (
  <Modal title={title} open={open} onClose={onClose}>
    {confirmed ? (
      <Box
        width={width}
        margin="auto"
        display="flex"
        alignItems="center"
        flexDirection="column"
        pb="12px"
      >
        {Icon}
        {description && (
          <Typography mt="16px" mb="48px" variant="body1" textAlign="center">
            {description}
          </Typography>
        )}
        {caption && (
          <Typography mb="48px" variant="body1" textAlign="center">
            {caption}
          </Typography>
        )}
        {Action}
      </Box>
    ) : (
      <Box>{children}</Box>
    )}
  </Modal>
);
