import { FC } from 'react';

import { Button, Modal } from '@atoms';
import { Box, Typography } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';

interface ModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  open: boolean;
  fullName?: string;
}

export const BlockMemberModal: FC<ModalProps> = ({ onCancel, onSubmit, open, fullName }) => (
  <Modal open={open} onClose={onCancel}>
    <Box margin="auto" display="flex" alignItems="center" flexDirection="column">
      <BlockIcon color="error" sx={{ fontSize: '83px' }} />
      <Typography mt="8px" variant="h4" textAlign="center">
        Block user?
      </Typography>
      <Typography mt="16px" variant="body1" textAlign="center" width="380px">
        If you block <b>{fullName}</b> the user no longer have access to the Ceartas profile
      </Typography>
      <Box display="flex" mt="48px" mb="12px" gap="24px">
        <Button onClick={onSubmit} variant="contained" color="error" sx={{ width: 180 }}>
          Block
        </Button>
        <Button onClick={onCancel} variant="contained" color="primary" sx={{ width: 180 }}>
          Cancel
        </Button>
      </Box>
    </Box>
  </Modal>
);
