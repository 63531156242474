/* eslint-disable no-useless-escape */
import { FC } from 'react';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { AuthContainer } from '@templates';
import { useSignIn } from '@hooks';
import { PasswordForm } from '@organisms';
import { Axios } from '@helpers';

interface FormData {
  password: string;
}

export const Signup: FC = () => {
  const url = new URL(window.location.href);
  const email = url.searchParams.get('email')?.replaceAll(' ', '+') || ''; // empty string to avoid TS issue
  const fullName = url.searchParams.get('fullName');
  const key = url.searchParams.get('key');

  const { handleSubmit, control, watch } = useForm<FormData>();
  const { signIn, isFetching } = useSignIn();

  const { mutate: signUp, isLoading } = useMutation<unknown, unknown, FormData>(
    (data) => Axios.post('/auth/sign-up/confirm', { ...data, email, key }),
    {
      onSuccess: () => signIn(email, password),
    },
  );

  const onSubmit = (data: FormData) => signUp(data);

  const password = watch('password');

  return (
    <AuthContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h4" mb="10px">
          Sign up
        </Typography>
        <Typography textAlign="center" color="text.secondary" variant="body2" mb="24px">
          Hi,{' '}
          <Typography variant="body2" component="span" fontWeight={600} color="text.primary">
            {fullName}
          </Typography>
          👋
          <br /> Create a password that you will remember and match all criterias listed below to
          start working with Ceartas Admin with your email{' '}
          <Typography variant="body2" component="span" fontWeight={600} color="text.primary">
            {email}
          </Typography>
        </Typography>
        <PasswordForm
          control={control}
          password={password}
          buttonDisabled={isFetching || isLoading}
          buttonText="Sign up"
          fieldName="password"
        />
      </form>
    </AuthContainer>
  );
};
