import dayjs from 'dayjs';
import { GridColDef, GridRowModel } from '@mui/x-data-grid';
import { Typography, Chip } from '@mui/material';

import { TableBusinessAccount } from '@services';
import { getLastModifiedColor, getStatus, removedCellClassName } from '@helpers';
import { DATE_FORMAT } from '@constants';
import { InfoCell, UserPaymentStatus } from '@atoms';
import { renderActionsButtonMenu, renderPlanCell, renderSlotCell } from '@molecules/Table/cells';

interface Props {
  onEdit: (row: GridRowModel) => void;
  onMoveAssets: (row: GridRowModel) => void;
}
export const getColumns = ({ onEdit, onMoveAssets }: Props): GridColDef<TableBusinessAccount>[] => {
  const actionsCreator = (row: GridRowModel) => [
    { name: 'Edit', onClick: () => onEdit(row) },
    { name: 'Move assets', onClick: () => onMoveAssets(row) },
  ];
  return [
    {
      field: 'companyName',
      headerName: 'Company',
      minWidth: 230,
      flex: 2,
      cellClassName: removedCellClassName,
      renderCell: ({ row: { companyName, email, logo } }) => (
        <InfoCell name={companyName} email={email} avatarUrl={logo} />
      ),
    },
    {
      field: 'slots',
      headerName: 'Number of slots',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      cellClassName: removedCellClassName,
      renderCell: renderSlotCell,
    },
    {
      field: 'plan',
      headerName: 'Plan',
      minWidth: 80,
      flex: 1,
      cellClassName: removedCellClassName,
      renderCell: renderPlanCell,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 130,
      flex: 1,
      cellClassName: removedCellClassName,
      renderCell: ({ row: { status, plan, isRemoved } }) => {
        const paymentStatus = getStatus(status, plan, isRemoved);
        return <UserPaymentStatus status={paymentStatus} />;
      },
    },
    {
      field: 'foundDeleted',
      headerName: 'Links found / deleted',
      minWidth: 200,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      cellClassName: removedCellClassName,
      sortable: false,
      renderCell: ({
        row: {
          links: { found, deleted },
        },
      }) => (
        <>
          <Typography variant="subtitle2" color="error.main">
            {found}
          </Typography>
          <Typography ml="3px" mr="3px" variant="subtitle2" color="text.disabled">
            /
          </Typography>
          <Typography variant="subtitle2" color="success.main">
            {deleted}
          </Typography>
        </>
      ),
    },
    {
      field: 'lastScanningDate',
      headerName: 'Last Scanning',
      minWidth: 140,
      flex: 1,
      cellClassName: removedCellClassName,
      renderCell: ({ value }) =>
        value ? (
          <Chip label={dayjs(value).format(DATE_FORMAT)} color={getLastModifiedColor(value)} />
        ) : (
          <Typography color="text.disabled">No scanning</Typography>
        ),
    },
    {
      field: 'joinDate',
      headerName: 'Join date',
      minWidth: 110,
      flex: 1,
      cellClassName: removedCellClassName,
      renderCell: ({ row: { joinDate } }) => dayjs(joinDate).format(DATE_FORMAT),
    },
    {
      field: 'action',
      headerName: '',
      width: 54,
      sortable: false,
      flex: 1,
      cellClassName: removedCellClassName,
      renderCell: renderActionsButtonMenu(actionsCreator),
    },
  ];
};
