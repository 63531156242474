import { FC } from 'react';
import {
  Typography,
  Box,
  IconButton,
  DialogProps as MuiModalProps,
  Dialog as MuiModal,
  DialogContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ModalProps extends MuiModalProps {
  onClose: () => void;
  title?: string;
  width?: string;
}

const Modal: FC<ModalProps> = ({ children, onClose, title, ...props }) => (
  <MuiModal onClose={onClose} keepMounted={false} {...props} fullWidth scroll="body">
    <DialogContent sx={{ p: 0 }}>
      <Box py="24px" px="32px" height="100%" width="100%">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">{title}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {children}
      </Box>
    </DialogContent>
  </MuiModal>
);

export default Modal;
