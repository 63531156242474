import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Plans, ScanManagementType } from '@services';
import { DATE_FORMAT, PLANS_LABEL, SCAN_SOURCES_LABEL } from '@constants';
import { renderChipsCell, renderDateCell } from '@molecules/Table/cells';
import { IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export const columns: GridColDef<ScanManagementType>[] = [
  {
    field: 'identifierValue',
    headerName: 'Subscription plan',
    minWidth: 180,
    flex: 1,
    renderCell: ({ value }) => PLANS_LABEL[value as Plans],
  },
  {
    field: 'keywords',
    headerName: 'Keywords',
    minWidth: 190,
    flex: 1,
    renderCell: renderChipsCell(),
  },
  {
    field: 'pages',
    renderHeader: () => (
      <Typography variant="body2" fontWeight="700">
        <Typography fontFamily="sans-serif" component="span" fontWeight="700">
          &#8470;
        </Typography>{' '}
        of pages
      </Typography>
    ),
    minWidth: 139,
    flex: 1,
  },
  {
    field: 'scanSources',
    headerName: 'Scan type',
    minWidth: 190,
    flex: 1,
    renderCell: renderChipsCell(SCAN_SOURCES_LABEL),
  },
  {
    field: 'lastScanAt',
    headerName: 'Last scanning',
    minWidth: 153,
    flex: 1,
    renderCell: renderDateCell,
  },
  {
    field: 'nextScanAt',
    headerName: 'Next scanning',
    minWidth: 157,
    flex: 1,
    renderCell: ({ value }) => (
      <>
        {value ? <Typography variant="body2">{dayjs(value).format(DATE_FORMAT)}</Typography> : null}
        <IconButton className="show-on-hover" sx={{ ml: '20px' }}>
          <EditIcon />
        </IconButton>
      </>
    ),
  },
];
