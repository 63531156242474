import { FC } from 'react';
import IconButton from '@mui/material/IconButton';
import { useMutation, useQueryClient } from 'react-query';

import { ReactComponent as BlockIcon } from '@assets/user-actions/block.svg';
import { ReactComponent as ActivateIcon } from '@assets/user-actions/activate.svg';
import { ActiveStatus, User } from '@services';
import { Axios } from '@helpers';

interface UserActionProps {
  status: ActiveStatus;
  userId: string;
}

export const UserAction: FC<UserActionProps> = ({ status, userId }) => {
  const queryClient = useQueryClient();

  const { mutate: changeStatus, isLoading } = useMutation<
    { data: User },
    unknown,
    { status: ActiveStatus }
  >((data) => Axios.patch(`/admin/users/${userId}/status`, data), {
    onSuccess: (data) => {
      if (!data?.data) return;
      const [queries] = queryClient.getQueriesData<{ items: User[] }>('users');
      const newData = {
        ...queries[1],
        items: queries[1]?.items?.map((user) => (user.id === userId ? data.data : user)),
      };

      queryClient.setQueryData(queries[0], newData);
    },
  });

  if (status === ActiveStatus.Active) {
    return (
      <IconButton
        disabled={isLoading}
        className="show-on-hover"
        onClick={(e) => {
          e.stopPropagation();
          changeStatus({ status: ActiveStatus.Disabled });
        }}
      >
        <BlockIcon />
      </IconButton>
    );
  }

  if (status === ActiveStatus.Disabled) {
    return (
      <IconButton
        disabled={isLoading}
        className="show-on-hover"
        onClick={(e) => {
          e.stopPropagation();
          changeStatus({ status: ActiveStatus.Active });
        }}
      >
        <ActivateIcon />
      </IconButton>
    );
  }

  return null;
};
