import React, { HTMLAttributes, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useMutation } from 'react-query';

import { SocialMediaAccount } from '@services';
import { Axios } from '@helpers';

import { LoadingButton } from '@mui/lab';
import { IconButton, TextField, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import { StyledCard } from './styles';
import { LINK_PATTERN } from './utils';

interface Props<T extends FieldValues> extends HTMLAttributes<HTMLElement> {
  control: Control<T, object>;
  name: Path<T>;
}

type Response = {
  data: {
    redirectLink: string;
    title: string;
    type: string;
  };
};

const SocialMediaAccountsForm = <T extends object>({ control, name }: Props<T>) => {
  const [value, setValue] = useState('');

  const { mutate: parseUrl, isLoading } = useMutation<Response, unknown, unknown>(
    (data) => Axios.post('admin/companies/social-media/parser', data),
    {
      onSuccess: (res) => {
        const parsedData = res.data;
        field.onChange([...socialMediaAccounts, parsedData]);
        setValue('');
      },
    },
  );

  const { field } = useController({
    name,
    control,
  });

  const socialMediaAccounts = field.value as unknown as SocialMediaAccount[];

  const onDeleteAccount = (url?: string) => {
    const newSocialMedia = socialMediaAccounts.filter(
      (account: SocialMediaAccount) => account.redirectLink !== url,
    );
    field.onChange(newSocialMedia);
  };

  return (
    <>
      <TextField
        value={value}
        placeholder="https://onlyfans.com/username"
        onChange={(e) => setValue(e.target.value)}
        InputProps={{
          endAdornment: (
            <LoadingButton
              sx={{ height: '40px' }}
              size="small"
              variant="contained"
              onClick={() => parseUrl({ socialMediaUrl: value })}
              disabled={!LINK_PATTERN.test(value)}
              loading={isLoading}
            >
              Add
            </LoadingButton>
          ),
        }}
      />

      {socialMediaAccounts.map((account: SocialMediaAccount) => (
        <StyledCard key={account.id}>
          <Typography>{account.title}</Typography>
          <IconButton onClick={() => onDeleteAccount(account.redirectLink)}>
            <Close />
          </IconButton>
        </StyledCard>
      ))}
    </>
  );
};

export default SocialMediaAccountsForm;
