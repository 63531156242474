import { FC } from 'react';
import { WorkingModeTable } from '@organisms/Tables';
import { LinkStatuses } from '@services';

export const WorkingModeRaw: FC = () => <WorkingModeTable status={LinkStatuses.Raw} />;

export const WorkingModeInfringing: FC = () => (
  <WorkingModeTable status={LinkStatuses.Infringing} />
);

export const WorkingModeFalsePositive: FC = () => (
  <WorkingModeTable status={LinkStatuses.FalsePositive} />
);
