import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { ReactComponent as SearchIcon } from '@assets/search.svg';
import TuneIcon from '@mui/icons-material/Tune';

export const NoRowsOverlay: FC<{ filterText?: string; hasSelectedFilters?: boolean }> = ({
  filterText,
  hasSelectedFilters,
}) => {
  const renderNoData = () => <>No results found</>;
  const renderNoFilterText = () => (
    <>
      No results found for <b>{filterText}</b>
      <br />
      Try a different search
    </>
  );
  const renderNoFilters = () => (
    <>
      No results found.
      <br />
      Try the combination of other filters
    </>
  );
  const renderText = () => {
    if (filterText) {
      return renderNoFilterText();
    }
    if (hasSelectedFilters) {
      return renderNoFilters();
    }
    return renderNoData();
  };

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {hasSelectedFilters && !filterText ? (
        <TuneIcon color="primary" sx={{ fontSize: 75 }} />
      ) : (
        <SearchIcon />
      )}
      <Typography mt="8px" variant="body1" textAlign="center">
        {renderText()}
      </Typography>
    </Box>
  );
};
