import { useState, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import { NotificationType } from '@services';
import { getToken } from '@helpers';

interface ServerToClientEvents {
  notification: (notification: NotificationType) => void;
}

interface ClientToServerEvents {
  noFunc: () => void;
}

export const useSocket = () => {
  const [socket, setSocket] = useState<Socket<ServerToClientEvents, ClientToServerEvents> | null>(
    null,
  );
  const [token, setToken] = useState<string | null>(null);

  const getAccessToken = async () => {
    const accessToken = await getToken();
    setToken(accessToken);
  };

  useEffect(() => {
    if (!token) {
      getAccessToken();
    } else {
      const ioSocket = io(process.env.REACT_APP_API_URL || '', {
        secure: true,
        transports: ['websocket', 'polling'],
        extraHeaders: {
          Authorization: token as string,
        },
      });

      setSocket(ioSocket);

      ioSocket.on('connect', () => {
        console.log('connected socket-', ioSocket.id);
      });

      ioSocket.on('disconnect', () => {
        console.log('disconnected');
      });

      const cleanup = () => {
        console.log('cleanup disconnect socket');
        ioSocket.disconnect();
      };

      return cleanup;
    }
    return undefined;
  }, [token]);

  return socket;
};
