import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import UploadIcon from '@mui/icons-material/Upload';

import { ROUTES } from '@constants';
import { successMessage, warningMessage, WhitelistCompanyType } from '@services';
import { Axios } from '@helpers';
import { useConfirmationModal } from '@hooks';

import { Divider, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Button, Modal } from '@atoms';
import { ConfirmModal } from '@organisms/Modals';

import { AddLinkStep } from './AddLinkStep';
import { useAddLinkStep } from './hooks';
import { FinalFormData } from './utils';

interface IModalProps {
  isBusiness?: boolean;
}
export const AddWhitelistLinkModal = ({ isBusiness }: IModalProps) => {
  const { id, assetId } = useParams();
  const backPath = isBusiness
    ? `/${ROUTES.BusinessAccounts}/${id}/${ROUTES.Whitelist}`
    : `/${ROUTES.ContentCreators}/${id}/${assetId}/${ROUTES.Whitelist}`;
  const navigate = useNavigate();
  const selectedCompanies: WhitelistCompanyType[] = [
    {
      id: id || '',
      logo: '',
      name: '',
    },
  ];
  const isDisabledNext = !selectedCompanies.length;

  const handleOnClose = () => {
    navigate(backPath);
    sessionStorage.removeItem('add-whitelist-close-path');
  };

  const { mutate: addWhitelist, isLoading } = useMutation(
    (data: { companyIds: string[]; domains: string[] }) =>
      Axios.post<{
        added: string[];
        duplicates: string[];
      }>('/admin/whitelist-domains', data),
    {
      onSuccess: (res) => {
        handleOnClose();

        if (res?.data?.duplicates && res?.data?.duplicates.length > 0) {
          warningMessage(
            `Some domains already exist in the whitelist: ${res?.data?.duplicates.join(', ')}`,
          );
        } else {
          successMessage('New whitelist links was added to selected accounts!');
        }
      },
    },
  );

  const { mutate: submitCSV, isLoading: isLoadingCSV } = useMutation(
    ({ file, companyIds }: { file: File; companyIds: string[] }) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('companyIds', companyIds as unknown as string);

      return Axios.post<{
        added: string[];
        duplicates: string[];
      }>('/admin/whitelist-domains/csv', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    {
      onSuccess: (res) => {
        handleOnClose();

        if (res?.data?.duplicates && res?.data?.duplicates.length > 0) {
          warningMessage(
            `Some domains already exist in the whitelist: ${res?.data?.duplicates.join(', ')}`,
          );
        } else {
          successMessage('New whitelist links was added to selected accounts!');
        }
      },
    },
  );

  const finalSubmit = ({ file, domains, companyIds }: FinalFormData) => {
    if (file) {
      submitCSV({ file, companyIds });
    }
    if (domains?.length) {
      addWhitelist({ companyIds, domains });
    }
  };

  const { onSubmit, control, isDisabled, onUpload, errors } = useAddLinkStep({
    users: selectedCompanies,
    onSubmit: finalSubmit,
  });

  const [isConfirmOpen, onOpenConfirm, onConfirmCancel, onSubmitConfirm] = useConfirmationModal(
    false,
    handleOnClose,
  );

  const isDirty = !isDisabledNext || !isDisabled;

  return (
    <>
      <ConfirmModal
        open={isConfirmOpen}
        onCancel={onConfirmCancel}
        onSubmit={onSubmitConfirm}
        title="Are you sure you want to close modal?"
        submitText="Yes, close"
      />
      <Modal
        title="Upload links"
        width="600px"
        sx={isConfirmOpen ? { opacity: 0 } : {}}
        onClose={isDirty ? onOpenConfirm : handleOnClose}
        open
      >
        <Box display="flex" flexDirection="column">
          <Divider sx={{ my: '24px', mx: '-32px' }} />
          <Box height="auto" overflow="hidden">
            <AddLinkStep control={control} errors={errors} onUpload={onUpload} />
          </Box>
          <Box display="flex">
            <Button
              variant="text"
              color="secondary"
              sx={{ marginLeft: 'auto' }}
              onClick={isDirty ? onOpenConfirm : handleOnClose}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              startIcon={<UploadIcon />}
              onClick={onSubmit}
              sx={{ width: '148px' }}
              loading={isLoading || isLoadingCSV}
              disabled={isDisabled}
            >
              Upload
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
