import styled from 'styled-components';

import { MemberStatus } from '@services';

export interface UserStatusWrapProps {
  status: MemberStatus;
  color?: string;
}

export const MemberStatusWrap = styled.div<UserStatusWrapProps>`
  height: 32px;
  padding-right: 10px;
  padding-left: 7px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.status[props.color || props.status]};
  border-radius: 16px;
  color: ${(props) => props.theme.palette.common.white};

  svg {
    margin-right: 9px;
  }
`;

export const FakeMemberStatusWrap = styled.div<UserStatusWrapProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(props) => props.theme.status[props.color || props.status]};

  svg path {
    fill: ${(props) => props.theme.status[props.color || props.status]}!important;
  }
`;
