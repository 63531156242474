import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HAS_SOCIAL_ERROR, loginValidation, ROUTES } from '@constants';
import { useSignIn } from '@hooks';
import { errorMessage } from '@services';
import { ReactComponent as GoogleLogo } from '@assets/google.svg';

import { Button, Divider, Typography, Box } from '@mui/material';
import { TextField, PasswordField, Link } from '@atoms';
import { AuthContainer } from '@templates';

interface FormData {
  email: string;
  password: string;
}

export const Login: FC = () => {
  const { handleSubmit, control } = useForm<FormData>({
    resolver: yupResolver(loginValidation),
    mode: 'onChange',
    delayError: 700,
  });

  const { signIn, signInWithGoogle, isFetching, error } = useSignIn();

  const onSubmit = ({ email, password }: FormData) => signIn(email, password);

  useEffect(() => {
    const item = sessionStorage.getItem(HAS_SOCIAL_ERROR);
    if (item) {
      errorMessage("User doesn't exist!");
      sessionStorage.removeItem(HAS_SOCIAL_ERROR);
    }
  }, []);

  return (
    <AuthContainer>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4" mb="10px">
          Login
        </Typography>
        <Typography color="text.secondary" variant="body2" mb="24px">
          Login to access Ceartas Admin application
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ width: '100%', fontWeight: 700 }}
          startIcon={<GoogleLogo />}
          onClick={signInWithGoogle}
        >
          Sign in with Google
        </Button>
        <Divider sx={{ margin: '16px 0 4px', width: '100%' }}>Or</Divider>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb="16px" width="100%">
          <TextField name="email" control={control} label="Email" placeholder="name@ceartas.io" />
        </Box>
        <PasswordField
          placeholder="Enter the password"
          name="password"
          label="Password"
          control={control}
          outsideError={error}
        />
        <Box mt="24px" mb="24px" width="100%">
          <Button fullWidth variant="contained" type="submit" disabled={isFetching}>
            Login
          </Button>
        </Box>
        <Link to={`/${ROUTES.ForgotPassword}`}>Forgot password?</Link>
      </form>
    </AuthContainer>
  );
};
