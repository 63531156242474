import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { ModelType } from '@services';
import { removedCellClassName } from '@helpers';

import { InfoCell } from '@atoms';
import { renderManagerCell } from '@molecules/Table/cells';
import { DATE_FORMAT } from '@constants';
import dayjs from 'dayjs';

export const columns: GridColDef<ModelType>[] = [
  {
    field: 'username',
    headerName: 'Name',
    minWidth: 200,
    flex: 1,
    cellClassName: removedCellClassName,
    valueGetter: (params: GridValueGetterParams) => params.row.username,
    renderCell: ({ row: { username, photo_url } }) => (
      <InfoCell name={username} avatarUrl={photo_url} />
    ),
  },
  {
    field: 'joinDate',
    headerName: 'Join date',
    width: 130,
    cellClassName: removedCellClassName,
    renderCell: ({ value }) => dayjs(value).format(DATE_FORMAT),
  },
  {
    field: 'manager',
    headerName: 'Manager',
    minWidth: 200,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: renderManagerCell(true),
  },
  {
    field: 'companyName',
    headerName: 'Company',
    minWidth: 126,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { company } }) => company?.name,
  },
];
