import { FC, useState } from 'react';
import { Typography, Box, Button } from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import { useMutation } from 'react-query';

import { useProfile } from '@hooks/useProfile';
import { UserAvatar } from '@molecules';
import { BASE_URL, ROUTES } from '@constants';
import { ChangePasswordConfirmModal } from '@organisms/Modals';
import { Axios } from '@helpers';

export const AccountInfo: FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { profile } = useProfile();
  const { email, firstName, lastName, role, avatarUrl } = profile;
  const fullName = `${firstName || ''} ${lastName || ''}`;

  const { mutate: resetPassword, isLoading } = useMutation<unknown, unknown, unknown>(
    () =>
      Axios.post('/auth/reset-password', {
        email,
        redirectUrl: `${BASE_URL}/${ROUTES.ChangePassword}`,
      }),
    {
      onSuccess: () => setModalOpen(true),
    },
  );

  return (
    <>
      <ChangePasswordConfirmModal open={modalOpen} onClose={() => setModalOpen(false)} />
      <Box mt="18px">
        <UserAvatar fullName={fullName} role={role} avatarUrl={avatarUrl} size={60} />
      </Box>
      <Typography mt="24px" mb="8px" variant="body1" fontWeight={600}>
        Full name
      </Typography>
      <Typography mb="8px" variant="h6">
        {fullName}
      </Typography>
      <Typography mt="24px" mb="8px" variant="body1" fontWeight={600}>
        Email
      </Typography>
      <Typography mb="34px" variant="h6">
        {email}
      </Typography>
      <Typography mb="8px" variant="h5">
        Password
      </Typography>
      <Typography mb="10px" variant="body2" color="secondary">
        Change your account password often to prevent unauthorized access to your account.
      </Typography>
      <Button
        sx={{ padding: '0 18px' }}
        color="black"
        variant="contained"
        startIcon={<PasswordIcon />}
        onClick={resetPassword}
        disabled={isLoading}
      >
        Change Password
      </Button>
    </>
  );
};
