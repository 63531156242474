import { FC, useState } from 'react';
import cn from 'classnames';
import { useMutation } from 'react-query';
import { LinkStatuses } from '@services';
import { Axios, uncapitalizeFirstLetter } from '@helpers';
import { Box, Button, InputLabel, SelectChangeEvent, SelectProps } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import { useParams, useSearchParams } from 'react-router-dom';
import { ConfirmModal } from '@organisms';
import { useConfirmationModal } from '@hooks';
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { MessageModal } from '@atoms';
import { SelectStyled, MenuItemStyled } from '../Filters/styles';

interface Props extends SelectProps {
  value?: string;
  linkList: string[];
  refetch: () => void;
}

const listStatus = [
  { label: 'Raw', statusValue: LinkStatuses.Raw },
  { label: 'Infringing', statusValue: LinkStatuses.Infringing },
  { label: 'False positive', statusValue: LinkStatuses.FalsePositive },
];

export const ChangeStatusSelect: FC<Props> = ({
  refetch,
  value: defaultValue,
  linkList,
  className,
  ...props
}) => {
  const [searchParams] = useSearchParams();
  const [openConfirm, setOpenConfirm] = useState(false);
  const currentIndex = listStatus
    .map((item: any) => item.statusValue)
    .indexOf(searchParams.get('status') || 'raw');
  const defaultStatus =
    currentIndex === listStatus.length - 1 ? currentIndex - 1 : currentIndex + 1;
  const { assetId } = useParams();
  const [value, setValue] = useState(defaultValue || listStatus[defaultStatus]?.statusValue);
  const { mutate: onSubmit, isLoading } = useMutation<
    unknown,
    unknown,
    { status: string; links: string[] }
  >(
    ({ status, links }) => Axios.patch(`/admin/models/${assetId}/links/status`, { status, links }),
    {
      onSuccess: () => {
        setOpenConfirm(true);
        onCancel();
      },
    },
  );

  const getLabel = (val: string) =>
    listStatus.find((item) => item.statusValue === val)?.label || '';

  const handleOnChange = (event: SelectChangeEvent<unknown>) => {
    event.stopPropagation();
    setValue(event.target.value as string);
  };

  const handleOnSubmit = () => {
    onSubmit({
      links: linkList,
      status: uncapitalizeFirstLetter(value as string),
    });
  };

  const onCloseConfirmModal = () => {
    setOpenConfirm(false);
    refetch();
  };

  const [open, onOpen, onCancel] = useConfirmationModal(false, handleOnSubmit);

  return (
    <Box display="flex" alignItems="center">
      <InputLabel sx={{ marginBottom: '0', marginRight: '8px' }}>Change status to: </InputLabel>
      <SelectStyled
        placeholder="Select option"
        sx={{ width: '220px', height: '40px' }}
        value={value}
        onChange={handleOnChange}
        className={cn(className, 'small')}
        {...props}
      >
        {listStatus
          .filter((item) => item.statusValue !== searchParams.get('status'))
          .map(({ label, statusValue }) => (
            <MenuItemStyled key={label} value={statusValue}>
              {label}
            </MenuItemStyled>
          ))}
      </SelectStyled>
      <LoadingButton
        startIcon={<CheckIcon />}
        variant="text"
        color="primary"
        sx={{ width: '80px', padding: '10px 4px', marginLeft: '32px' }}
        onClick={onOpen}
        loading={isLoading}
        disabled={!value}
      >
        Save
      </LoadingButton>
      <ConfirmModal
        open={open}
        onCancel={onCancel}
        onSubmit={handleOnSubmit}
        title={
          <>
            Are you sure you want to change status in <br />
            selected links from{' '}
            {getLabel(searchParams.get('status') || LinkStatuses.Raw).toUpperCase()} to{' '}
            {getLabel(value).toUpperCase()}?
          </>
        }
        icon={<ReportIcon color="warning" sx={{ fontSize: '100px' }} />}
        submitText="Change"
      />
      <MessageModal
        title=""
        open={openConfirm}
        width="400px"
        onClose={onCloseConfirmModal}
        confirmed={!!true}
        description={
          <>
            {getLabel(searchParams.get('status') || LinkStatuses.Raw).toUpperCase()} status in
            selected links changed to {getLabel(value).toUpperCase()} status
          </>
        }
        Action={
          <Button color="info" variant="contained" onClick={onCloseConfirmModal} fullWidth>
            Got it
          </Button>
        }
        Icon={<CheckCircleIcon sx={{ fontSize: 83, color: '#69BEFC' }} />}
      />
    </Box>
  );
};
