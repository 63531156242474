import { Ref } from 'react';
import get from 'lodash.get';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export const KEYBOARD_KEY = {
  enter: 'Enter',
  backspace: 'Backspace',
};

export function matchIsBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function matchIsObject(value: unknown): value is object {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
}

export function assocRefToPropRef(ref: unknown, propRef: Ref<unknown> | undefined): void {
  if (typeof propRef === 'function') {
    propRef(ref);
  } else if (propRef && matchIsObject(propRef) && 'current' in propRef) {
    const pRef = propRef as any;
    pRef.current = ref;
  }
}

export function append<T extends unknown[]>(array: T, item: T[keyof T]): T {
  return [...array, item] as T;
}

export function removeIndex<T extends unknown[]>(array: T, indexItem: number): T {
  return array.filter((_, index) => indexItem !== index) as T;
}

export function updateIndex<T extends unknown[]>(array: T, indexItem: number, item: T[keyof T]): T {
  return array.map((chipItem, index) => (indexItem === index ? item : chipItem)) as T;
}

export const getErrorMessage = <TFieldValues extends FieldValues>(
  errors: FieldErrors<TFieldValues>,
  name: string,
) => {
  let errorText = get(errors, name)?.message;
  let errorIds: number[] = [];
  if (Array.isArray(errors[name])) {
    const messages = errors[name] as unknown as { message: string }[];
    errorText = messages
      .map((m) => m?.message || '')
      .filter((m) => !!m)
      .join('\n');
    errorIds = messages.map((_, i) => i).filter((m) => !!m);
  }
  return {
    errorIds,
    errorText,
  };
};
