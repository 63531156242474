import { FC, useMemo } from 'react';
import isEqual from 'lodash.isequal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { editLinkValidation } from '@constants';
import CheckIcon from '@mui/icons-material/Check';

import { Box, Typography } from '@mui/material';
import { Button, Modal, TextField } from '@atoms';

interface FormData {
  link: string;
}

interface ChangePasswordConfirmModalProps {
  onCancel: () => void;
  onSubmit: (formData: FormData) => void;
  open: boolean;
  link: string;
  title?: string;
  helperText?: string;
}

export const EditLinkModal: FC<ChangePasswordConfirmModalProps> = ({
  onCancel,
  onSubmit,
  open,
  link,
  title = 'Edit website link',
  helperText = 'Here you can update the website link.',
}) => {
  const defaultValues = useMemo(() => ({ link }), [link]);
  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
  } = useForm<FormData>({
    resolver: yupResolver(editLinkValidation),
    defaultValues,
    mode: 'onChange',
    delayError: 700,
  });

  const values = watch();
  const isDirty = !isEqual(values, defaultValues);

  return (
    <Modal open={open} onClose={onCancel} title={title}>
      <Box width="100%" display="flex" flexDirection="column">
        <Typography mb="40px" variant="body2" color="text.secondary">
          {helperText}
        </Typography>
        <TextField control={control} name="link" />
        <Box display="flex" pb="9px" mt="24px" gap="24px" marginLeft="auto">
          <Button onClick={onCancel} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            disabled={!isDirty || !isValid}
            startIcon={<CheckIcon />}
            sx={{ width: '148px' }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
