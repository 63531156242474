import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation } from 'react-query';
import { Axios } from '@helpers';
import { FilterItem, successMessage } from '@services';
import { ROUTES } from '@constants';
import { useConfirmationModal } from '@hooks';

import { Box, Typography } from '@mui/material';
import { Table } from '@molecules';
import { memberStatusFilter, renderDateFilter, roleFilter } from '@molecules/Table/filters';
import { ConfirmModal } from '@organisms/Modals';

import { getColumns, MemberData } from './utils';

const FILTERS_LIST: FilterItem[] = [
  roleFilter,
  memberStatusFilter,
  {
    name: 'Join date',
    key: 'joinDate',
    resetFields: ['joinedStartDate', 'joinedEndDate'],
    getFilter: renderDateFilter({
      key: 'joinDate',
      label: 'Join date',
      resetFields: ['joinedStartDate', 'joinedEndDate'],
    }),
  },
];

export const WorkspaceTable: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [toBlockRow, setBlockRow] = useState<MemberData | null>(null);

  const { mutate: blockMember, isLoading } = useMutation<unknown, unknown, string>(
    (memberId) => Axios.patch(`/admin/accounts/business/${id}/member/${memberId}`),
    {
      onSuccess: () => {
        successMessage('Member has blocked!');
        setBlockRow(null);
      },
    },
  );

  const submitBlock = () => {
    if (toBlockRow) {
      blockMember(toBlockRow.id);
    }
  };

  const [open, onOpen, onCancel, onSubmit] = useConfirmationModal(false, submitBlock);

  const onBlock = useCallback(
    (row) => {
      setBlockRow(row);
      onOpen();
    },
    [onOpen],
  );

  const onEdit = useCallback(({ id: memberId }) => {
    sessionStorage.setItem('workspace-table', window.location.search || '');
    navigate(`/${ROUTES.Workspace}/${id}/${memberId}/${ROUTES.AccountDetails}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () =>
      getColumns({
        onEdit,
        onBlock,
      }),
    [onEdit, onBlock],
  );

  return (
    <Box>
      <Typography variant="h5" mt="24px">
        Workspace management
      </Typography>
      <Typography variant="body2" mt="8px" mb="16px">
        Here you can find all users with their roles and permissions connected to the account.
      </Typography>
      {!isLoading && (
        <Table<MemberData>
          searchPlaceholder="Search by name or email"
          queryName="workspace-list"
          columns={columns}
          apiRoute={`/admin/accounts/workspace/${id}`}
          onRowClick={onEdit}
          filtersList={FILTERS_LIST}
        />
      )}
      <ConfirmModal
        open={open}
        onCancel={onCancel}
        onSubmit={onSubmit}
        title={`Are you sure you want to block\n${toBlockRow?.name}?`}
        submitText="Yes, Block"
        submitColor="error"
      />
    </Box>
  );
};
