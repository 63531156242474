import { FC } from 'react';
import { Box, Typography, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

interface AccountSettingsHeaderProps {
  toggleEdit: () => void;
  isEditing: boolean;
  onSave: () => void;
  saveButtonDisabled: boolean;
}

const buttonStyles = { width: '180px' };

export const AccountSettingsHeader: FC<AccountSettingsHeaderProps> = ({
  isEditing,
  toggleEdit,
  onSave,
  saveButtonDisabled,
}) => (
  <Box mb="16px" display="flex" justifyContent="space-between">
    <Typography variant="h4">Account settings</Typography>

    {isEditing ? (
      <Box display="flex">
        <Button style={buttonStyles} onClick={toggleEdit} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={onSave}
          style={{ ...buttonStyles, marginLeft: '16px' }}
          variant="contained"
          disabled={saveButtonDisabled}
        >
          Save
        </Button>
      </Box>
    ) : (
      <Button
        style={buttonStyles}
        onClick={toggleEdit}
        startIcon={<EditIcon />}
        variant="contained"
      >
        Edit
      </Button>
    )}
  </Box>
);
