import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';
import { Link, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import styled from 'styled-components';
import { DATE_FORMAT } from '@constants';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export const getColumns = (): GridColDef[] => [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 400,
    renderCell: ({ value, row: { url } }) => (
      <CustomWidthTooltip placement="top-start" title={value}>
        <Link className="ellipsis" href={url} target="_blank">
          {value}
        </Link>
      </CustomWidthTooltip>
    ),
  },
  {
    field: 'updatedAt',
    headerName: 'Update date',
    flex: 2,
    minWidth: 170,
    renderCell: ({ row: { updatedAt } }) => dayjs(updatedAt).format(DATE_FORMAT),
  },
  {
    field: 'createdAt',
    headerName: 'Date of addition',
    flex: 2,
    minWidth: 170,
    renderCell: ({ row: { createdAt } }) => dayjs(createdAt).format(DATE_FORMAT),
  },
];
