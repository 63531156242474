import { FilterItem } from '@services';
import { renderDateFilter } from './renderDateFilter';

export const lastModifiedFilter: FilterItem = {
  name: 'Last modified',
  key: 'lastModified',
  resetFields: ['updatedStartDate', 'updatedEndDate'],
  getFilter: renderDateFilter({
    key: 'lastModified',
    resetFields: ['updatedStartDate', 'updatedEndDate'],
  }),
};
