import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';

import { TableBusinessAccount } from '@services';
import { getStatus, removedCellClassName } from '@helpers';
import { DATE_FORMAT } from '@constants';

import { Chip } from '@mui/material';
import { InfoCell, UserPaymentStatus } from '@atoms';
import { renderPlanCell, renderManagerCell, renderSlotCell } from '@molecules/Table/cells';

export const columns: GridColDef<TableBusinessAccount>[] = [
  {
    field: 'companyName',
    headerName: 'Company',
    minWidth: 230,
    flex: 2,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { companyName, email, logo } }) => (
      <InfoCell name={companyName} email={email} avatarUrl={logo} />
    ),
  },
  {
    field: 'plan',
    headerName: 'Plan',
    minWidth: 116,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: renderPlanCell,
  },
  {
    field: 'slots',
    headerName: 'N of slots',
    minWidth: 110,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: renderSlotCell,
  },
  {
    field: 'status',
    headerName: 'Payment status',
    minWidth: 151,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { status, plan, isRemoved, freeTrialEffectiveDate } }) => {
      const isFreeTrial = freeTrialEffectiveDate
        ? dayjs(freeTrialEffectiveDate).isAfter(dayjs())
        : false;
      const paymentStatus = getStatus(status, plan, isRemoved, isFreeTrial);
      return <UserPaymentStatus status={paymentStatus} />;
    },
  },
  {
    field: 'joinDate',
    headerName: 'Join date',
    minWidth: 110,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { joinDate } }) => dayjs(joinDate).format(DATE_FORMAT),
  },
  {
    field: 'manager',
    headerName: 'Manager',
    minWidth: 150,
    flex: 2,
    cellClassName: removedCellClassName,
    renderCell: renderManagerCell(),
  },
  {
    field: 'assignStatus',
    headerName: 'Status',
    minWidth: 111,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { manager } }) => (
      <Chip label={manager ? 'Assigned' : 'Unassigned'} color={manager ? 'success' : 'error'} />
    ),
  },
];
