import styled from 'styled-components';
import { Link as LinkStyle } from 'react-router-dom';

export const Link = styled(LinkStyle)`
  cursor: 'pointer';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.palette.primary.main};
  text-decoration: none;
`;
