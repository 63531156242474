import { FC, useState } from 'react';
import cn from 'classnames';
import { useMutation, useQuery } from 'react-query';
import { successMessage, User } from '@services';
import { Axios } from '@helpers';
import { Avatar, Box, InputLabel, SelectChangeEvent, SelectProps, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';

import { SelectStyled, MenuItemStyled } from '../Filters/styles';

interface Props extends SelectProps {
  value?: string;
  memberIds: string[];
  refetch: () => void;
}

export const AssignMemberSelect: FC<Props> = ({
  memberIds,
  refetch,
  value: defaultValue,
  className,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);
  const { data } = useQuery<unknown, unknown, User[]>('/admin/users/managers', () =>
    Axios.get('/admin/users?role=manager&activeStatus=active').then((res) => res.data.items || []),
  );

  const managers = (data || []).map(({ id, avatarUrl, firstName, lastName }) => ({
    id,
    avatarUrl,
    fullName: `${firstName || ''} ${lastName || ''}`,
  }));

  const { mutate: onSubmit, isLoading } = useMutation<
    unknown,
    unknown,
    { managerId: string; companyIds: string[] }
  >(({ managerId, companyIds }) => Axios.put(`/admin/users/${managerId}/assign`, { companyIds }), {
    onSuccess: () => {
      successMessage('Manager has been assign!');
      refetch();
    },
  });

  const handleOnChange = (event: SelectChangeEvent<unknown>) => {
    event.stopPropagation();
    setValue(event.target.value as string);
  };

  const handleOnSubmit = () => {
    onSubmit({
      managerId: value as string,
      companyIds: memberIds,
    });
  };

  return (
    <Box display="flex" alignItems="center">
      <InputLabel sx={{ marginBottom: '0', marginRight: '8px' }}>Assign to: </InputLabel>
      <SelectStyled
        placeholder="Select option"
        sx={{ width: '220px', height: '40px' }}
        value={value}
        onChange={handleOnChange}
        className={cn(className, 'small')}
        {...props}
      >
        {managers.map(({ id, avatarUrl, fullName }) => (
          <MenuItemStyled key={id} value={id}>
            <Avatar
              alt={fullName}
              sx={{ width: 32, height: 32, marginRight: '16px' }}
              src={avatarUrl}
            >
              {fullName[0].toUpperCase()}
            </Avatar>
            <Typography variant="body2" className="ellipsis" sx={{ marginRight: '20px' }}>
              {fullName}
            </Typography>
          </MenuItemStyled>
        ))}
      </SelectStyled>
      <LoadingButton
        startIcon={<CheckIcon />}
        variant="text"
        color="primary"
        sx={{ width: '80px', padding: '10px 4px', marginLeft: '32px' }}
        onClick={handleOnSubmit}
        loading={isLoading}
        disabled={!managers.some(({ id }) => id === value)}
      >
        Save
      </LoadingButton>
    </Box>
  );
};
