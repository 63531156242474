import { Box, styled } from '@mui/material';

export const StyledContainer = styled(Box)`
  display: flex;
  padding: 13px 12px;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  min-height: 56px;
  color: rgba(0, 0, 0, 0.54);
  gap: 8px;

  svg path {
    fill: rgba(0, 0, 0, 0.54);
  }
`;
