import { FC } from 'react';
import { SelectProps } from '@mui/material';
import { OptionType } from '@services';

import { FilterCloseWrap } from './FilterCloseWrap';
import { SelectStyled, MenuItemStyled } from './styles';

interface Props extends SelectProps {
  items: OptionType[];
  name: string;
  onClose?: () => void;
}

export const DefaultFilter: FC<Props> = ({ items, name, onClose, ...props }) => (
  <FilterCloseWrap label={name} onClose={onClose}>
    <SelectStyled placeholder="Select option" {...props}>
      {items.map(({ value, label, icon }) => (
        <MenuItemStyled key={value} value={value}>
          {icon && icon}
          {label}
        </MenuItemStyled>
      ))}
    </SelectStyled>
  </FilterCloseWrap>
);
