import { GridColDef, GridRowModel } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { WhitelistType } from '@services';
import { MEMBER_ROLE_LABEL } from '@constants';

import { InfoCell } from '@atoms';
import { renderDateCell, renderTextCell } from '@molecules/Table/cells';
import { IconButton, Box } from '@mui/material';

interface Props {
  onEdit: (row: GridRowModel) => void;
  onDelete: (row: GridRowModel) => void;
}

export const getColumns = ({ onEdit, onDelete }: Props): GridColDef<WhitelistType>[] => [
  {
    field: 'createdAt',
    headerName: 'Date',
    minWidth: 140,
    renderCell: renderDateCell,
  },
  {
    field: 'domainRegex',
    headerName: 'Website',
    minWidth: 220,
    renderCell: renderTextCell,
  },
  {
    field: 'uploadedBy',
    headerName: 'Uploaded by',
    minWidth: 220,
    flex: 1,
    renderCell: ({
      row: {
        uploadedBy: { firstName, lastName, role, photoUrl },
      },
    }) => (
      <InfoCell
        name={`${firstName || ''} ${lastName || ''}`}
        role={MEMBER_ROLE_LABEL[role]}
        avatarUrl={photoUrl || ''}
      />
    ),
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 220,
    flex: 1,
    renderCell: renderTextCell,
  },
  {
    field: 'action',
    headerName: 'Actions',
    align: 'center',
    headerAlign: 'center',
    minWidth: 110,
    sortable: false,
    renderCell: ({ row }) => (
      <Box>
        <IconButton onClick={() => onEdit(row)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => onDelete(row)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    ),
  },
];
