import { ReactNode } from 'react';
import { FilterItem, MemberRole } from '@services';
import { SelectChangeEvent } from '@mui/material';
import { DefaultFilter } from '@molecules';
import { MEMBER_ROLE_OPTIONS } from '@constants';

const KEY = 'role';

export const roleFilter: FilterItem = {
  name: 'Role',
  key: KEY,
  getFilter: (onChange, onClose, setFilters, filter): ReactNode => (
    <DefaultFilter
      key={KEY}
      name="Role"
      value={filter[KEY] as MemberRole}
      items={MEMBER_ROLE_OPTIONS}
      onChange={(event: SelectChangeEvent<unknown>) =>
        setFilters({ ...filter, [KEY]: event.target.value as string })
      }
      onClose={onClose}
    />
  ),
};
