import styled from 'styled-components';

export const Input = styled.input`
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  width: 100%;
  font-weight: ${(props) => props.theme.typography.body1.fontWeight};
  font-size: ${(props) => props.theme.typography.body1.fontSize};
  color: ${(props) => props.theme.palette.text.primary};
  text-indent: 44px;
  outline: none;

  &:hover {
    border-color: ${(props) => props.theme.palette.text.disabled};
  }

  &:focus,
  &:active {
    border-color: ${(props) => props.theme.palette.primary.main};
  }

  &::placeholder {
    font-weight: ${(props) => props.theme.typography.body1.fontWeight};
    font-size: ${(props) => props.theme.typography.body1.fontSize};
    color: ${(props) => props.theme.palette.text.disabled};
  }
`;
