import styled from 'styled-components';
import { Box } from '@mui/material';

export const InputSelectWrap = styled(Box)`
  position: relative;

  .MuiInputBase-input {
    padding-right: 155px;
  }
`;

export const SelectWrap = styled(Box)`
  position: absolute;
  right: 0;
  top: 27px;
  display: flex;
  align-items: center;
  width: 150px;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiSelect-select {
    color: ${(props) => props.theme.palette.text.primary};
  }

  &:before {
    content: '';
    position: absolute;
    height: 24px;
    width: 1px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.23);
  }
`;
