import { FC } from 'react';
import { ManagedAccount } from '@services';
import { Box, Typography } from '@mui/material';
import { Table } from '@molecules';
import { columns } from './utils';

interface Props {
  id: string;
  memberId: string;
}

export const ManagedAccountsTable: FC<Props> = ({ id, memberId }) => (
  <Box>
    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
      <Box>
        <Typography variant="h5" mt="24px">
          Managed accounts
        </Typography>
        <Typography variant="body2" mt="8px" mb="16px">
          Here you can find all content creator accounts managed by the manager.
        </Typography>
      </Box>
      {/* <Button variant="contained" color="primary"> */}
      {/*   Assign model */}
      {/* </Button> */}
    </Box>
    <Table<ManagedAccount>
      queryName={`${id}-managed-assets-${memberId}`}
      apiRoute={`/admin/accounts/business/${id}/member/${memberId}/managed-assets`}
      columns={columns}
      withoutSearch
    />
  </Box>
);
