import React, { SyntheticEvent } from 'react';

import { Box, BoxProps, IconButton, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';

import { StyledAvatar, StyledLabel } from './styles';

interface Props extends BoxProps {
  src?: string | ArrayBuffer;
  onUpload: (previewImg: string | ArrayBuffer, fromData: FormData) => void;
  isLoading?: boolean;
  withoutAdditionalInfo?: boolean;
  disabled?: boolean;
}

export const UploadAvatar: React.FC<Props> = ({
  src,
  onUpload,
  withoutAdditionalInfo,
  disabled,
  ...props
}) => {
  const handleOnUpload = (e: SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.files?.length) {
      const fileReader = new FileReader();
      const formData = new FormData();
      const file = e.currentTarget.files[0];
      formData.append('file', file);
      fileReader.readAsDataURL(file);
      fileReader.onload = (event: ProgressEvent<FileReader>) => {
        if (event?.target?.result) {
          const previewImg = event.target?.result;
          onUpload(previewImg, formData);
        }
      };
    }
  };

  const handleDelete = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const formData = new FormData();
    onUpload('', formData);
  };

  return (
    <Box {...props}>
      <Box sx={{ position: 'relative', m: '0 auto' }} width="fit-content">
        <StyledAvatar src={src as string}> </StyledAvatar>
        {!disabled && (
          <StyledLabel htmlFor="icon-button-file">
            {src ? (
              <IconButton onClick={handleDelete}>
                <Delete />
              </IconButton>
            ) : (
              <>
                <input
                  style={{ display: 'none' }}
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onChange={handleOnUpload}
                />
                <StyledAvatar sx={{ cursor: 'pointer', fontSize: 14 }}>
                  Click to upload
                </StyledAvatar>
              </>
            )}
          </StyledLabel>
        )}
      </Box>
      {!withoutAdditionalInfo && (
        <Typography className="mt-4">Maximum size: 1152 x 720, png or jpeg</Typography>
      )}
    </Box>
  );
};
