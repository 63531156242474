import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { UserTable } from '@organisms';
import { InnerView } from '@templates';

import { UsersHeader } from './UsersHeader';

export const Users: FC = () => (
  <>
    <UsersHeader />
    <InnerView>
      <UserTable />
      <Outlet />
    </InnerView>
  </>
);
