/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import type { TextFieldProps } from '@mui/material/TextField';

import type {
  MuiChipsInputChip,
  MuiChipsInputChipComponent,
  MuiChipsInputChipProps,
  MuiChipsInputProps,
} from './types';

import { TextFieldStyled, EndAdornmentClose, ChipsContainer } from './styled';
import { assocRefToPropRef, matchIsBoolean } from './utils';
import { EditableChip as Chip } from './EditableChip';

export const KEYBOARD_KEY = {
  enter: 'Enter',
  backspace: 'Backspace',
};

type TextFieldChipsProps = TextFieldProps & {
  chips: MuiChipsInputChip[];
  nonRemovable?: MuiChipsInputChip[];
  onAddChip?: (chip: MuiChipsInputChip) => void;
  onEditChip?: (chip: MuiChipsInputChip, chipIndex: number) => void;
  clearInputOnBlur?: boolean;
  hideClearAll?: boolean;
  disableDeleteOnBackspace?: boolean;
  addOnWhichKey?: string | string[];
  disableEdition?: boolean;
  inputValue?: string;
  errorIds?: number[];
  validate?: MuiChipsInputProps['validate'];
  onInputChange?: (inputValue: string) => void;
  onDeleteChip?: (chipIndex: number) => void;
  onDeleteAllChips?: () => void;
  renderChip?: (
    ChipComponent: MuiChipsInputChipComponent,
    ChipProps: MuiChipsInputChipProps,
  ) => Element;
};

export const TextFieldChips = React.forwardRef(
  (props: TextFieldChipsProps, propRef: TextFieldChipsProps['ref']): React.ReactElement => {
    const {
      chips,
      nonRemovable,
      onAddChip,
      onEditChip,
      onDeleteChip,
      onDeleteAllChips,
      InputProps,
      onInputChange,
      disabled,
      clearInputOnBlur,
      validate,
      error,
      helperText,
      hideClearAll,
      inputProps,
      size,
      disableDeleteOnBackspace,
      disableEdition,
      className,
      renderChip,
      addOnWhichKey,
      onFocus,
      inputValue: inputValueControlled,
      errorIds,
      ...restTextFieldProps
    } = props;
    const [inputValueUncontrolled, setInputValueUncontrolled] = React.useState<string>('');
    const [textError, setTextError] = React.useState<string>('');
    const inputElRef = React.useRef<HTMLDivElement | null>(null);
    const isFocusingRef = React.useRef<boolean>(false);
    const isControlledRef = React.useRef(typeof inputValueControlled === 'string');
    const [chipIndexEditable, setChipIndexEditable] = React.useState<null | number>(null);
    const { onKeyDown, ...restInputProps } = inputProps || {};
    const { ...restIInputProps } = InputProps || {};

    const clearTextError = () => {
      setTextError('');
    };

    const isControlled = isControlledRef.current;
    const inputValue = isControlled ? (inputValueControlled as string) : inputValueUncontrolled;

    const updateInputValue = (newInputValue: string) => {
      onInputChange?.(newInputValue);
      if (!isControlled) {
        setInputValueUncontrolled(newInputValue);
      }
    };

    const updateChipIndexEditable = (chipIndex: number) => {
      updateInputValue(chips[chipIndex]);
      setChipIndexEditable(chipIndex);
      clearTextError();
    };

    const clearChipIndexEditable = () => {
      setChipIndexEditable(null);
    };

    const clearInputValue = () => {
      clearTextError();
      updateInputValue('');
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      updateInputValue(event.target.value);
    };

    const handleClickAway = () => {
      if (!isFocusingRef.current) {
        return;
      }
      if (chipIndexEditable !== null) {
        clearChipIndexEditable();
        clearInputValue();
      } else if (clearInputOnBlur) {
        clearInputValue();
      }

      isFocusingRef.current = false;
    };

    const handleRef = (ref: HTMLDivElement | null): void => {
      inputElRef.current = ref;
      if (propRef) {
        assocRefToPropRef(ref, propRef);
      }
    };

    const validationGuard =
      (chipValue: MuiChipsInputChip, event: React.KeyboardEvent<HTMLInputElement>) =>
      (callback: () => void) => {
        if (typeof validate === 'function') {
          const validation = validate(chipValue);
          if (validation === false) {
            event.preventDefault();
            return;
          }
          if (!matchIsBoolean(validation) && validation.isError) {
            event.preventDefault();
            setTextError(validation.textError);
            return;
          }
        }
        callback();
      };

    const updateChip = (
      chipValue: MuiChipsInputChip,
      chipIndex: number,
      event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
      validationGuard(
        chipValue,
        event,
      )(() => {
        onEditChip?.(chipValue, chipIndex);
        clearChipIndexEditable();
        clearInputValue();
      });
    };

    const addChip = (
      chipValue: MuiChipsInputChip,
      event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
      validationGuard(
        chipValue,
        event,
      )(() => {
        onAddChip?.(inputValue.trim());
        clearInputValue();
      });
    };

    const matchIsValidKeyToAdd = (eventKey: string) => {
      if (addOnWhichKey) {
        if (Array.isArray(addOnWhichKey)) {
          return addOnWhichKey.some((key) => key === eventKey);
        }
        return addOnWhichKey === eventKey;
      }

      return eventKey === KEYBOARD_KEY.enter;
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      const isKeyIsAdd = matchIsValidKeyToAdd(event.key);
      const isBackspace = event.key === KEYBOARD_KEY.backspace;
      const inputValueTrimed = inputValue.trim();

      if (isKeyIsAdd) {
        event.preventDefault();
      }

      if (inputValue.length > 0 && isKeyIsAdd) {
        if (inputValueTrimed.length === 0) {
          clearInputValue();
        } else if (chipIndexEditable !== null) {
          updateChip(inputValueTrimed, chipIndexEditable, event);
        } else {
          addChip(inputValueTrimed, event);
        }
      } else if (
        isBackspace &&
        inputValue.length === 0 &&
        chips.length > 0 &&
        !disableDeleteOnBackspace
      ) {
        const chipIndex = chips.length - 1;
        onDeleteChip?.(chipIndex);
        if (chipIndexEditable === chipIndex) {
          clearChipIndexEditable();
        }
      }

      onKeyDown?.(event);
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      event.preventDefault();
      onFocus?.(event);
      isFocusingRef.current = true;
    };

    const handleClearAll = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (!hideClearAll && !disabled) {
        onDeleteAllChips?.();
        clearInputValue();
        clearChipIndexEditable();
      }
    };

    const handleEdit = (chipIndex: number) => {
      if (chipIndex === chipIndexEditable) {
        clearInputValue();
        clearChipIndexEditable();
      } else {
        updateChipIndexEditable(chipIndex);
      }

      inputElRef.current?.focus();
    };

    const handleDeleteChip = (chipIndex: number) => {
      if (disabled) {
        return;
      }
      onDeleteChip?.(chipIndex);
      if (chipIndexEditable !== null) {
        clearChipIndexEditable();
        clearInputValue();
      }
    };

    const hasAtLeastOneChip = chips.length > 0 || (nonRemovable || []).length > 0;

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <TextFieldStyled
          value={inputValue}
          onChange={handleChange}
          ref={propRef}
          className={`MuiChipsInput-TextField ${className || ''}`}
          size={size}
          placeholder="Type and press enter"
          onFocus={handleFocus}
          inputProps={{
            onKeyDown: handleKeyDown,
            ...restInputProps,
          }}
          disabled={disabled}
          error={Boolean(textError) || error}
          helperText={textError || helperText}
          InputProps={{
            inputRef: handleRef,
            startAdornment: hasAtLeastOneChip ? (
              <ChipsContainer>
                <>
                  {(nonRemovable || []).map((chip, index) => {
                    const ChipProps: MuiChipsInputChipProps = {
                      key: `chip-${index}`,
                      variant: 'outlined',
                      index,
                      onEdit: handleEdit,
                      label: chip,
                      title: chip,
                      isEditing: index === chipIndexEditable,
                      size,
                      disabled: true,
                      disableEdition,
                      isError: errorIds?.includes(index),
                      onDelete: handleDeleteChip,
                    };
                    return renderChip ? renderChip(Chip, ChipProps) : <Chip {...ChipProps} />;
                  })}
                </>
                <>
                  {chips.map((chip, index) => {
                    const ChipProps: MuiChipsInputChipProps = {
                      key: `chip-${index}`,
                      variant: 'outlined',
                      index,
                      onEdit: handleEdit,
                      label: chip,
                      title: chip,
                      isEditing: index === chipIndexEditable,
                      size,
                      disabled,
                      disableEdition,
                      isError: errorIds?.includes(index),
                      onDelete: handleDeleteChip,
                    };
                    return renderChip ? renderChip(Chip, ChipProps) : <Chip {...ChipProps} />;
                  })}
                </>
              </ChipsContainer>
            ) : null,
            endAdornment:
              !hideClearAll && !disabled ? (
                <EndAdornmentClose style={{ visibility: hasAtLeastOneChip ? 'visible' : 'hidden' }}>
                  <IconButton
                    aria-label="Clear"
                    title="Clear"
                    disabled={disabled}
                    size="small"
                    onClick={handleClearAll}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </EndAdornmentClose>
              ) : null,
            ...restIInputProps,
          }}
          {...restTextFieldProps}
        />
      </ClickAwayListener>
    );
  },
);

TextFieldChips.defaultProps = {
  onInputChange: () => ({}),
  clearInputOnBlur: false,
  hideClearAll: false,
  disableDeleteOnBackspace: false,
  disableEdition: false,
  addOnWhichKey: KEYBOARD_KEY.enter,
  onDeleteChip: () => ({}),
  onAddChip: () => ({}),
  inputValue: undefined,
  onEditChip: () => ({}),
  renderChip: undefined,
  onDeleteAllChips: () => ({}),
  validate: () => true,
  errorIds: [],
  nonRemovable: [],
};
