import { FC } from 'react';
import { SocialMediaType } from '@services';
import { Box, BoxProps, InputLabel, Chip, Tooltip } from '@mui/material';

import { StyledContainer } from './styles';

interface Props extends BoxProps {
  label: string;
  chips: SocialMediaType[];
}

export const InputWithChips: FC<Props> = ({ label, chips, ...props }) => (
  <Box width="100%" {...props}>
    {label && <InputLabel>{label}</InputLabel>}
    <StyledContainer>
      {chips.length
        ? chips.map(({ fullLink, nickname }) => (
            <Tooltip key={fullLink} title={fullLink} arrow>
              <Chip label={nickname} variant="outlined" sx={{ opacity: '0.6' }} />
            </Tooltip>
          ))
        : 'No usernames'}
    </StyledContainer>
  </Box>
);
