import { FC } from 'react';
import { FormControlLabelProps, FormControlLabel } from '@mui/material';

import { FilterCloseWrap } from './FilterCloseWrap';

type SearchOptionsProps = {
  onClose?: () => void;
} & FormControlLabelProps;
export const SearchOptionsCheckbox: FC<SearchOptionsProps> = ({ onClose, ...props }) => (
  <FilterCloseWrap label="Search options" width="300px" onClose={onClose}>
    <FormControlLabel {...props} className="gap-2" />
  </FilterCloseWrap>
);
