import { FC, useState, useEffect, useCallback } from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import debounce from 'lodash.debounce';

import { SearchIcon } from '@atoms/Icons';

import { Input } from './styles';

interface TableSearchProps {
  defaultSearch?: string;
  placeholder?: string;
  onSearch: (search: string) => void;
}

export const TableSearch: FC<TableSearchProps> = ({
  defaultSearch = '',
  placeholder = 'Search for User name and Email',
  onSearch,
}) => {
  const [search, setSearch] = useState(defaultSearch);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((_search) => onSearch(_search), 300),
    [],
  );

  useEffect(() => {
    debouncedSearch(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Box position="relative" display="flex" alignItems="center" width="100%">
      <Box position="absolute" height="24px" left="12px">
        <SearchIcon />
      </Box>
      <Input
        id="table-search-input"
        value={search}
        onChange={({ target: { value } }) => setSearch(value)}
        placeholder={placeholder}
      />
      {search && (
        <IconButton
          sx={{ position: 'absolute', right: '12px', cursor: 'pointer' }}
          onClick={() => setSearch('')}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
};
