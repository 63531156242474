import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FilterItem, TableBusinessAccount, TableSelectType } from '@services';
import { getSelectedAccount } from '@helpers';
import { ROUTES } from '@constants';
import { Table } from '@molecules';
import {
  assignStatusFilter,
  paymentStatusFilter,
  planFilter,
  renderDateFilter,
} from '@molecules/Table/filters';
import { InnerView } from '@templates';

import { columns } from './utils';

const FILTERS_LIST: FilterItem[] = [
  planFilter(true),
  paymentStatusFilter,
  {
    name: 'Join date',
    key: 'joinDate',
    resetFields: ['joinedStartDate', 'joinedEndDate'],
    getFilter: renderDateFilter({
      key: 'joinDate',
      label: 'Join date',
      resetFields: ['joinedStartDate', 'joinedEndDate'],
    }),
  },
  assignStatusFilter,
];

export const NewBusinessAccountsTable: FC = () => {
  const navigate = useNavigate();
  const preSelectedFilters = useMemo(() => ({ newClients: 'true' }), []);

  return (
    <InnerView autoHeight>
      <Table<TableBusinessAccount>
        searchPlaceholder="Search for Company name or Email"
        queryName="new-business-accounts-table"
        columns={columns}
        apiRoute="/admin/accounts/business"
        filtersList={FILTERS_LIST}
        preSelectedFilters={preSelectedFilters}
        getSelectedValue={getSelectedAccount<TableBusinessAccount>}
        onRowClick={({ id, isRemoved }) => {
          if (id && !isRemoved) {
            sessionStorage.setItem(
              'business-accounts-table-back-to',
              window.location.pathname || '',
            );
            sessionStorage.setItem('business-accounts-table', window.location.search || '');
            navigate(`/${ROUTES.BusinessAccounts}/${id}/${ROUTES.AccountDetails}`);
          }
        }}
        selectedRowType={TableSelectType.Account}
        checkboxSelection
      />
    </InnerView>
  );
};
