import { Axios } from '@helpers';
import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { NotificationMessage } from '@atoms';
import { useIntersectionObserver } from '@hooks/useIntersectionObserver';
import { TableSearch } from '@molecules';
import { Box, Fade, LinearProgress, Typography } from '@mui/material';
import { UserActivityResponseType, UserActivityType } from '@services';
import { InnerView } from '@templates';
import { useQuery } from 'react-query';

const PAGE_LENGTH = 10;

interface Props {
  title: string;
  isModel?: boolean;
}

export const NotificationPage: FC<Props> = ({ title, isModel }) => {
  const { id, assetId } = useParams();
  const [page, setPage] = useState(0);
  const modelId = isModel ? assetId : undefined;
  const [renderData, setRenderData] = useState<UserActivityType[]>([]);
  const [filterText, setFilterText] = useState('');

  const { data, isLoading } = useQuery<void, void, UserActivityResponseType>(
    [page, filterText],
    () =>
      Axios.get('admin/company-activities/', {
        params: { size: PAGE_LENGTH, page, companyId: id, filterText, modelId },
      }).then((res) => res.data),
  );

  const hasMessages = Boolean(renderData?.length);
  const hasMore = Boolean((page + 1) * PAGE_LENGTH < (data?.totalCount || 0));

  useEffect(() => {
    setRenderData([]);
    setPage(0);
  }, [filterText]);

  const loadMore = useCallback(() => {
    if (!hasMore || isLoading) return;

    setPage((prev) => prev + 1);
  }, [hasMore, isLoading]);

  const { ref } = useIntersectionObserver({ loadMore });

  useEffect(() => {
    const result = data?.items || [];
    setRenderData((prevState) => [...prevState, ...result]);
  }, [data?.items]);

  return (
    <>
      <Box>
        <Typography variant="h5" mb="8px">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" mb="16px">
          Here is all recent activities of this account.
        </Typography>
      </Box>
      <TableSearch
        placeholder="Search activities"
        defaultSearch={filterText}
        onSearch={(text) => setFilterText(text)}
      />
      <Box mt="20px">
        <InnerView
          id="notificationPageInner"
          autoHeight
          mt="32px"
          sx={{
            paddingTop: '0',
            paddingLeft: '0',
          }}
        >
          <Fade in={isLoading}>
            <LinearProgress sx={{ height: 4 }} />
          </Fade>

          <Box>
            {hasMessages ? (
              renderData?.map((item) => (
                <NotificationMessage
                  sx={{
                    borderRadius: '8px',
                  }}
                  key={item.id}
                  {...item}
                />
              ))
            ) : (
              <Typography variant="subtitle1" sx={{ margin: '0 auto 0 auto' }} pt={3} pl={6}>
                This account has no activity yet
              </Typography>
            )}
            <Box ref={ref} />
          </Box>
        </InnerView>
      </Box>
      <Outlet />
    </>
  );
};
