import { ReactElement, useState } from 'react';
import { Dayjs } from 'dayjs';
import get from 'lodash.get';
import { Controller, Control } from 'react-hook-form';
import {
  LocalizationProvider,
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Box, TextField as MuiTextField, InputLabel, Button } from '@mui/material';
import { SxProps } from '@mui/system';
import { TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField/TextField';
import { ButtonProps } from '@mui/material/Button/Button';
import DateRangeIcon from '@mui/icons-material/DateRange';

export interface DatePickerProps extends Omit<MuiDatePickerProps<Dayjs, Dayjs>, 'renderInput'> {
  label?: string;
  placeholder?: string;
  renderInput?: (props: MuiTextFieldPropsType) => ReactElement;
}

export const DatePicker = ({ label, placeholder, ...props }: DatePickerProps) => (
  <Box width="100%">
    {label && <InputLabel>{label}</InputLabel>}
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDatePicker
        renderInput={(rawParams) => {
          const params = { ...rawParams, inputProps: { ...rawParams.inputProps, placeholder } };
          return <MuiTextField {...params} helperText={null} />;
        }}
        {...props}
      />
    </LocalizationProvider>
  </Box>
);

export interface ButtonDatePickerProps
  extends Omit<DatePickerProps, 'InputProps' | 'InputAdornmentProps'> {
  buttonProps?: ButtonProps;
}

export const ButtonDatePicker = ({ placeholder, buttonProps, ...props }: ButtonDatePickerProps) => {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderInput={(params) => (
        <Button
          variant="outlined"
          color="black"
          size="small"
          sx={{
            width: '189px',
            padding: '12px',
          }}
          ref={params.inputRef}
          disabled={params.disabled}
          endIcon={<DateRangeIcon />}
          onClick={() => setOpen(true)}
          {...buttonProps}
        >
          {(params.inputProps as { value?: string })?.value || placeholder}
        </Button>
      )}
      {...props}
    />
  );
};

interface ControlledDatePickerProps extends Omit<DatePickerProps, 'onChange' | 'value'> {
  control: Control<any, object>;
  name: string;
  sx?: SxProps;
  required?: boolean;
}

export const ControlledDatePicker = ({
  name,
  label,
  placeholder,
  control,
  sx,
  required,
  ...props
}: ControlledDatePickerProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field, formState: { errors } }) => {
      const errorText = (get(errors, name) as any)?.message;

      return (
        <Box width="100%" sx={sx}>
          <InputLabel>
            {label}
            {required && <span>&nbsp;*</span>}
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MuiDatePicker
              {...field}
              {...props}
              renderInput={(rawParams) => {
                const params = {
                  ...rawParams,
                  inputProps: {
                    ...rawParams.inputProps,
                    placeholder,
                  },
                };
                return <MuiTextField error={!!errorText} {...params} helperText={errorText} />;
              }}
            />
          </LocalizationProvider>
        </Box>
      );
    }}
  />
);
