import { Box } from '@mui/system';
import styled from 'styled-components';

export const ScanSettingImage = styled.div`
  position: relative;
  img {
    width: 100%;
    height: 150px;
    object-fit: contain;
    border: 1px dashed rgba(0, 0, 0, 0.23);
    border-radius: 4px;
  }
  .MuiButtonBase-root {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
  }
  &:hover {
    .MuiButtonBase-root {
      display: block;
    }
  }
`;

export const ScanSettingImageBtn = styled(Box)`
  height: 150px;
  position: relative;
  border: 1px dashed rgba(0, 0, 0, 0.23);
  display: grid;
  place-content: center;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    border-color: rgb(68, 174, 251);
    .MuiSvgIcon-root {
      color: rgb(68, 174, 251);
    }
  }
`;

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  overflow: 'hidden',
  position: 'absolute',
  inset: 0,
  whiteSpace: 'nowrap',
});
