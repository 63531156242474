import styled from 'styled-components';

export const HeaderWrap = styled.header`
  height: 88px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;

  svg {
    fill: ${(props) => props.theme.palette.text.secondary};
  }
`;
