import { Controller, Control, Path, FieldValues } from 'react-hook-form';
import get from 'lodash.get';
import { OptionType } from '@services';
import {
  MenuItem,
  SelectProps,
  Box,
  InputLabel,
  Typography,
  Checkbox,
  Chip,
  FormHelperText,
} from '@mui/material';
import { StyledMultiselect } from './styles';

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props<T extends FieldValues> extends SelectProps {
  control: Control<T, object>;
  name: Path<T>;
  label: string;
  options: OptionType[];
}

export const Multiselect = <T extends object>({
  control,
  name,
  label,
  disabled,
  options,
  placeholder,
  required,
  ...props
}: Props<T>) => {
  const renderHeader = (value: unknown) => (
    <Box display="flex" alignItems="center" gap="8px">
      {(value as string[]).map((item) => (
        <Chip
          key={item}
          label={options.find((op) => op.value === item)?.label}
          variant="outlined"
          disabled={disabled}
        />
      ))}
      {(value as string[]).length ? null : (
        <Typography color="text.disabled">{placeholder}</Typography>
      )}
    </Box>
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState: { errors } }) => {
        const errorText = (get(errors, name) as any)?.message;

        return (
          <Box width="100%" sx={{ pointerEvents: disabled ? 'none' : '' }}>
            <InputLabel htmlFor={name}>
              {label}
              {required && <span>&nbsp;*</span>}
            </InputLabel>
            <StyledMultiselect
              {...field}
              disabled={disabled}
              displayEmpty
              MenuProps={MenuProps}
              renderValue={renderHeader}
              multiple
              error={!!errorText}
              {...props}
            >
              {options.map(({ value, label: itemLabel, icon }) => (
                <MenuItem value={value} key={value}>
                  <Checkbox checked={(field.value as string[]).indexOf(value) > -1} />
                  {icon && icon}
                  {itemLabel}
                </MenuItem>
              ))}
            </StyledMultiselect>
            {errorText && <FormHelperText error>{errorText}</FormHelperText>}
          </Box>
        );
      }}
    />
  );
};
