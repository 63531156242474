import { FC, ReactNode, SyntheticEvent, useMemo } from 'react';
import {
  FilterItem,
  LinkAtSourceInformationAiMatches,
  SourceType,
  TableSelectType,
} from '@services';
import { Box, Checkbox, SelectChangeEvent } from '@mui/material';
import {
  LinkSourceTypeFilter,
  SearchOptionsCheckbox,
  CollapseTable,
  AIMatchesFilter,
} from '@molecules';
import { useParams } from 'react-router-dom';
import groupBy from 'lodash.groupby';
import { columns, columnsWithTags, isStatusRaw } from './utils';

interface WorkingModeTableProps {
  status: string;
}

const FILTERS_LIST: FilterItem[] = [
  {
    name: 'Source type',
    key: 'sourceType',
    getFilter: (onChange, onClose, setFilters, filter): ReactNode => (
      <LinkSourceTypeFilter
        key="sourceType"
        name="sourceType"
        value={filter.sourceType as SourceType}
        onChange={(event: SelectChangeEvent<unknown>) =>
          setFilters({ ...filter, sourceType: event.target.value as string })
        }
        onClose={onClose}
      />
    ),
  },
  {
    name: 'Search filter',
    key: 'searchFilter',
    getFilter: (onChange, onClose, setFilters, filter): ReactNode => (
      <SearchOptionsCheckbox
        key="isOnlyOneSpaceSearch"
        name="isOnlyOneSpaceSearch"
        control={<Checkbox inputProps={{ 'aria-label': 'controlled' }} />}
        label="Search with one space"
        value={filter.isOnlyOneSpaceSearch}
        onChange={(event: SyntheticEvent<unknown>, checked: boolean) => {
          setFilters({ ...filter, isOnlyOneSpaceSearch: String(checked) });
        }}
        onClose={() => {
          onClose();
          setFilters({ ...filter, isOnlyOneSpaceSearch: String(false) });
        }}
      />
    ),
  },
  {
    name: 'AI Matches',
    key: 'aiMatches',
    getFilter: (onChange, onClose, setFilters, filter): ReactNode => (
      <AIMatchesFilter
        key="aiMatches"
        name="aiMatches"
        value={filter.aiMatches as LinkAtSourceInformationAiMatches}
        onChange={(event: SelectChangeEvent<unknown>) =>
          setFilters({ ...filter, aiMatches: event.target.value as string })
        }
        onClose={onClose}
      />
    ),
  },
];

export const WorkingModeTable: FC<WorkingModeTableProps> = ({ status }) => {
  const { assetId } = useParams();
  const preSelectedFilters = useMemo(
    () => ({ size: '250', status, sortBy: 'domain', sortDirection: 'asc' }),
    [status],
  );
  // eslint-disable-next-line
  const getSelectedLinks = <T extends { url: string }>(_items: T[], selectedItemsId: string[]) =>
    '';

  const transformData = (items?: any) => {
    let result: any = groupBy(items, (item) => item.domain);
    result = Object.entries(result).map(([domain, child], index: number) => ({
      label: domain,
      child,
      id: index,
    }));
    return result;
  };

  return (
    <Box>
      <CollapseTable<any>
        searchPlaceholder="Search for Link address"
        queryName="modal-working-mode"
        apiRoute={`/admin/models/${assetId}/links`}
        filtersList={FILTERS_LIST}
        columns={isStatusRaw() ? columnsWithTags : columns}
        preSelectedFilters={preSelectedFilters}
        getSelectedValue={getSelectedLinks}
        selectedRowType={TableSelectType.ChangeStatus}
        checkboxSelection
        renderRowPerPageOptions={false}
        transformData={transformData}
        defaultPageSize={250}
      />
    </Box>
  );
};
