import styled from 'styled-components';

export const CardWrap = styled.div`
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
