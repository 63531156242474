import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStateWithReset } from '@hooks';
import { linksValidation } from '@constants';
import { WhitelistCompanyType } from '@services';

import { splitLinks } from '@organisms/Modals/SubmitLinksModal/utils';
import { validateLink, FinalFormData } from './utils';

interface FormData {
  links: string;
}

interface Props {
  users: WhitelistCompanyType[];
  onSubmit: (formData: FinalFormData) => void;
}

export const useAddLinkStep = ({ users, onSubmit }: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const [errors, setErrors, resetErrors] = useStateWithReset<number[]>([], []);

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
  } = useForm<FormData>({
    resolver: yupResolver(linksValidation),
    mode: 'onChange',
    delayError: 700,
  });

  const currentLinks = watch('links');
  const isDirty = Boolean(currentLinks || file);

  const onUpload = (newFile: File | null) => {
    setFile(newFile);
  };

  useEffect(() => {
    resetErrors();
    const newLinks = splitLinks(currentLinks);
    newLinks.forEach(validateLink(setErrors));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLinks, setErrors]);

  const isDisabled = !isDirty || !isValid || !!errors.length;

  const finalSubmit = ({ links }: FormData) =>
    onSubmit({
      domains: splitLinks(links),
      companyIds: users.map(({ id }) => id),
      file,
    });

  return {
    onSubmit: handleSubmit(finalSubmit),
    control,
    isDisabled,
    onUpload,
    errors,
  };
};
