import { useMemo } from 'react';
import { Box, Button } from '@mui/material';
import { Control, FieldValues, Path } from 'react-hook-form';

import { Criteria, PasswordField } from '@atoms';
import { PASSWORD_VALIDATION_REGEXPS } from '@constants';

export interface PasswordFormProps<T extends FieldValues> {
  control: Control<T, object>;
  fieldName: Path<T>;
  password: string;
  buttonDisabled: boolean;
  buttonText: string;
}

export const PasswordForm = <T extends object>({
  control,
  password,
  buttonDisabled,
  buttonText,
  fieldName,
}: PasswordFormProps<T>) => {
  const validation = useMemo(() => {
    const length = password?.length >= 8;
    const cases = PASSWORD_VALIDATION_REGEXPS.cases.test(password);
    const number = PASSWORD_VALIDATION_REGEXPS.number.test(password);
    const specialSymbol = PASSWORD_VALIDATION_REGEXPS.specialSymbol.test(password);

    return {
      length,
      cases,
      number,
      specialSymbol,
    };
  }, [password]);

  return (
    <>
      <PasswordField
        placeholder="Create password"
        name={fieldName}
        label="Password"
        control={control}
      />
      <Box width="100%" mt="16px" mb="12px">
        <Criteria checked={validation.length} text="At least 8 characters " />
        <Criteria checked={validation.cases} text="Upper and lower case letters" />
        <Criteria checked={validation.number} text="One number" />
        <Criteria checked={validation.specialSymbol} text="One special symbol" />
      </Box>
      <Button
        fullWidth
        variant="contained"
        type="submit"
        disabled={!Object.values(validation).every(Boolean) || buttonDisabled}
      >
        {buttonText}
      </Button>
    </>
  );
};
