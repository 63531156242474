import Highlighter from 'react-highlight-words';
import { Tooltip, Link, Box, TooltipProps, tooltipClasses } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  filterText?: string;
  idList: string;
  value: string;
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export const RenderLinkCell: FC<Props> = ({ filterText = '', idList, value }) => (
  <CustomWidthTooltip
    placement="top-start"
    title={<Highlighter searchWords={[filterText]} textToHighlight={value} />}
  >
    <Box display="flex" gap="5px">
      <Link className="ellipsis" href={value} target="_blank">
        <Highlighter searchWords={[filterText]} textToHighlight={value} />
      </Link>
      {idList.length > 1 && <strong>{`x ${idList.length}`}</strong>}
    </Box>
  </CustomWidthTooltip>
);
