import { useState, useEffect } from 'react';

const useShiftKeyDetector = (): boolean => {
  const [isShiftPressed, setIsShiftPressed] = useState<boolean>(false);

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Shift') {
      setIsShiftPressed(true);
    }
  };

  const handleKeyUp = (event: KeyboardEvent): void => {
    if (event.key === 'Shift') {
      setIsShiftPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return isShiftPressed;
};

export default useShiftKeyDetector;
