import { FC } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DateRangeIcon from '@mui/icons-material/DateRange';

import { useRangeDatePicker } from '@hooks/useRangeDatePicker';

import { FilterCloseWrap } from './FilterCloseWrap';
import { DatePickerStyled, DatePickerWrap } from './styles';

export interface DateFilterProps {
  defaultStartDate?: Date | null;
  defaultEndDate?: Date | null;
  onChange: (date: [Date | null, Date | null]) => void;
  onClose: () => void;
  label?: string;
}

export const DateFilter: FC<DateFilterProps> = ({
  defaultStartDate,
  defaultEndDate,
  onChange,
  onClose,
  label = 'Last modified',
}) => {
  const {
    startDate,
    endDate,
    onChange: onChangeDatePicker,
  } = useRangeDatePicker({ defaultStartDate, defaultEndDate, onChange });

  return (
    <FilterCloseWrap width="315px" label={label} onClose={onClose}>
      <DatePickerWrap>
        <DateRangeIcon fontSize="small" />
        <DatePickerStyled
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeDatePicker}
          selectsRange
          calendarStartDay={1}
          monthsShown={1}
          placeholderText="DD.MM.YYYY - DD.MM.YYYY"
          dateFormat="dd.MM.yyyy"
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
      </DatePickerWrap>
    </FilterCloseWrap>
  );
};
