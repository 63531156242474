import { FC, ReactElement } from 'react';
import { BoxProps } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { Wrap } from './styles';

export interface LoaderProps extends BoxProps {
  isLoading: boolean;
  children?: ReactElement;
}

export const Loader: FC<LoaderProps> = ({ children, isLoading, ...rest }) => {
  if (isLoading) {
    return (
      <Wrap {...rest}>
        <CircularProgress />
      </Wrap>
    );
  }

  return children || null;
};
