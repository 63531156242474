import styled from 'styled-components';
import MuiButton from '@mui/material/Button';

export const Button = styled(MuiButton)`
  padding: 0 32px;

  svg {
    margin-right: 6px;
  }
`;
