import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { WhitelistTable } from '@organisms';
import UploadIcon from '@mui/icons-material/Upload';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';

export const Whitelist: FC = () => {
  const navigate = useNavigate();
  const isBusiness = false;

  const handleOpenWhitelistModal = () => {
    const companyRoute = isBusiness ? ROUTES.BusinessAccounts : ROUTES.ContentCreators;
    sessionStorage.setItem(
      'add-whitelist-close-path',
      `/${ROUTES.Whitelist}/${companyRoute}${window.location.search || ''}`,
    );
    navigate(`${ROUTES.Add}`);
  };
  return (
    <>
      <Box display="flex" mb="16px" justifyContent="space-between">
        <Box>
          <Typography variant="h5" mb="8px">
            Whitelist
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Below is whitelist of this account business.
          </Typography>
        </Box>
        <Box display="flex" gap="9px" justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            startIcon={<UploadIcon />}
            onClick={handleOpenWhitelistModal}
          >
            Upload links
          </Button>
        </Box>
      </Box>
      <WhitelistTable />
    </>
  );
};
