import { useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { useQuery } from 'react-query';

import { User } from '@services';
import { Axios, setAuthToken } from '@helpers';
import { ROUTES } from '@constants';

export const useUser = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { refetch } = useQuery<unknown, unknown, User>(
    'profile',
    () => Axios.get('/user/profile').then((res) => res.data),
    {
      enabled: false,
      onSuccess: () => setIsLoading(false),
    },
  );

  useEffect(() => {
    const updateUser = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        setUser(authenticatedUser);
        await setAuthToken();
        if (window.location.pathname !== `/${ROUTES.SocialAuth}`) {
          refetch();
        } else {
          setIsLoading(false);
        }
      } catch {
        setUser(null);
        setIsLoading(false);
      }
    };
    Hub.listen('auth', updateUser); // listen for login events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove('auth', updateUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { user, isLoading };
};
