import styled from 'styled-components';

export const AvatarWrap = styled.div<{ isEditing?: boolean }>`
  position: relative;
  cursor: ${({ isEditing }) => (isEditing ? 'pointer' : 'inherit')};
  overflow: hidden;
  border-radius: 100%;
  transition: all 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: center;

  .photo {
    position: absolute;
    opacity: 0;
    z-index: 105;
  }

  &::before {
    content: '';
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    transition: all 0.2s linear;
  }

  &:hover::before {
    opacity: ${({ isEditing }) => (isEditing ? 0.5 : 0)};
  }

  &:hover .photo {
    opacity: ${({ isEditing }) => (isEditing ? 1 : 0)};
  }
`;
