import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Chip } from '@mui/material';
import { PLANS_LABEL } from '@constants';
import { Plans } from '@services';

export const styleConfigByPlan: {
  [key in Plans]: {
    color: 'black' | 'success';
    variant: 'outlined' | 'filled';
  };
} = {
  [Plans.Free]: {
    color: 'black',
    variant: 'outlined',
  },
  [Plans.Star]: {
    color: 'black',
    variant: 'outlined',
  },
  [Plans.Elite]: {
    color: 'black',
    variant: 'outlined',
  },
  [Plans.Vip]: {
    color: 'success',
    variant: 'filled',
  },
  [Plans.Platinum]: {
    color: 'black',
    variant: 'filled',
  },
  [Plans.Agency]: {
    color: 'black',
    variant: 'outlined',
  },
  [Plans.Business]: {
    color: 'black',
    variant: 'outlined',
  },
  [Plans.Enterprise]: {
    color: 'black',
    variant: 'filled',
  },
};

export const renderPlanCell = ({ value }: GridRenderCellParams) => {
  if (!value) return null;

  const config = styleConfigByPlan[value as Plans];

  return <Chip label={PLANS_LABEL[value as Plans]} color={config.color} variant={config.variant} />;
};
