import { Checkbox } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import React, { memo, useMemo } from 'react';
import { StyledCollapseTableCell, StyledCollapseTableChild } from './CollapseTableRow.styles';
import useShiftKeyDetector from './hook';

type Props = {
  columns: GridColumns;
  row: any;
  checkboxSelection?: boolean;
  value: any;
  onRowClick?: (row: any) => void;
  selectedItems?: string[];
  setSelectedItems?: (value: string[]) => void;
};

const CollapseTableChild: React.FC<Props> = memo(
  ({ columns, row, value, checkboxSelection, onRowClick, selectedItems, setSelectedItems }) => {
    const checked = useMemo(() => !!selectedItems?.includes(value?.id), [selectedItems, value]);
    const isShiftPressed = useShiftKeyDetector();

    const onCheckItem = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!setSelectedItems) return;
      if (checked && !event.target.checked && selectedItems && selectedItems?.length > 0) {
        setSelectedItems(selectedItems?.filter((item) => item !== value?.id));
      } else if (isShiftPressed && selectedItems?.length) {
        const selectedItemIndex = row.indexOf(row.find((rowItem: any) => rowItem.id === value.id));
        const formatedSelectedItems = selectedItems.map((item) => ({
          id: item,
          index: row.indexOf(row.find((rowItem: any) => rowItem.id === item)),
        }));
        const lastItem = formatedSelectedItems.reduce(
          (max, obj) => (obj.index > max.index ? obj : max),
          formatedSelectedItems[0],
        );
        const firstItem = formatedSelectedItems.reduce(
          (min, obj) => (obj.index < min.index ? obj : min),
          formatedSelectedItems[0],
        );
        const closestFirstItem =
          formatedSelectedItems
            .filter((item) => item.index < selectedItemIndex)
            .reduce<{ id: string; index: any } | undefined>(
              (prev, curr) =>
                prev === undefined ||
                selectedItemIndex - curr.index < selectedItemIndex - prev.index
                  ? curr
                  : prev,
              undefined,
            ) || null;

        if (selectedItemIndex < firstItem.index) {
          const minSelectedItems = row
            .filter((_: any, index: any) => index >= selectedItemIndex && index < firstItem.index)
            .map((item: any) => item.id);
          setSelectedItems([...minSelectedItems, ...(selectedItems ?? [])]);
        } else if (selectedItemIndex > lastItem.index) {
          const maxSelectedItems = row
            .filter((_: any, index: any) => index <= selectedItemIndex && index > lastItem.index)
            .map((item: any) => item.id);
          setSelectedItems([...maxSelectedItems, ...(selectedItems ?? [])]);
        } else if (closestFirstItem) {
          const middleSelectedItems = row
            .filter(
              (_: any, index: any) => index > closestFirstItem.index && index <= selectedItemIndex,
            )
            .map((item: any) => item.id);
          setSelectedItems([...middleSelectedItems, ...(selectedItems ?? [])]);
        }
      } else {
        setSelectedItems([value?.id, ...(selectedItems ?? [])]);
      }
    };

    return (
      <StyledCollapseTableChild sx={{ display: 'flex' }} key={value?.id} onClick={onRowClick}>
        {checkboxSelection && <Checkbox checked={checked} onChange={onCheckItem} />}
        {columns.map((column: any, index: number) => (
          <StyledCollapseTableCell
            maxWidth={column?.maxWidth}
            minWidth={column?.minWidth}
            width={column?.width}
            textAlign={column?.align}
            flex={column?.flex}
            // eslint-disable-next-line
            key={index}
          >
            {column?.renderCell({
              value: column?.field === 'preview' ? value.url : value[column?.field],
              row,
            })}
          </StyledCollapseTableCell>
        ))}
      </StyledCollapseTableChild>
    );
  },
);

export default CollapseTableChild;
