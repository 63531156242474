import { FC } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { CCAssetDetailsType } from '@services';
import { Axios } from '@helpers';
import { Box } from '@mui/material';
import { CCAssetsTable } from '@organisms/Tables';
import { CCAssetForm } from './CCAssetForm';

export const CCAssetDetails: FC = () => {
  const { id } = useParams();

  const { data } = useQuery<unknown, unknown, CCAssetDetailsType>(
    `content-creator-asset-data/${id}`,
    () => Axios.get(`/admin/accounts/${id}`).then((res) => res.data),
  );

  if (!data) {
    return null;
  }

  return (
    <Box>
      <CCAssetForm data={data} />
      <CCAssetsTable used={data.slots.used} />
    </Box>
  );
};
