import { FC } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { CCModelDetailsType } from '@services';
import { Axios } from '@helpers';
import { CCModelForm } from './CCModelForm';

export const CCModelDetails: FC = () => {
  const { id } = useParams();

  const { data } = useQuery<unknown, unknown, CCModelDetailsType>(
    `content-creator-data-model/${id}`,
    () => Axios.get(`/admin/accounts/content-creator/${id}`).then((res) => res.data),
  );

  if (!data) {
    return null;
  }

  return <CCModelForm data={data} />;
};
