import { ReactNode } from 'react';
import { FilterItem, MemberStatus } from '@services';
import { MEMBER_STATUS_OPTIONS } from '@constants';
import { SelectChangeEvent } from '@mui/material';
import { DefaultFilter } from '@molecules';

const KEY = 'activeStatus';

export const memberStatusFilter: FilterItem = {
  name: 'Status',
  key: KEY,
  getFilter: (onChange, onClose, setFilters, filter): ReactNode => (
    <DefaultFilter
      key={KEY}
      name="Status"
      value={filter[KEY] as MemberStatus}
      items={MEMBER_STATUS_OPTIONS}
      onChange={(event: SelectChangeEvent<unknown>) =>
        setFilters({ ...filter, [KEY]: event.target.value as string })
      }
      onClose={onClose}
    />
  ),
};
