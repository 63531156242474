import { Box, IconButton, styled } from '@mui/material';

export const StyledIconButton = styled(IconButton)`
  height: 40px;
  padding: unset;
`;

export const IconWrapper = styled(Box)`
  position: relative;

  &.unread::after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 1.5px solid white;
    background: #ff4848;
    position: absolute;
    right: 3px;
    top: 3px;
  }

  // & .MuiSvgIcon-root {
  //   fill: rgba(0, 0, 0, 0.38);
  // }

  // &.open .MuiSvgIcon-root {
  //   fill: #1D1D1D;
  // }
`;

export const StyledCard = styled(Box)`
  max-height: 496px;
  min-height: 176px;
  width: 433px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  padding: 10px 0;
`;
