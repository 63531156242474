import { Avatar, styled } from '@mui/material';

export const StyledAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  &.small {
    width: 92px;
    height: 92px;
  }
`;

export const StyledLabel = styled('label')`
  position: absolute;
  right: 0px;
  bottom: 0px;
  background: white;
  border-radius: 50%;
`;
