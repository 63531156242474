import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableContentCreator, FilterItem, TableSelectType, AssetsType } from '@services';
import { getSelectedAccount } from '@helpers';
import { ROUTES } from '@constants';
import { Table } from '@molecules/Table';
import {
  assignStatusFilter,
  paymentStatusFilter,
  planFilter,
  renderDateFilter,
} from '@molecules/Table/filters';
import { InnerView } from '@templates';
import { columns } from './utils';

const FILTERS_LIST: FilterItem[] = [
  planFilter(),
  paymentStatusFilter,
  {
    name: 'Join date',
    key: 'joinDate',
    resetFields: ['joinedStartDate', 'joinedEndDate'],
    getFilter: renderDateFilter({
      key: 'joinDate',
      label: 'Join date',
      resetFields: ['joinedStartDate', 'joinedEndDate'],
    }),
  },
  assignStatusFilter,
];

export const NewContentCreatorTable: FC = () => {
  const navigate = useNavigate();
  const preSelectedFilters = useMemo(() => ({ newClients: 'true' }), []);

  return (
    <InnerView autoHeight>
      <Table<TableContentCreator>
        searchPlaceholder="Search for Content creator name or Email"
        queryName="new-content-creators-table"
        columns={columns}
        apiRoute="/admin/accounts/content-creator"
        filtersList={FILTERS_LIST}
        preSelectedFilters={preSelectedFilters}
        getSelectedValue={getSelectedAccount<TableContentCreator>}
        selectedRowType={TableSelectType.Account}
        onRowClick={({ id, modelId, assetType, isRemoved }) => {
          if (id && !isRemoved) {
            sessionStorage.setItem('content-creator-table-back-to', window.location.pathname || '');
            sessionStorage.setItem('content-creator-table', window.location.search || '');
            const routeTo =
              assetType === AssetsType.Models
                ? `/${ROUTES.ContentCreators}/${id}/${modelId}/${ROUTES.ModelDetails}`
                : `/${ROUTES.ContentCreators}/${id}/${ROUTES.AssetDetails}`;
            navigate(routeTo);
          }
        }}
        checkboxSelection
      />
    </InnerView>
  );
};
