import { FC, useState, MouseEvent, memo } from 'react';
import TuneIcon from '@mui/icons-material/Tune';

import { Menu, MenuItem } from '@mui/material';
import { StyledButton } from './styles';

interface FilterButtonProps {
  options: { name: string; key: string }[];
  onSelect: (key: string) => void;
  disabled: boolean;
  hasSelected?: boolean;
}

export const FilterButton: FC<FilterButtonProps> = memo(
  ({ options, onSelect, disabled, hasSelected }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);

    return (
      <>
        <StyledButton
          variant="outlined"
          onClick={(event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
          endIcon={<TuneIcon />}
          color="black"
          className={menuOpen || (hasSelected && !disabled) ? 'active' : ''}
          disabled={disabled}
        >
          Add filters
        </StyledButton>
        <Menu anchorEl={anchorEl} open={menuOpen} onClose={() => setAnchorEl(null)}>
          {options.map(({ name, key }) => (
            <MenuItem
              key={key}
              onClick={() => {
                onSelect(key);
                setAnchorEl(null);
              }}
            >
              {name}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  },
);
