import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import { Axios } from '@helpers';
import { successMessage } from '@services';
import { useConfirmationModal } from '@hooks';
import DeleteIcon from '@mui/icons-material/Delete';

import { Button, SelectProps } from '@mui/material';
import { ConfirmModal } from '@organisms/Modals';

interface Props extends SelectProps {
  invoices: string[];
  refetch: () => void;
}

export const InvoiceDeleteSelected: FC<Props> = ({ invoices, refetch }) => {
  const { id } = useParams();

  const { mutate: deleteLinks } = useMutation<
    { invoiceIds: string[] },
    unknown,
    { invoiceIds: string[] }
  >((data) => Axios.delete(`/admin/companies/${id}/invoices`, { data }), {
    onSuccess: () => {
      successMessage('Invoices has been removed!');
      refetch();
    },
  });

  const handleOnChange = () => {
    deleteLinks({ invoiceIds: { ...invoices } });
  };

  const [open, onOpen, onCancel, onSubmit] = useConfirmationModal(false, handleOnChange);

  return (
    <>
      <Button
        onClick={onOpen}
        color="error"
        sx={{ textTransform: 'uppercase' }}
        startIcon={<DeleteIcon fontSize="small" />}
      >
        Delete
      </Button>
      <ConfirmModal
        open={open}
        onCancel={onCancel}
        onSubmit={onSubmit}
        title="Are you sure you want to delete the selected invoices?"
        submitText="Yes, delete"
      />
    </>
  );
};
