import { FC } from 'react';
import { MenuItem, Select, SelectProps } from '@mui/material';
import styled from 'styled-components';

import { PAYMENT_FILTER_STATUS } from '@constants';
import { FilterCloseWrap } from './FilterCloseWrap';

const SelectStyled = styled(Select)`
  height: 50px;

  .MuiSelect-select {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 8px;
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const MenuItemStyled = styled(MenuItem)`
  align-items: center;
  display: flex;

  svg {
    margin-right: 8px;
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
`;

interface Props extends SelectProps {
  onClose?: () => void;
  label?: string;
}

export const StatusFilter: FC<Props> = ({ onClose, label = 'Status', ...props }) => (
  <FilterCloseWrap label={label} onClose={onClose}>
    <SelectStyled placeholder="Select option" {...props}>
      {Object.keys(PAYMENT_FILTER_STATUS).map((status) => (
        <MenuItemStyled key={status} value={status}>
          {PAYMENT_FILTER_STATUS[status].icon}
          {PAYMENT_FILTER_STATUS[status].label}
        </MenuItemStyled>
      ))}
    </SelectStyled>
  </FilterCloseWrap>
);
