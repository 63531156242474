import { FC, useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Divider, Typography, Stack, Avatar, IconButton, Menu, MenuItem, Box } from '@mui/material';

import { useLogout } from '@hooks/useLogout';
import { capitalize } from '@helpers';
import { useProfile } from '@hooks/useProfile';
import { ROUTES } from '@constants';

import { Notification } from '@molecules';
import { HeaderWrap } from './styles';

const version = process.env.REACT_APP_VERSION || 'local';

export const Header: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const logout = useLogout();
  const { profile } = useProfile();
  const navigate = useNavigate();

  const onClose = () => setAnchorEl(null);

  return (
    <>
      <HeaderWrap>
        <Typography mr="20px">
          <b>FE Version:</b> {version.substr(0, 6)}
        </Typography>
        <Notification sx={{ marginRight: '40px' }} />
        <Avatar src={profile?.avatarUrl || ''} />
        <Stack ml="16px" mr="6px" direction="column" minWidth="122px">
          <Typography variant="body2">
            {profile?.firstName || ''} {profile?.lastName || ''}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {capitalize(profile?.role || '')}
          </Typography>
        </Stack>

        <Box>
          <IconButton
            onClick={(event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
          >
            <KeyboardArrowDownIcon fontSize="medium" />
          </IconButton>
          <Menu anchorEl={anchorEl} open={menuOpen} onClose={onClose}>
            <MenuItem onClick={logout}>Logout</MenuItem>
            <MenuItem
              onClick={() => {
                navigate(`/${ROUTES.Settings}`);
                onClose();
              }}
            >
              Account settings
            </MenuItem>
          </Menu>
        </Box>
      </HeaderWrap>
      <Divider />
    </>
  );
};
