import { useQueryClient } from 'react-query';

import { User } from '@services';
import { USER_ROLES } from '@constants';

export const useProfile = (): { profile: User; isAdmin: boolean } => {
  const queryClient = useQueryClient();
  const [[, profile]] = queryClient.getQueriesData<User>('profile');

  return { profile, isAdmin: profile?.role === USER_ROLES.Admin };
};
