import { styled, Chip, TextField, Box } from '@mui/material';

export const EndAdornmentClose = styled('div')`
  right: 10px;
  bottom: 9px;
  position: absolute;
`;

export const TextFieldStyled = styled(TextField)(
  (props) => `
  max-width: 100%;

  .MuiInputBase-root {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 8px;
    padding-top: ${props.size === 'small' ? '5px' : '9px'};
    padding-right: ${props.InputProps?.endAdornment ? '9px' : '9px'};
    padding-bottom: ${props.size === 'small' ? '5px' : '9px'};
    padding-left: 12px;
    min-height: 50px;

    .MuiOutlinedInput-input {
      min-width: 30px;
      width: auto;
      flex-grow: 1;
      text-overflow: ellipsis;
      margin: 4px 0px;
      margin-right: 36px;
      align-self: center;
      padding: 0;
    }
  }
`,
);

export const ChipStyled = styled(Chip)(
  ({ theme, size }) => `
  max-width: 100%;
  height: ${size === 'small' ? '26px' : '32px'};


  &[aria-disabled="true"] > svg {
    color: ${theme.palette.text.disabled};
    cursor: default;
  }

  &.MuiChipsInput-Chip-Editing {
    background-color: #9e9e9e;
    border-color: #9e9e9e;
    color: ${theme.palette.primary.contrastText};
  }
  
  &.error {
    border-color: #FF4848;
  }
`,
);

export const ChipsContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
  max-height: 161px;
  overflow: hidden;
  overflow-y: scroll;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 8px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.4);
  }
`;
