import { Dispatch, SetStateAction } from 'react';
import validUrl from 'valid-url';

export const validateLink =
  (setErrors: Dispatch<SetStateAction<number[]>>) => (link: string, index: number) => {
    if (!validUrl.isWebUri(convertedEmojiURL(link))) {
      setErrors((errors) => (errors.includes(index + 1) ? errors : [...errors, index + 1]));
    }
  };

export const splitLinks = (value = '') =>
  value
    .trim()
    .split(/\s/g)
    .filter((link) => !!link)
    .map((link) => link.replace(/"([^"]+(?="))"/g, '$1'))
    .map((link) => link.replace(/'([^']+(?='))'/g, '$1'));

export const convertedEmojiURL = (link: string) =>
  link
    .split('')
    .map((char) => {
      try {
        return /[A-Za-z0-9\-_.~]/.test(char) ? char : encodeURI(char);
      } catch (e) {
        return encodeURIComponent(
          Array.from(char)
            .map((c) => {
              const codePoint = c.codePointAt(0);
              return codePoint ? `\\u${codePoint.toString(16)}` : '';
            })
            .join(''),
        );
      }
    })
    .join('');
