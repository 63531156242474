import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import pick from 'object.pick';
import dayjs from 'dayjs';
import { useMutation, useQueryClient } from 'react-query';
import SaveIcon from '@mui/icons-material/Save';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

import {
  COMPANY_TYPE_LABEL,
  contentCreatorValidation,
  DATE_FORMAT,
  PAYMENT_STATUS,
  CREATOR_PLANS_OPTIONS,
  COMPANY_ASSETS_LABEL,
} from '@constants';
import { CCModelDetailsType, PaymentStatus, Plans, successMessage } from '@services';
import { Axios, getStatus } from '@helpers';
import { useConfirmationModal, useFormEqual } from '@hooks';
import { yupResolver } from '@hookform/resolvers/yup';

import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FakeInput, InputWithChips, TextField } from '@atoms';
import { SelectFabric } from '@molecules';
import { ConfirmModal } from '@organisms/Modals';
import { Pause, PlayArrow } from '@mui/icons-material';

export interface FormData {
  companyName: string;
  subscriptionPlan: Plans;
  firstName: string;
  lastName: string;
  transparencyReportLink?: string | null;
  overrideFormulaCoef: number | null;
}

interface RequestBody {
  isBlocked: boolean;
}

export const CCModelForm: FC<{ data: CCModelDetailsType }> = ({ data }) => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const defaultValues = useMemo(
    () => ({
      ...pick(data, ['companyName', 'firstName', 'lastName', 'overrideFormulaCoef']),
      transparencyReportLink: data.transparencyReportLink || '',
      subscriptionPlan: data.plan,
    }),
    [data],
  );
  const { isRemoved, companyName, logo } = data;
  const isBlocked = data?.isBlocked || false;
  const [isLoadingBlock, setIsLoadingBlock] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { handleSubmit, control, watch, reset } = useForm<FormData>({
    resolver: yupResolver(contentCreatorValidation),
    defaultValues,
    mode: 'onChange',
    delayError: 700,
  });

  useFormEqual<FormData>({
    setDisabled,
    disabled,
    data: defaultValues,
    reset,
    watch,
  });

  const { mutate: onSubmit, isLoading } = useMutation<unknown, unknown, FormData>(
    (formData) => Axios.put(`/admin/accounts/content-creator/${data.id}`, formData),
    {
      onSuccess: () => {
        successMessage(`${companyName} has been updated!`);
        queryClient.refetchQueries(`content-creator-data-model/${id}`);
        queryClient.refetchQueries(`content-creator-data-asset/${id}`);
      },
    },
  );

  const finalSubmit = (formData: FormData) =>
    onSubmit({
      ...formData,
      transparencyReportLink: formData.transparencyReportLink || null,
    });

  const isFreeTrial = data?.freeTrialEffectiveDate
    ? dayjs(data?.freeTrialEffectiveDate).isAfter(dayjs())
    : false;

  const status = getStatus(data.status, data.plan, data.isRemoved, isFreeTrial);
  const { mutate: onBlockCompany } = useMutation<unknown, unknown, RequestBody>(
    (requestBody) => Axios.put(`/admin/accounts/${id}`, requestBody),
    {
      onSuccess: () => {
        setIsLoadingBlock(false);
        successMessage(`${data?.companyName} has been ${isBlocked ? 'enabled' : 'blocked'}!`);
        queryClient.refetchQueries(`content-creator-data-model/${id}`);
      },
    },
  );

  const updatePaymentStatusMutation = useMutation<unknown, unknown, { status: PaymentStatus }>(
    (requestBody) => Axios.put(`/admin/accounts/${id}/payment-status`, requestBody),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(`content-creator-data-model/${id}`);
        successMessage(
          `${data?.companyName}'s payment has been ${
            data.status === PaymentStatus.Paused ? 'Resumed' : 'Paused'
          }!`,
        );
      },
    },
  );

  const blockCompany = () => {
    setIsLoadingBlock(true);
    onBlockCompany({
      isBlocked: !isBlocked,
    });
  };
  const [open, onOpen, onCancel, onBlock] = useConfirmationModal(false, () => blockCompany());
  const getTitleModal = () =>
    `Are you sure you want to ${isBlocked ? 'enable' : 'block'} this account?`;
  const getConfirmTitle = () => `Yes, ${isBlocked ? 'enable' : 'block'}`;
  return (
    <Box component="form" onSubmit={handleSubmit(finalSubmit)}>
      <Box display="flex" width="100%" justifyContent="space-between">
        <Typography variant="h5" mb="8px">
          General information
        </Typography>
        <Box display="flex" gap="10px">
          <LoadingButton
            variant="contained"
            color={isBlocked ? 'success' : 'error'}
            startIcon={isBlocked ? null : <DoNotDisturbIcon />}
            onClick={onOpen}
            loading={isLoadingBlock}
          >
            {isBlocked ? 'Enable' : 'Block'} account
          </LoadingButton>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={disabled}
            startIcon={<SaveIcon />}
            loading={isLoading}
          >
            Save changes
          </LoadingButton>
        </Box>
      </Box>
      <Box width="664px">
        <Typography variant="body2" mb="16px">
          Below is general information about the user and their account.
        </Typography>
        <Box display="flex" gap="24px" mb="24px" mt="24px">
          <Avatar alt={companyName} sx={{ width: 82, height: 82 }} src={logo}>
            {companyName[0].toUpperCase()}
          </Avatar>
          <TextField
            label="Content creator name"
            control={control}
            name="companyName"
            disabled={isRemoved}
            placeholder="Content creator name or pseudonym"
            required
          />
        </Box>
        <Box display="flex" gap="24px" mb="24px" mt="24px">
          <TextField
            label="First name"
            name="firstName"
            placeholder="First User name"
            control={control}
            disabled={isRemoved}
            required
          />
          <TextField
            label="Last name"
            name="lastName"
            placeholder="Last User name"
            control={control}
            disabled={isRemoved}
          />
        </Box>
        <FakeInput label="Email">{data.email}</FakeInput>
        <InputWithChips label="Usernames" mt="24px" chips={data.socialMedia} />
        <Box display="flex" gap="24px" mb="24px" mt="24px">
          <TextField
            control={control}
            label="Value formula"
            placeholder="Add value formula"
            name="overrideFormulaCoef"
          />
          <TextField
            label="Transparency link report"
            control={control}
            name="transparencyReportLink"
            placeholder="Add link"
            disabled={isRemoved}
          />
        </Box>
        <Box display="flex" gap="24px" mt="24px" mb="40px">
          <FakeInput label="Account type">
            <Typography color="text.disabled" sx={{ display: 'flex' }}>
              <span>{COMPANY_TYPE_LABEL[data.companyType]}</span>
              <Divider orientation="vertical" sx={{ height: 'auto', mx: '8px' }} />
              <span>{COMPANY_ASSETS_LABEL.models}</span>
            </Typography>
          </FakeInput>
          <FakeInput label="Join date">{dayjs(data.joinDate).format(DATE_FORMAT)}</FakeInput>
        </Box>
        <Typography variant="h5" mb="8px">
          Billing plan
        </Typography>
        <Typography variant="body2" mb="16px">
          Here is you can find all information about users&apos; billing plans and plan due dates.
        </Typography>
        <Grid mt="24px" container spacing="24px" alignItems="end">
          <Grid item xs={4}>
            <SelectFabric
              label="Plan type"
              control={control}
              name="subscriptionPlan"
              options={CREATOR_PLANS_OPTIONS}
              disabled
            />
          </Grid>
          <Grid item xs>
            <FakeInput label="Status">
              {PAYMENT_STATUS[status].icon}
              <Typography ml="10px">{PAYMENT_STATUS[status].label}</Typography>
            </FakeInput>
          </Grid>
          {data.nextBillingDate ? (
            <Grid item xs>
              <FakeInput label="Next billing date">
                {dayjs(data.nextBillingDate).format(DATE_FORMAT)}
              </FakeInput>
            </Grid>
          ) : data.subscriptionCancellationEffectiveDate ? (
            <Grid item xs>
              <FakeInput label="Available till">
                {dayjs(data.subscriptionCancellationEffectiveDate).format(DATE_FORMAT)}
              </FakeInput>
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs>
            <LoadingButton
              disabled={
                data.status !== PaymentStatus.Active && data.status !== PaymentStatus.Paused
              }
              variant="contained"
              color={data.status === PaymentStatus.Paused ? 'success' : 'error'}
              startIcon={data.status === PaymentStatus.Paused ? <PlayArrow /> : <Pause />}
              loading={
                updatePaymentStatusMutation.isLoading ||
                !!queryClient.isFetching(`content-creator-data-model/${id}`)
              }
              onClick={() =>
                updatePaymentStatusMutation.mutate({
                  status:
                    data.status === PaymentStatus.Paused
                      ? PaymentStatus.Active
                      : PaymentStatus.Paused,
                })
              }
            >
              {data.status === PaymentStatus.Paused ? 'Resume' : 'Pause'}
            </LoadingButton>
          </Grid>
        </Grid>
        <ConfirmModal
          open={open}
          onCancel={onCancel}
          onSubmit={onBlock}
          title={getTitleModal()}
          submitText={getConfirmTitle()}
        />
      </Box>
    </Box>
  );
};
