import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Tooltip, Typography } from '@mui/material';

export const renderTextCell = ({ value }: GridRenderCellParams) => (
  <Tooltip title={value}>
    <Typography className="ellipsis" variant="body2">
      {value}
    </Typography>
  </Tooltip>
);
