import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { Axios, copyToClipboard, queryClient } from '@helpers';
import { LinkStatuses, LinkType } from '@services';
import { useConfirmationModal, useModalState } from '@hooks';

import { LoadingButton } from '@mui/lab';
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import { Button, Modal } from '@atoms';
import { ConfirmModal } from '@organisms';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { ROUTES } from '@constants';
import { useNavigate } from 'react-router-dom';

const LIMIT = 1000;

export const SUBMIT_INFRINGING_QUERY_NAME = (assetId?: string) =>
  `/admin/status-transition/${assetId}`;

interface Props {
  refetchQueryName: string;
  assetId?: string;
  id?: string;
}

export const SubmitInfringingLinkButton: FC<Props> = ({ refetchQueryName, assetId, id }) => {
  const [isOpen, , openModal, closeModal] = useModalState(false);
  const [submittedLink, setSubmittedLink] = useState(0);
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useQuery<unknown, unknown, { totalCount: number }>(
    SUBMIT_INFRINGING_QUERY_NAME(assetId),
    () =>
      Axios.get(`/admin/models/${assetId}/links?status=${LinkStatuses.Infringing}`).then(
        (res) => res.data.totalCount,
      ),
  );

  const { mutate: submitLinks, isLoading: isLoadingLinks } = useMutation<LinkType[], unknown, void>(
    () =>
      Axios.post(`/admin/models/${assetId}/links/status-transition`, {
        transitionFrom: 'infringing',
        transitionTo: 'submitted',
      }).then((res) => res.data),
    {
      onSuccess: (links) => {
        setSubmittedLink(links.length);
        const text = links.map(({ url }) => url).join('\n');
        copyToClipboard(text, 'Links has been copied!');
        refetch();
        queryClient.refetchQueries(refetchQueryName);
        openModal();
      },
    },
  );

  const handleClickWorkingMode = () => {
    sessionStorage.setItem('working-mode-back-to', window.location.pathname || '');
    sessionStorage.setItem('working-mode', window.location.search || '');
    navigate(`/${ROUTES.WorkingMode}/${assetId}/${id}/${ROUTES.Raw}`);
  };

  const [open, onOpen, onCancel, onSubmit] = useConfirmationModal(false, () => submitLinks());
  const totalCount: any = data || 0;
  const linkText = totalCount > 1 ? 'links' : 'link';
  const hasInfringingLinks = Boolean(totalCount);
  const isLimitExceeded = totalCount > LIMIT;

  return (
    <>
      <LoadingButton
        variant="contained"
        color="black"
        sx={{ p: '12px 24px', minWidth: '180px' }}
        loading={isLoading || isLoadingLinks}
        disabled={!hasInfringingLinks}
        onClick={onOpen}
      >
        Submit and Copy
      </LoadingButton>
      <Tooltip title="Working mode" placement="top" arrow>
        <Button
          variant="outlined"
          size="small"
          color="black"
          sx={{ padding: 0, minWidth: 50 }}
          onClick={handleClickWorkingMode}
        >
          <SettingsSuggestIcon fontSize="small" />
        </Button>
      </Tooltip>
      <ConfirmModal
        open={open}
        onCancel={onCancel}
        onSubmit={onSubmit}
        title={
          <>
            Are you sure you want to move <b>{isLimitExceeded ? LIMIT : totalCount}</b> {linkText}
            <br />
            with INFRINGING status to SUBMITTED?
            <br />
            It can’t be undone.
          </>
        }
        helperText={
          isLimitExceeded ? (
            <Box width="100%">
              <Divider sx={{ my: '16px' }} />
              <Typography
                variant="body2"
                color="text.disabled"
                align="center"
                sx={{ width: '80%', mx: 'auto' }}
              >
                You have {totalCount} links with INFRINGING status total. But you can Submit and
                Copy {LIMIT} infringing links at time.
              </Typography>
            </Box>
          ) : null
        }
        submitText="Move and Copy"
      />
      <Modal open={isOpen} onClose={closeModal}>
        <Box
          width="400px"
          margin="auto"
          display="flex"
          alignItems="center"
          flexDirection="column"
          pb="12px"
        >
          <CheckCircleIcon sx={{ fontSize: 83, color: '#69BEFC', mb: '16px' }} />
          <Typography align="center">
            <b>{submittedLink}</b> {linkText} with INFRINGING status
            <br />
            moved to SUBMITTED and copied
            <br />
            to the clipboard.
          </Typography>
          <Button variant="contained" onClick={closeModal} sx={{ mt: '48px', width: '164px' }}>
            Got it
          </Button>
        </Box>
      </Modal>
    </>
  );
};
