import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import {
  AssignAssetsParams,
  BusinessDetailsType,
  successMessage,
  TableModelAccount,
} from '@services';
import { Axios } from '@helpers';
import { ROUTES } from '@constants';

import CheckIcon from '@mui/icons-material/Check';
import { Divider, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Button, Modal } from '@atoms';
import { MoveAssetModelTable } from './MoveAssetTables';

export const MoveAssetModal: FC = () => {
  const { id } = useParams();
  const [selectedMembersId, setSelectedMembersId] = useState<string[]>([]);
  const navigate = useNavigate();
  const [backPath, setBackPath] = useState(`${ROUTES.Accounts}/${ROUTES.BusinessAccounts}`);
  const { data } = useQuery<unknown, unknown, BusinessDetailsType>(`business-details/${id}`, () =>
    Axios.get(`/admin/accounts/${id}`).then((res) => res.data),
  );

  const handleOnClose = () => {
    navigate(backPath);
  };

  const getSelectedAccount = <T extends { id: string }>(_items: T[], selectedItemsId: string[]) => {
    setSelectedMembersId(selectedItemsId);
    return '';
  };

  const { mutate: onSubmit, isLoading } = useMutation<unknown, unknown, { modelIds: string[] }>(
    ({ modelIds }) => {
      const submitParams: AssignAssetsParams = {
        modelIds,
      };
      return Axios.put(`/admin/models/move-models/${id}`, { ...submitParams });
    },
    {
      onSuccess: () => {
        successMessage('Assets have been moved!');
        handleOnClose();
      },
    },
  );

  const handleOnSubmit = () => {
    onSubmit({
      modelIds: selectedMembersId,
    });
  };

  useEffect(() => {
    const storedPath = sessionStorage.getItem('move-assets-close-path');
    if (storedPath) {
      setBackPath(storedPath);
    }
  }, []);

  const isDisabled = !selectedMembersId.length;

  return (
    <>
      <Box>123213</Box>
      <Modal title="Move asset" onClose={handleOnClose} width="800px" open>
        <Box display="flex" flexDirection="column">
          <Typography variant="body2" color="text.secondary">
            Here you can move asset to {data?.companyName}
          </Typography>
          <Divider sx={{ my: '24px', mx: '-32px' }} />
          <MoveAssetModelTable getSelectedAccount={getSelectedAccount<TableModelAccount>} />
          <Box display="flex" ml="auto" mt="24px">
            <Button variant="text" color="secondary" onClick={handleOnClose}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="black"
              startIcon={<CheckIcon />}
              disabled={isDisabled}
              loading={isLoading}
              onClick={handleOnSubmit}
            >
              Move assets
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
