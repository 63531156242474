import { Box, styled } from '@mui/material';

export const StyledContainer = styled(Box)`
  border-bottom: 1px solid #e8e9e9;
  position: relative;
  padding: 12px 26px 12px 40px;

  &.unread {
    &:hover {
      background: rgba(0, 0, 0, 0.03);
      cursor: pointer;
    }

    &::before {
      content: '';
      display: block;
      background: red;
      width: 6px;
      height: 6px;
      border-radius: 50px;
      position: absolute;
      left: 19px;
      top: calc(50% - 3px);
    }
  }
`;
