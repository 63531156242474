import { FC } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';

import { AuthContainer } from '@templates';
import { TextField, Link } from '@atoms';
import { BASE_URL, resetPasswordValidation, ROUTES } from '@constants';
import { ReactComponent as MarkEmailRead } from '@assets/mark-email-read.svg';
import { Axios } from '@helpers';

interface FormData {
  email: string;
}

export const ForgotPassword: FC = () => {
  const {
    mutate: resetPassword,
    isLoading,
    isSuccess,
  } = useMutation<unknown, unknown, FormData>((data) =>
    Axios.post('/auth/reset-password', {
      ...data,
      redirectUrl: `${BASE_URL}/${ROUTES.CreateNewPassword}`,
    }),
  );

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<FormData>({
    resolver: yupResolver(resetPasswordValidation),
    mode: 'onChange',
    delayError: 700,
  });

  const onSubmit = (data: FormData) => resetPassword(data);

  return (
    <AuthContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography textAlign="center" variant="h4" mb="10px">
          {isSuccess ? 'Your reset password email is on the way!' : 'Forgot Password?'}
        </Typography>
        <Typography color="text.secondary" textAlign="center" variant="body2" mb="24px">
          {isSuccess
            ? 'Go check your inbox, and we’ll have you back up and running in no time.'
            : 'Enter your Ceartas email address and we’ll send you instructions to reset your password.'}
        </Typography>

        {isSuccess ? (
          <>
            <MarkEmailRead />
            <Typography color="text.disabled" textAlign="center" fontSize="10px" my="24px">
              Didn&apos;t get an email? Check your Ceartas email and try again. If this does not
              work, contact your administrator.
            </Typography>
          </>
        ) : (
          <>
            <TextField name="email" control={control} label="Email" placeholder="name@ceartas.io" />

            <Box mt="24px" mb="24px" width="100%">
              <Button fullWidth variant="contained" type="submit" disabled={isLoading || !isValid}>
                Submit
              </Button>
            </Box>
          </>
        )}
        <Link to={`/${ROUTES.Login}`}>Return to Login</Link>
      </form>
    </AuthContainer>
  );
};
