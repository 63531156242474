import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { AssetsType, ScanSettingItemType } from '@services';
import { Axios, queryClient } from '@helpers';
import { useParams } from 'react-router-dom';
import { Loader } from '@atoms';
import { ScanSettingsForm } from '@organisms/Details/ScanSettings/ScanSettingsForm';
import { ScanHistoryTable } from '@organisms/Tables';

export const ScanSettings: FC = () => {
  const { id, assetId } = useParams();
  const assetData = queryClient.getQueryData(`asset-account-details/${id}`) as any;
  const CCData = queryClient.getQueryData(`content-creator-data-asset/${id}`) as any;
  const isModel = (assetData?.assetType || CCData?.assetType) === AssetsType.Models;
  const firstName = assetData?.firstName || assetData?.username || CCData?.firstName;
  const { data, isLoading } = useQuery<unknown, unknown, ScanSettingItemType>(
    `scan-management-details/${id}`,
    () =>
      Axios.get(`/admin/companies/${id}/assets/${assetId}/scan-settings`).then((res) => res.data),
  );

  return (
    <Loader isLoading={isLoading}>
      <Box>
        {data && (
          <ScanSettingsForm
            data={data}
            firstName={firstName}
            isModel={isModel}
            assetData={CCData || assetData}
          />
        )}
        <Box mt="40px" mb="16px">
          <Typography variant="h5" mb="8px">
            Scan history
          </Typography>
          <Typography variant="body2" color="text.secondary" mb="16px">
            Here you can find the total score of manual and automatic scanning.
          </Typography>
          <ScanHistoryTable id={id as string} assetId={assetId as string} />
        </Box>
      </Box>
    </Loader>
  );
};
