import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { TableContentCreator } from '@services';
import { getLastModifiedColor, getStatus, removedCellClassName } from '@helpers';
import { DATE_FORMAT } from '@constants';

import { Typography, Chip, IconButton } from '@mui/material';
import { InfoCell, UserPaymentStatus } from '@atoms';
import { renderPlanCell } from '@molecules/Table/cells';

export const columns: GridColDef<TableContentCreator>[] = [
  {
    field: 'contentCreator',
    headerName: 'Content creator',
    minWidth: 230,
    flex: 3,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { contentCreator, fullName, email, logo } }) => (
      <InfoCell name={contentCreator || fullName} email={email} avatarUrl={logo} />
    ),
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    minWidth: 150,
    cellClassName: removedCellClassName,
    flex: 2,
  },
  {
    field: 'plan',
    headerName: 'Plan',
    minWidth: 80,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: renderPlanCell,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 130,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { status, plan, isRemoved, freeTrialEffectiveDate } }) => {
      const isFreeTrial = freeTrialEffectiveDate
        ? dayjs(freeTrialEffectiveDate).isAfter(dayjs())
        : false;
      const paymentStatus = getStatus(status, plan, isRemoved, isFreeTrial);
      return <UserPaymentStatus status={paymentStatus} />;
    },
  },
  {
    field: 'foundDeleted',
    headerName: 'Links found / deleted',
    minWidth: 200,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    cellClassName: removedCellClassName,
    sortable: false,
    renderCell: ({
      row: {
        links: { found, deleted },
      },
    }) => (
      <>
        <Typography variant="subtitle2" color="error.main">
          {found}
        </Typography>
        <Typography ml="3px" mr="3px" variant="subtitle2" color="text.disabled">
          /
        </Typography>
        <Typography variant="subtitle2" color="success.main">
          {deleted}
        </Typography>
      </>
    ),
  },
  {
    field: 'lastScanningDate',
    headerName: 'Last Scanning',
    minWidth: 140,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: ({ value }) =>
      value ? (
        <Chip label={dayjs(value).format(DATE_FORMAT)} color={getLastModifiedColor(value)} />
      ) : (
        <Typography color="text.disabled">No scanning</Typography>
      ),
  },
  {
    field: 'joinDate',
    headerName: 'Join date',
    minWidth: 110,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { joinDate } }) => dayjs(joinDate).format(DATE_FORMAT),
  },
  {
    field: 'action',
    headerName: '',
    width: 54,
    cellClassName: removedCellClassName,
    renderCell: () => (
      <IconButton className="show-on-hover">
        <EditIcon />
      </IconButton>
    ),
  },
];
