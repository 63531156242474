import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { CheckIcon } from '@atoms/Icons';

export interface CriteriaProps {
  text: string;
  checked: boolean;
}

export const Criteria: FC<CriteriaProps> = ({ text, checked }) => (
  <Box display="flex" alignItems="center" mb="12px">
    <CheckIcon height={18} width={18} checked={checked} />
    <Typography variant="body2" color="text.disabled" ml="10px">
      {text}
    </Typography>
  </Box>
);
