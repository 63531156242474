// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

export const getAwsConfig = (isLocalhost: boolean) => ({
  Auth: {
    region: 'eu-central-1',
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  oauth: {
    domain: process.env.REACT_APP_GOOGLE_AUTH_URL,
    redirectSignIn: isLocalhost
      ? 'http://localhost:3000/social-auth'
      : `${process.env.REACT_APP_BASE_URL}/social-auth`,
    redirectSignOut: isLocalhost
      ? 'http://localhost:3000/login'
      : `${process.env.REACT_APP_BASE_URL}/login`,
    responseType: 'token',
  },
});
