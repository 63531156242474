import dayjs from 'dayjs';
import { GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { InfoCell, UserStatus } from '@atoms';
import { AssignModelControl } from '@molecules';

import { ActiveStatus, TableUser } from '@services';
import { DATE_FORMAT } from '@constants';
import { capitalize } from '@helpers';

import { UserAction } from './UserAction';

const removedCellClassName = ({ row: { status } }: GridCellParams<TableUser>) =>
  status === ActiveStatus.EmailSent ? 'disabled-row' : '';

export const columns: GridColDef<TableUser>[] = [
  {
    field: 'name',
    headerName: 'Full name',
    width: 212,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { firstName, lastName, avatar } }) => (
      <InfoCell name={`${firstName || ''} ${lastName || ''}`} avatarUrl={avatar || ''} />
    ),
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 264,
    cellClassName: removedCellClassName,
  },
  {
    field: 'role',
    headerName: 'Permisson type',
    width: 173,
    cellClassName: removedCellClassName,
    valueGetter: (params: GridValueGetterParams) => capitalize(params.row.role),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 230,
    renderCell: ({ row: { status, invitedAt } }) => (
      <UserStatus
        status={status}
        text={
          invitedAt && status === ActiveStatus.EmailSent
            ? `(${dayjs(invitedAt).format(DATE_FORMAT)})`
            : undefined
        }
      />
    ),
  },
  {
    field: 'assignedAccounts',
    headerName: 'Assign',
    sortable: false,
    width: 160,
    renderCell: ({ row }) => <AssignModelControl data={row} />,
  },
  {
    field: 'action',
    headerName: '',
    sortable: false,
    width: 55,
    renderCell: ({ row: { status, id } }) => <UserAction userId={id} status={status} />,
  },
];
