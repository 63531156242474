import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AuthContainer } from '@templates';
import { PasswordForm } from '@organisms';
import { ReactComponent as VerifiedImage } from '@assets/verified.svg';
import { Button } from '@atoms';
import { ROUTES } from '@constants';
import { Axios } from '@helpers';

interface FormData {
  newPassword: string;
}

export const CreatePassword: FC<{ isAuthorized?: boolean }> = ({ isAuthorized }) => {
  const url = new URL(window.location.href);
  const email = url.searchParams.get('email')?.replaceAll(' ', '+') || ''; // empty string to avoid TS issue
  const key = url.searchParams.get('key');

  const navigate = useNavigate();
  const { handleSubmit, control, watch } = useForm<FormData>();

  const {
    mutate: createPassword,
    isLoading,
    isSuccess,
  } = useMutation<unknown, unknown, FormData>((data) =>
    Axios.post('/auth/reset-password/confirm', { ...data, email, key }),
  );

  const onSubmit = (data: FormData) => createPassword(data);

  const password = watch('newPassword');

  return (
    <AuthContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h4" mb="10px" textAlign="center">
          {isSuccess ? (
            <>
              Your password was
              <br /> successfully <br /> changed
            </>
          ) : (
            'Create a new password'
          )}
        </Typography>
        <Typography textAlign="center" color="text.secondary" variant="body2" mb="24px">
          {isSuccess
            ? 'Go to Login and use the new password with your Ceartas email.'
            : 'Create a new strong password that you will remember and match all criterias listed below'}
        </Typography>
        {isSuccess ? (
          <>
            <VerifiedImage />
            <Box mt="24px" width="100%">
              <Button fullWidth variant="contained" onClick={() => navigate(`/${ROUTES.Login}`)}>
                {isAuthorized ? 'Go to App' : 'Go to Login'}
              </Button>
            </Box>
          </>
        ) : (
          <PasswordForm
            fieldName="newPassword"
            control={control}
            password={password}
            buttonDisabled={isLoading}
            buttonText="Submit"
          />
        )}
      </form>
    </AuthContainer>
  );
};
