import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';

import { getAwsConfig } from '@constants';

import App from './App';
import 'normalize.css';
import './styles/main.css';

let isLocalhost = false;

if (typeof window !== 'undefined') {
  isLocalhost = !!(window.location.hostname === 'localhost');
}

const AWS_CONFIG = getAwsConfig(isLocalhost);

Amplify.configure(AWS_CONFIG);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
