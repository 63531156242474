import styled from 'styled-components';

export const AuthContainerWrap = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.palette.mainBackground};
  padding-top: 80px;
`;

export const AuthContainerInner = styled.div`
  background: ${(props) => props.theme.palette.secondary.contrastText};
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 12px;
  margin: auto;
  width: 456px;
  padding: 56px 48px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const LogoWrap = styled.div`
  margin: 0 auto 80px;
  width: fit-content;
`;
