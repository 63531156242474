import { AvatarGroup, styled } from '@mui/material';

export const StyledAvatarGroup = styled(AvatarGroup)`
  flex-direction: row;

  & .MuiAvatar-root {
    border-color: #f6f6f6;

    &.number {
      background: #f6f6f6;
      color: #1e1f20;
    }

    &:first-of-type {
      order: 100;
      z-index: 1;
      min-width: 30px;
      width: auto;
    }

    &:last-child {
      margin-left: -8px;
    }
  }
`;
