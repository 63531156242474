import { FC, ReactNode } from 'react';
import { ButtonProps } from '@mui/material';
import { StyledButton } from './styles';

export interface Props extends ButtonProps {
  children: ReactNode;
}

export const Button: FC<Props> = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
);
