import { Controller, Control, Path, FieldValues } from 'react-hook-form';
import get from 'lodash.get';
import { OptionType } from '@services';
import {
  MenuItem,
  Select,
  SelectProps,
  Box,
  InputLabel,
  Typography,
  FormHelperText,
} from '@mui/material';

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props<T extends FieldValues> extends SelectProps {
  control: Control<T, object>;
  name: Path<T>;
  label: string;
  options: OptionType[];
}

export const SelectFabric = <T extends object>({
  control,
  name,
  label,
  disabled,
  options,
  placeholder,
  required,
  ...props
}: Props<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field, formState: { errors } }) => {
      const withoutValue = (!field.value || !(field.value as string[])?.length) && placeholder;
      const errorText = (get(errors, name) as any)?.message;

      return (
        <Box width="100%" sx={{ pointerEvents: disabled ? 'none' : '' }}>
          <InputLabel htmlFor={name}>
            {label}
            {required && <span>&nbsp;*</span>}
          </InputLabel>
          <Select
            {...field}
            disabled={disabled}
            displayEmpty
            MenuProps={MenuProps}
            renderValue={
              withoutValue
                ? () => <Typography color="text.disabled">{placeholder}</Typography>
                : undefined
            }
            error={!!errorText}
            {...props}
          >
            {options.map(({ value, label: itemLabel, icon }) => (
              <MenuItem value={value} key={value}>
                {icon && icon}
                {itemLabel}
              </MenuItem>
            ))}
          </Select>
          {errorText && <FormHelperText error>{errorText}</FormHelperText>}
        </Box>
      );
    }}
  />
);
