import { FC } from 'react';
import { Typography } from '@mui/material';

import { MemberStatus } from '@services';
import { MEMBER_STATUS_ICONS, MEMBER_STATUS_TEXT } from '@constants';

import { FakeMemberStatusWrap, MemberStatusWrap } from './styles';

export interface MemberStatusChipProps {
  status: MemberStatus;
  text?: string;
  color?: string;
}

export const MemberStatusChip: FC<MemberStatusChipProps> = ({ status, text, color }) => (
  <MemberStatusWrap status={status} color={color}>
    {MEMBER_STATUS_ICONS[status]}
    <Typography fontSize="13px">
      {MEMBER_STATUS_TEXT[status]} {text}
    </Typography>
  </MemberStatusWrap>
);

export const MemberStatusFake: FC<MemberStatusChipProps> = ({ status, text, color }) => (
  <FakeMemberStatusWrap status={status} color={color}>
    {MEMBER_STATUS_ICONS[status]}
    <Typography>
      {MEMBER_STATUS_TEXT[status]} {text}
    </Typography>
  </FakeMemberStatusWrap>
);
