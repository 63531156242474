import { ChangeEvent, FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import pick from 'object.pick';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Axios, queryClient } from '@helpers';
import {
  businessAccountValidation,
  COMPANY_ASSETS_LABEL,
  COMPANY_TYPE_LABEL,
  DATE_FORMAT,
  PAYMENT_STATUS,
  SUBMIT_BUTTON_ID,
} from '@constants';
import { successMessage } from '@services';
import { useFormEqual } from '@hooks';
import { useSubmitButtonState } from '@contexts/SubmitButton';

import { FakeInput, TextField } from '@atoms';
import { Avatar, Box, Divider, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { TransformedBusinessData } from './utils';

export interface FormData {
  companyName: string;
  overrideFormulaCoef: number | null;
  firstName: string;
  lastName: string;
  slots: {
    available: number | string;
    used: number;
  };
  slotsCount: number | string;
}

export const BusinessAccountsDetailsForm: FC<{ data: TransformedBusinessData }> = ({ data }) => {
  const { id } = useParams();
  const { companyName, logo } = data;
  const defaultValues = useMemo(
    () =>
      pick(data, [
        'companyName',
        'price',
        'overrideFormulaCoef',
        'firstName',
        'lastName',
        'slots',
        'slotsCount',
      ]),
    [data],
  );
  const { setDisabled, disabled, setLoading } = useSubmitButtonState();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { isValid },
  } = useForm<FormData>({
    resolver: yupResolver(businessAccountValidation),
    defaultValues: {
      ...defaultValues,
      slots: { ...defaultValues.slots, available: defaultValues.slots.available },
    },
    mode: 'onChange',
    delayError: 700,
  });

  useFormEqual<FormData>({
    setDisabled,
    disabled: !isValid || disabled,
    data: defaultValues,
    reset,
    watch,
  });

  const { mutate: onSubmit, isLoading } = useMutation<unknown, unknown, FormData>(
    (formData) => Axios.put(`/admin/accounts/${data.id}`, formData),
    {
      onSuccess: () => {
        queryClient.refetchQueries(`business-details/${id}`);
        successMessage(`${data.companyName} has been updated!`);
      },
    },
  );

  const finalSubmit = (formData: FormData) =>
    onSubmit({
      ...formData,
    });

  const handleSlotsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue('slots.available', event.target.value);
    setValue('slotsCount', event.target.value);
  };

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  return (
    <Box component="form" width="664px" onSubmit={handleSubmit(finalSubmit)}>
      <Box display="flex" gap="24px" mb="24px">
        <Avatar alt={companyName} sx={{ width: 82, height: 82 }} src={logo}>
          {companyName[0].toUpperCase()}
        </Avatar>
        <TextField control={control} label="Company name" name="companyName" required />
      </Box>
      <Box display="flex" gap="24px" mb="24px" mt="24px">
        <TextField
          label="First name"
          name="firstName"
          placeholder="First User name"
          control={control}
          required
        />
        <TextField
          label="Last name"
          name="lastName"
          placeholder="Last User name"
          control={control}
        />
      </Box>
      <Box display="flex" gap="24px" mb="24px">
        <FakeInput label="Email">{data.email}</FakeInput>
      </Box>
      <Box display="flex" mb="24px" gap="24px">
        <FakeInput label="Account type">
          <Typography color="text.disabled" sx={{ display: 'flex' }}>
            <span>{COMPANY_TYPE_LABEL[data.companyType]}</span>
            {!!data.assetType && (
              <>
                <Divider orientation="vertical" sx={{ height: 'auto', mx: '8px' }} />
                <span>{COMPANY_ASSETS_LABEL[data.assetType]}</span>
              </>
            )}
          </Typography>
        </FakeInput>
        <FakeInput label="Status">
          {PAYMENT_STATUS[data.status].icon}
          <Typography ml="10px">{PAYMENT_STATUS[data.status].label}</Typography>
        </FakeInput>
        <FakeInput label="Join date">{dayjs(data.joinDate).format(DATE_FORMAT)}</FakeInput>
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap="24px" mb="24px">
        <TextField
          control={control}
          label="Slots"
          name="slots.available"
          type="number"
          required
          returnNumber
          onChange={handleSlotsChange}
        />
      </Box>
      <Box display="flex" gap="24px">
        <TextField
          control={control}
          label="Value formula"
          placeholder="Add value formula"
          name="overrideFormulaCoef"
        />
      </Box>
      <LoadingButton style={{ opacity: 0 }} type="submit" id={SUBMIT_BUTTON_ID} />
    </Box>
  );
};
