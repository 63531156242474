import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentType, TableSelectType } from '@services';
import { Table } from '@molecules';

import { getColumns } from './utils';

export const UploadDocumentTable: FC<{ refetch?: () => void }> = (props) => {
  const { assetId } = useParams();
  const columns = useMemo(() => getColumns(), []);

  return (
    <Table<DocumentType>
      // searchPlaceholder="Search for documents name"
      queryName={`upload-documents-${assetId}`}
      columns={columns}
      withoutSearch
      selectedRowType={TableSelectType.Document}
      apiRoute={`/admin/models/${assetId}/model-documents`}
      checkboxSelection
      refetchQueryName={`upload-documents-${assetId}`}
      {...props}
    />
  );
};
