import { FC } from 'react';
import { SelectProps } from '@mui/material';

import { LinkAtSourceInformationAiMatches } from '@services';
import { LINK_AT_SOURCE_INFORMATION_AI_MATCHES } from '@constants';

import { SelectStyled, MenuItemStyled } from './styles';
import { FilterCloseWrap } from './FilterCloseWrap';

export const AIMatchesFilter: FC<{ onClose?: () => void } & SelectProps> = ({
  onClose,
  ...props
}) => (
  <FilterCloseWrap label="AI Matches" onClose={onClose}>
    <SelectStyled placeholder="Select option" {...props}>
      {Object.entries(LinkAtSourceInformationAiMatches).map(([, key]) => (
        <MenuItemStyled key={key} value={key}>
          {LINK_AT_SOURCE_INFORMATION_AI_MATCHES[key]}
        </MenuItemStyled>
      ))}
    </SelectStyled>
  </FilterCloseWrap>
);
