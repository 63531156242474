import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';

import { TableContentCreator } from '@services';
import { getStatus, removedCellClassName } from '@helpers';
import { DATE_FORMAT } from '@constants';

import { Chip } from '@mui/material';
import { InfoCell, UserPaymentStatus } from '@atoms';
import { renderPlanCell, renderManagerCell } from '@molecules/Table/cells';

export const columns: GridColDef<TableContentCreator>[] = [
  {
    field: 'contentCreator',
    headerName: 'Content creator',
    minWidth: 230,
    flex: 2,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { contentCreator, fullName, email, logo } }) => (
      <InfoCell name={contentCreator || fullName} email={email} avatarUrl={logo} />
    ),
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    minWidth: 164,
    cellClassName: removedCellClassName,
    flex: 1,
  },
  {
    field: 'plan',
    headerName: 'Plan',
    minWidth: 80,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: renderPlanCell,
  },
  {
    field: 'status',
    headerName: 'Payment status',
    minWidth: 166,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { status, plan, isRemoved, freeTrialEffectiveDate } }) => {
      const isFreeTrial = freeTrialEffectiveDate
        ? dayjs(freeTrialEffectiveDate).isAfter(dayjs())
        : false;
      const paymentStatus = getStatus(status, plan, isRemoved, isFreeTrial);
      return <UserPaymentStatus status={paymentStatus} />;
    },
  },
  {
    field: 'joinDate',
    headerName: 'Join date',
    minWidth: 110,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { joinDate } }) => dayjs(joinDate).format(DATE_FORMAT),
  },
  {
    field: 'manager',
    headerName: 'Manager',
    minWidth: 164,
    flex: 2,
    cellClassName: removedCellClassName,
    renderCell: renderManagerCell(),
  },
  {
    field: 'assignStatus',
    headerName: 'Status',
    minWidth: 164,
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { manager } }) => (
      <Chip label={manager ? 'Assigned' : 'Unassigned'} color={manager ? 'success' : 'error'} />
    ),
  },
];
