import { FC, useState, MouseEvent, useRef, SyntheticEvent } from 'react';
import { Typography, Stack, Box, Avatar, Menu, MenuItem } from '@mui/material';
import { ReactComponent as PhotoCamera } from '@assets/photo-camera.svg';

import { capitalize } from '@helpers';
import { AvatarWrap } from './styles';

interface UserAvatarProps {
  fullName: string;
  avatarUrl: string | ArrayBuffer;
  role: string;
  isEditing?: boolean;
  onLoad?: (file: File) => void;
  size?: number;
}

export const UserAvatar: FC<UserAvatarProps> = ({
  fullName,
  avatarUrl,
  role,
  isEditing,
  onLoad,
  size = 40,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const onClose = () => setAnchorEl(null);

  const onChangeImage = (e: SyntheticEvent<HTMLInputElement>) => {
    setAnchorEl(null);
    if (e.currentTarget.files?.length) {
      const file = e.currentTarget.files[0];
      if (file && onLoad) {
        onLoad(file);
      }
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <AvatarWrap
        isEditing={isEditing}
        onClick={(event: MouseEvent<HTMLDivElement>) =>
          isEditing && setAnchorEl(event.currentTarget)
        }
      >
        <Avatar
          alt={fullName}
          sx={{ width: size, height: size }}
          sizes="large"
          src={avatarUrl as string}
        />
        <PhotoCamera className="photo" />
      </AvatarWrap>
      <Menu anchorEl={anchorEl} open={menuOpen} onClose={onClose}>
        <MenuItem onClick={() => fileInputRef?.current?.click()}>Upload new</MenuItem>
        {avatarUrl && (
          <MenuItem>
            <Box
              color="error.main"
              onClick={() => {
                setAnchorEl(null);
              }}
            >
              Remove
            </Box>
          </MenuItem>
        )}
      </Menu>
      <input
        onChange={onChangeImage}
        ref={fileInputRef}
        accept="image/*"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
      />
      <Stack ml="16px" mr="6px" direction="column" minWidth="122px">
        <Typography variant="body2">{fullName}</Typography>
        <Typography variant="body2" color="text.secondary">
          {capitalize(role)}
        </Typography>
      </Stack>
    </Box>
  );
};
