import { FC, ReactNode, createContext, useContext, useState, useMemo, Dispatch } from 'react';

export interface ISubmitButtonContext {
  children?: ReactNode;
}

interface SubmitButtonState {
  disabled: boolean;
  setDisabled: Dispatch<boolean>;
  loading: boolean;
  setLoading: Dispatch<boolean>;
}

const SubmitButtonCtx = createContext<SubmitButtonState>({
  disabled: true,
  setDisabled: (disabled: boolean) => disabled,
  loading: false,
  setLoading: (loading: boolean) => loading,
});

export const SubmitButtonContext: FC<ISubmitButtonContext> = ({ children }) => {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const contextValue = useMemo(
    () => ({ disabled, setDisabled, loading, setLoading }),
    [disabled, loading],
  );

  return <SubmitButtonCtx.Provider value={contextValue}>{children}</SubmitButtonCtx.Provider>;
};

export const useSubmitButtonState = (): SubmitButtonState => {
  const ctx = useContext<SubmitButtonState>(SubmitButtonCtx);
  if (ctx === undefined) {
    throw new Error('useSubmitButtonState must be used within a Provider');
  }
  return ctx;
};
