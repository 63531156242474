import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BACK_BUTTON_ID } from '@constants';
import { Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface Props {
  name: string;
  paramsId: string;
  route: string;
}

export const AccountHeader: FC<Props> = ({ name, paramsId, route, children }) => {
  const navigate = useNavigate();
  const [params, setParams] = useState('');
  const [backTo, setBackTo] = useState(route);

  useEffect(() => {
    const storedParams = sessionStorage.getItem(paramsId);
    if (storedParams) {
      setParams(storedParams);
    }
    const storedRoute = sessionStorage.getItem(`${paramsId}-back-to`);
    if (storedRoute) {
      setBackTo(storedRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBack = () => {
    navigate(`${backTo}${params}`);
    sessionStorage.removeItem(`${paramsId}-back-to`);
  };

  return (
    <Box mb="16px" display="flex" alignItems="center" justifyContent="space-between">
      <Box mb="16px" display="flex" alignItems="center">
        <IconButton onClick={onBack} id={BACK_BUTTON_ID}>
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography ml="24px" variant="h4">
          {name}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};
