import { FC } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import { ROUTES } from '@constants';
import { Axios } from '@helpers';
import { AssetsType, CCAssetDetailsType, ScanSettingItemType, successMessage } from '@services';
import { useConfirmationModal } from '@hooks';

import { Box, Button } from '@mui/material';
import { Loader } from '@atoms';
import { AccountHeader, Tabs } from '@molecules';
import { ConfirmModal } from '@organisms';
import { InnerView } from '@templates';
import Helmet from 'react-helmet';

const links = [
  { label: 'Account details', to: ROUTES.ModelDetails },
  { label: 'Links management', to: ROUTES.LinksManagement },
  { label: 'Scan Settings', to: ROUTES.ScanSettings },
  { label: 'Documents', to: ROUTES.UploadDocument },
  { label: 'Whitelist', to: ROUTES.Whitelist },
  { label: 'Notifications', to: ROUTES.Notification },
];

const PARAMS_ID = 'content-creator-table';

export const ContentCreatorTabs: FC<{ hideTabs?: boolean }> = ({ hideTabs }) => {
  const { id, assetId } = useParams();
  const { pathname } = useLocation();
  const isScanTab = pathname.includes(links[2].to);

  const { data, isLoading } = useQuery<unknown, unknown, CCAssetDetailsType>(
    `content-creator-data-asset/${id}`,
    () => Axios.get(`/admin/accounts/${id}`).then((res) => res.data),
  );
  const { data: scanData } = useQuery<unknown, unknown, ScanSettingItemType>(
    `scan-management-details/${id}`,
    () => {
      if (assetId) {
        return Axios.get(`/admin/companies/${id}/assets/${assetId}/scan-settings`).then(
          (res) => res.data,
        );
      }
      return {};
    },
  );

  const isModel = data?.assetType === AssetsType.Models;

  const { mutate: startScan } = useMutation<void, void, void>(
    () => Axios.post(`/admin/companies/${id}/assets/${assetId}/scan`),
    {
      onSuccess: () => {
        successMessage('The scan has been started!\nIt will take up to 5 minutes!');
      },
    },
  );

  const [open, onOpen, onCancel, onSubmit] = useConfirmationModal(false, () => startScan());

  return (
    <Loader isLoading={isLoading}>
      <>
        {data?.companyName && (
          <Helmet>
            <title>{data?.companyName}</title>
          </Helmet>
        )}
        <AccountHeader
          name={data?.companyName || ''}
          paramsId={PARAMS_ID}
          route={`/${ROUTES.Accounts}/${ROUTES.ContentCreators}`}
        >
          {isModel && (
            <Button
              variant="contained"
              sx={{ px: '50px' }}
              onClick={onOpen}
              disabled={!data?.canStartScan}
            >
              Start Personal Scan
            </Button>
          )}
        </AccountHeader>
        <InnerView autoHeight>
          {!hideTabs && (
            <Box mt="20px" mb="24px">
              <Tabs links={links} modified={isScanTab ? scanData?.updatedAt : data?.lastModified} />
            </Box>
          )}
          <Outlet />
        </InnerView>
        <ConfirmModal
          open={open}
          onCancel={onCancel}
          onSubmit={onSubmit}
          title="Are you sure you want to launch the scan?"
          submitText="Yes, Launch"
        />
      </>
    </Loader>
  );
};
