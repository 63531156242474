import { FC, ReactNode } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export interface BaseIconProps extends SvgIconProps {
  children: ReactNode;
  viewBox?: string;
}

export const BaseIcon: FC<BaseIconProps> = ({ width, height, children, viewBox, ...props }) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox={viewBox || (width && height) ? `0 0 ${width} ${height}` : undefined}
    {...props}
    sx={{
      ...props.sx,
      width: `${width}px`,
      height: `${height}px`,
    }}
  >
    {children}
  </SvgIcon>
);
