import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import pick from 'object.pick';
import dayjs from 'dayjs';
import { useMutation, useQueryClient } from 'react-query';
import SaveIcon from '@mui/icons-material/Save';

import {
  COMPANY_TYPE_LABEL,
  contentCreatorValidation,
  DATE_FORMAT,
  PAYMENT_STATUS,
  CREATOR_PLANS_OPTIONS,
  COMPANY_ASSETS_LABEL,
} from '@constants';
import { CCAssetDetailsType, Plans, successMessage } from '@services';
import { Axios, getStatus } from '@helpers';
import { useFormEqual } from '@hooks';
import { yupResolver } from '@hookform/resolvers/yup';

import { Avatar, Box, Divider, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FakeInput, TextField } from '@atoms';
import { SelectFabric } from '@molecules';

export interface FormData {
  companyName: string;
  subscriptionPlan: Plans;
  firstName: string;
  lastName: string;
  transparencyReportLink?: string | null;
  overrideFormulaCoef: number | null;
}

export const CCAssetForm: FC<{ data: CCAssetDetailsType }> = ({ data }) => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const defaultValues = useMemo(
    () => ({
      ...pick(data, ['companyName', 'firstName', 'lastName', 'overrideFormulaCoef']),
      transparencyReportLink: data?.transparencyReportLink || '',
      subscriptionPlan: data.plan,
    }),
    [data],
  );
  const { isRemoved, companyName, logo } = data;
  const [disabled, setDisabled] = useState(true);
  const { handleSubmit, control, watch, reset } = useForm<FormData>({
    resolver: yupResolver(contentCreatorValidation),
    defaultValues,
    mode: 'onChange',
    delayError: 700,
  });

  useFormEqual<FormData>({
    setDisabled,
    disabled,
    data: defaultValues,
    reset,
    watch,
  });

  const { mutate: onSubmit, isLoading } = useMutation<unknown, unknown, FormData>(
    (formData) => Axios.put(`/admin/accounts/${data.id}`, formData),
    {
      onSuccess: () => {
        successMessage(`${companyName} has been updated!`);
        queryClient.refetchQueries(`content-creator-asset-data/${id}`);
      },
    },
  );

  const finalSubmit = (formData: FormData) =>
    onSubmit({
      ...formData,
      transparencyReportLink: formData.transparencyReportLink || null,
    });

  const isFreeTrial = data?.freeTrialEffectiveDate
    ? dayjs(data?.freeTrialEffectiveDate).isAfter(dayjs())
    : false;
  const status = getStatus(data.status, data.plan, data.isRemoved, isFreeTrial);

  return (
    <Box>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        component="form"
        onSubmit={handleSubmit(finalSubmit)}
      >
        <Typography variant="h5" mb="8px">
          General information
        </Typography>
        <LoadingButton
          variant="contained"
          type="submit"
          disabled={disabled}
          startIcon={<SaveIcon />}
          loading={isLoading}
        >
          Save changes
        </LoadingButton>
      </Box>
      <Box width="664px">
        <Typography variant="body2" mb="16px">
          Below is general information about the user and their account.
        </Typography>
        <Box display="flex" gap="24px" mb="24px" mt="24px">
          <Avatar alt={companyName} sx={{ width: 82, height: 82 }} src={logo}>
            {companyName[0].toUpperCase()}
          </Avatar>
          <TextField
            label="Content creator name"
            control={control}
            name="companyName"
            disabled={isRemoved}
            placeholder="Content creator name or pseudonym"
            required
          />
        </Box>
        <Box display="flex" gap="24px" mb="24px" mt="24px">
          <TextField
            label="First name"
            name="firstName"
            placeholder="First User name"
            control={control}
            disabled={isRemoved}
            required
          />
          <TextField
            label="Last name"
            name="lastName"
            placeholder="Last User name"
            control={control}
            disabled={isRemoved}
          />
        </Box>
        <FakeInput label="Email">{data.email}</FakeInput>
        <Box display="flex" gap="24px" mb="24px" mt="24px">
          <TextField
            label="Transparency link report"
            control={control}
            name="transparencyReportLink"
            placeholder="Add link"
            disabled={isRemoved}
          />
          <TextField
            control={control}
            label="Value formula"
            placeholder="Add value formula"
            name="overrideFormulaCoef"
            disabled={isRemoved}
          />
        </Box>
        <Box display="flex" gap="24px" mt="24px" mb="40px">
          <FakeInput label="Account type">
            <Typography color="text.disabled" sx={{ display: 'flex' }}>
              <span>{COMPANY_TYPE_LABEL[data.companyType]}</span>
              {!!data.assetType && (
                <>
                  <Divider orientation="vertical" sx={{ height: 'auto', mx: '8px' }} />
                  <span>{COMPANY_ASSETS_LABEL[data.assetType]}</span>
                </>
              )}
            </Typography>
          </FakeInput>
          <FakeInput label="Join date">{dayjs(data.joinDate).format(DATE_FORMAT)}</FakeInput>
        </Box>
        <Typography variant="h5" mb="8px">
          Billing plan
        </Typography>
        <Typography variant="body2" mb="16px">
          Here is you can find all information about users&apos; billing plans and plan due dates.
        </Typography>
        <Box display="flex" gap="24px" mt="24px">
          <SelectFabric
            label="Plan type"
            control={control}
            name="subscriptionPlan"
            options={CREATOR_PLANS_OPTIONS}
            disabled
          />
          <FakeInput label="Status">
            {PAYMENT_STATUS[status].icon}
            <Typography ml="10px">{PAYMENT_STATUS[status].label}</Typography>
          </FakeInput>
          {data.paymentInfo?.nextPayment?.date && (
            <FakeInput label="Next billing date">
              {dayjs(data.paymentInfo?.nextPayment?.date).format(DATE_FORMAT)}
            </FakeInput>
          )}
        </Box>
      </Box>
    </Box>
  );
};
