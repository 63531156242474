import { ReactNode } from 'react';
import { FilterItem, Plans } from '@services';
import { SelectChangeEvent } from '@mui/material';
import { PlanFilter } from '@molecules';

export const planFilter = (isBusiness?: boolean, allType?: boolean): FilterItem => ({
  name: 'Plan',
  key: 'subscriptionPlan',
  getFilter: (onChange, onClose, setFilters, filter): ReactNode => (
    <PlanFilter
      key="subscriptionPlan"
      name="plan"
      value={filter.subscriptionPlan as Plans}
      onChange={(event: SelectChangeEvent<unknown>) =>
        setFilters({ ...filter, subscriptionPlan: event.target.value as string })
      }
      onClose={onClose}
      isBusiness={isBusiness}
      allType={allType}
    />
  ),
});
