/* eslint-disable react/no-unescaped-entities */
import { FC } from 'react';

import { Button, Modal } from '@atoms';
import { Box, Typography } from '@mui/material';
import { ReactComponent as MarkEmailRead } from '@assets/mark-email-read.svg';

interface ChangePasswordConfirmModalProps {
  onClose: () => void;
  open: boolean;
}

export const ChangePasswordConfirmModal: FC<ChangePasswordConfirmModalProps> = ({
  onClose,
  open,
}) => (
  <Modal open={open} onClose={onClose}>
    <Box width="380px" margin="auto" display="flex" alignItems="center" flexDirection="column">
      <MarkEmailRead />
      <Typography mb="16px" mt="8px" variant="h4" textAlign="center">
        Your change password email is on the way!
      </Typography>
      <Typography mb="48px" variant="body1" textAlign="center">
        Go check your inbox, and finish changing your Ceartas password.
      </Typography>
      <Button onClick={onClose} variant="contained" sx={{ width: '180px' }}>
        Got it
      </Button>
      <Typography mt="24px" fontSize="10px" color="text.secondary" textAlign="center">
        Didn't get an email? Check your Ceartas email and try again. If this does not work, contact
        your administrator.
      </Typography>
    </Box>
  </Modal>
);
