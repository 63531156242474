/* eslint-disable no-useless-escape */
import * as yup from 'yup';
import validUrl from 'valid-url';
import { StringSchema } from 'yup';
import { ObjectShape } from 'yup/lib/object';

declare module 'yup' {
  interface StringSchema {
    linksValidation(errorMessage: string): StringSchema;
  }
}

yup.addMethod<StringSchema>(yup.string, 'linksValidation', function (errorMessage) {
  return this.test('test-valid-link', errorMessage, (value) =>
    value ? !!validUrl.isWebUri(value as string) : true,
  );
});

export const loginValidation = yup
  .object({
    email: yup
      .string()
      .email('Enter the correct email!')
      .required('Please enter your email address!'),
    password: yup.string().required(),
  })
  .required();

export const inviteValidation = yup
  .object({
    firstName: yup.string().label('First name').trim().min(2).required('First name can’t be empty'),
    lastName: yup.string().label('Last name').trim().min(2).required('Last name can’t be empty'),
    email: yup
      .string()
      .email('Enter the correct email!')
      .required('Please enter your email address!'),
  })
  .required();

export const updateUserValidation = yup
  .object({
    firstName: yup.string().trim().required('First name can’t be empty'),
    lastName: yup.string().trim().required('Last name can’t be empty'),
    role: yup.string().required(),
    status: yup.string().required(),
  })
  .required();

export const resetPasswordValidation = yup
  .object({
    email: yup
      .string()
      .email('Enter the correct email!')
      .required('Please enter your email address!'),
  })
  .required();

export const accountValidation = yup
  .object({
    firstName: yup.string().trim().required('First name can’t be empty'),
    lastName: yup.string().trim().required('Last name can’t be empty'),
  })
  .required();

export const contentCreatorValidation = yup
  .object({
    firstName: yup.string().trim().required('First name can’t be empty'),
    companyName: yup.string().trim().required('Content creator name can’t be empty'),
    lastName: yup.string().trim(),
    transparencyReportLink: yup
      .string()
      .nullable(true)
      .linksValidation('Link must be valid')
      .transform((_, val) => val || null),
    subscriptionPlan: yup.string().required(),
    overrideFormulaCoef: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .typeError('Value must be a number'),
  })
  .required();

export const businessAccountValidation = yup
  .object({
    companyName: yup.string().label('Company name').trim().required(),
    firstName: yup.string().label('First name').trim().required(),
    lastName: yup.string().label('Last name').trim(),
    overrideFormulaCoef: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .typeError('Value must be a number'),
    slots: yup.object().shape({
      used: yup.number().required('Used is required'),
      available: yup
        .number()
        .transform((value, originalValue) => (`${originalValue}`.trim() === '' ? 0 : +value))
        .nullable()
        .required('Available slots is required')
        .typeError('Available slots must be a number')
        .min(1, 'Available slots must be greater than 0')
        .when('used', (used, schema) =>
          schema.test({
            name: 'is-greater-than-available',
            message: `Available slots must be more or equal to ${used} slots`,
            test: (available: string | number) => used <= +available,
          }),
        ),
    }),
  })
  .required();

export const getAssetAccountValidation = () =>
  yup
    .object({
      username: yup.string().label('Username').trim().required().min(2),
      transparencyReportLink: yup
        .string()
        .nullable(true)
        .linksValidation('Link must be valid')
        .transform((_, val) => val || null),
      properties: yup.object().nullable(true),
    })
    .required();

export const linksValidation = yup.object({
  links: yup.string(),
});

export const memberAccountValidation = yup
  .object({
    firstName: yup
      .string()
      .label('First name')
      .nullable()
      .trim()
      .min(2)
      .required('First name can’t be empty'),
    lastName: yup
      .string()
      .label('Last name')
      .nullable()
      .trim()
      .min(2)
      .required('Last name can’t be empty'),
    status: yup.string().nullable().trim().required('Status name can’t be empty'),
    role: yup.string().required(),
  })
  .required();

export const generalLinkInfoValidation = yup
  .object({
    overrideGoogleWeb: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null)),
    overrideAtSource: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null)),
  })
  .required();

export const createAccountValidation = yup
  .object({
    companyType: yup.string().required(),
    firstName: yup.string().label('First name').trim().min(2).required('First name can’t be empty'),
    lastName: yup.string().label('Last name').trim().min(2).required('Last name can’t be empty'),
    companyName: yup.string().label('Company name').trim().min(2),
    email: yup
      .string()
      .label('email')
      .email('Enter the correct email!')
      .required('Please enter your email address!'),
    slots: yup
      .number()
      .label('Slots')
      .transform((_, val) => (val ? Number(val) : null))
      .typeError('This value should be an integer from 1')
      .min(1)
      .required('Slots can’t be empty'),
    plan: yup.string().required('Plan is required'),
  })
  .required();

export const generatePayLinkValidation = yup
  .object({
    plan: yup.string().required('Plan is required'),
    slots: yup
      .number()
      .label('Slots')
      .transform((_, val) => (val ? Number(val) : null))
      .typeError('This value should be an integer from 1')
      .min(1)
      .required('Slots can’t be empty'),
    pricePerSlot: yup
      .number()
      .label('Price')
      .transform((_, val) => (val ? Number(val) : null))
      .typeError('This value should be an integer from 1')
      .min(1)
      .required('Price can’t be empty'),
  })
  .required();

export const scanManagementDetailsValidation = yup
  .object({
    keywords: yup
      .array()
      .label('Keywords')
      .required()
      .of(yup.string().min(2).max(100))
      .min(1)
      .max(500)
      .transform((_, keys) => keys.map((key: string) => key.toLowerCase())),
    scanSources: yup.array().label('Scan type').required().of(yup.string()).min(1),
    pages: yup
      .number()
      .label('Pages')
      .required()
      .transform((_, val) => (val ? Number(val) : null))
      .typeError('This value should be an integer from 1 to 100')
      .min(1, 'This value should be an integer from 1 to 100')
      .max(100, 'This value should be an integer from 1 to 100')
      .positive('This value should be an integer from 1 to 100')
      .integer('This value should be an integer from 1 to 100'),
    scanFrequencyDays: yup
      .number()
      .label('Quantity')
      .required()
      .transform((_, val) => (val ? Number(val) : null))
      .typeError('This value should be an integer from 1 to 500')
      .min(1, 'This value should be an integer from 1 to 500')
      .max(500, 'This value should be an integer from 1 to 500')
      .positive('This value should be an integer from 1 to 500')
      .integer('This value should be an integer from 1 to 500'),
  })
  .required();

export const scanSettingsDetailsValidation = yup
  .object({
    keywords: yup
      .array()
      .label('Keywords')
      .of(yup.string().min(2).max(100))
      .max(500)
      .transform((_, keys) => keys.map((key: string) => key.toLowerCase())),
    usernames: yup
      .array()
      .label('Usernames')
      .of(yup.string().min(2).max(100))
      .max(500)
      .transform((_, keys) => keys.map((key: string) => key.replace(/\s/, '_').toLowerCase())),
    scanSources: yup.array().label('Scan type').of(yup.string()),
    filterNames: yup
      .array()
      .label('Filter Names')
      .of(yup.string().min(2).max(100))
      .max(500)
      .transform((_, keys) => keys.map((key: string) => key.toLowerCase())),
    filterRemovals: yup
      .array()
      .label('Filter Removals')
      .of(yup.string().min(2).max(100))
      .max(500)
      .transform((_, keys) => keys.map((key: string) => key.toLowerCase())),
    pages: yup
      .number()
      .label('Pages')
      .transform((_, val) => (val ? Number(val) : null))
      .typeError('This value should be an integer from 1 to 100')
      .nullable(true)
      .min(1, 'This value should be an integer from 1 to 100')
      .max(100, 'This value should be an integer from 1 to 100')
      .positive('This value should be an integer from 1 to 100')
      .integer('This value should be an integer from 1 to 100'),
    scanFrequencyDays: yup
      .number()
      .label('Quantity')
      .transform((_, val) => (val ? Number(val) : null))
      .typeError('This value should be an integer from 1 to 500')
      .nullable(true)
      .min(1, 'This value should be an integer from 1 to 500')
      .max(500, 'This value should be an integer from 1 to 500')
      .positive('This value should be an integer from 1 to 500')
      .integer('This value should be an integer from 1 to 500'),
    aiFeatures: yup
      .object()
      .label('AI Features')
      .shape({
        faceMatch: yup.boolean(),
        nsfw: yup.boolean(),
        objectDetect: yup.boolean(),
        ocrDetect: yup.boolean(),
        people: yup.boolean(),
        watermark: yup.boolean(),
      })
      .optional(),
  })
  .required();

export const editLinkValidation = yup
  .object({
    link: yup.string().label('Domain').min(2).required(),
  })
  .required();

export const getSchemaAddAsset = (validationObject: ObjectShape) =>
  yup.object({
    username: yup.string().min(2),
    properties: yup.object(validationObject),
  });

export const getSchemaEditContract = yup
  .object({
    firstName: yup.string().label('First name').trim().required('First name can’t be empty'),
    lastName: yup.string().label('Last name').trim().required('Last name can’t be empty'),
    updateProfile: yup.boolean().optional(),
  })
  .required();
