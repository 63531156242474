export enum InputTypes {
  Input = 'input',
  Date = 'date',
  Multiselect = 'multiselect',
  Radio = 'radio',
  AddSocialMediaAccounts = 'add-account',
}

export enum BookAssetProperties {
  AuthorName = 'bookAuthorName',
  Genre = 'bookGenre',
  Publisher = 'bookPublisher',
  DateOfPublishing = 'bookDateOfPublishing',
}
export enum GameAssetProperties {
  Publisher = 'gamePublisher',
  Distributor = 'gameDistributor',
}
export enum NFTAssetProperties {
  Id = 'nftId',
  Creator = 'nftCreator',
  OriginalStore = 'nftOriginalStore',
}
export enum TVAssetProperties {
  Publisher = 'tvPublisher',
  StudioName = 'tvStudioName',
  ReleaseYear = 'tvReleaseYear',
}
export enum MusicAssetProperties {
  Label = 'musicLabel',
  AlbumTitle = 'musicAlbumTitle',
  SongTitle = 'musicSongTitle',
}
export enum SoftwareAssetProperties {
  Type = 'softwareType',
  Creator = 'softwareCreator',
  Version = 'softwareVersion',
  License = 'softwareLicense',
}
export enum MovieAssetProperties {
  Director = 'movieDirector',
  Producer = 'movieProducer',
  Released = 'movieReleased',
}
export enum ModelAssetProperties {
  Plan = 'plan',
  SocialMedia = 'socialMedia',
}

export enum SocialMedia {
  Fansly = 'fansly',
  Fanfix = 'fanfix',
  Fanvue = 'fanvue',
  Twitter = 'twitter',
  Reddit = 'reddit',
  TikTok = 'tiktok',
  Instagram = 'instagram',
  OnlyFans = 'onlyfans',
  Passes = 'passes',
  Patreon = 'patreon',
  Nudiez = 'nudiez',
  Chaturbate = 'chaturbate',
  Other = 'other',
}

export type SocialMediaAccount = {
  id: string;
  title: string;
  properties?: { [key: string]: string | string[] };
  imageUrl?: string | null;
  type?: SocialMedia;
  redirectLink?: string;
};
