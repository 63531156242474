import React from 'react';
import type { MuiChipsInputChipProps } from './types';
import { KEYBOARD_KEY } from './utils';
import { ChipStyled } from './styled';

type ChipProps = MuiChipsInputChipProps;

export const EditableChip = (props: ChipProps) => {
  const {
    className,
    index,
    onDelete,
    disabled,
    onEdit,
    isEditing,
    disableEdition,
    isError,
    ...restChipProps
  } = props;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === KEYBOARD_KEY.enter) {
      onDelete(index);
    }
  };

  const handleDelete = (event: MouseEvent) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();
    onDelete(index);
  };

  const handleDoubleClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    // Return if click on a svg icon
    if (target.textContent !== restChipProps.label) {
      return;
    }
    if (!disabled) {
      onEdit(index);
    }
  };

  return (
    <ChipStyled
      className={`MuiChipsInput-Chip ${isEditing ? 'MuiChipsInput-Chip-Editing' : ''} ${
        isError ? 'error' : ''
      } ${className || ''}`}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      onDoubleClick={disableEdition ? undefined : handleDoubleClick}
      tabIndex={disabled ? -1 : 0}
      aria-disabled={disabled}
      onDelete={disabled ? undefined : handleDelete}
      {...restChipProps}
    />
  );
};
