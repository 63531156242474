import { Box, Link, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export const renderMarkTextCell = (value: string) => (
  <Box>
    <CustomWidthTooltip
      placement="top-start"
      title={<Highlighter searchWords={['']} textToHighlight={value} />}
    >
      <Link className="ellipsis" href={value} target="_blank">
        <Highlighter searchWords={['']} textToHighlight={value} />
      </Link>
    </CustomWidthTooltip>
  </Box>
);
