import { LinkType, SourceType } from '@services';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { DATE_FORMAT, SOURCE_STATUS_NAME } from '@constants';
import { LinkPreview } from '@atoms';
import { renderMarkTextCell, renderTagsCell } from '@molecules/Table/cells';

export const columns: GridColDef<LinkType>[] = [
  {
    field: 'url',
    headerName: 'Link',
    minWidth: 250,
    flex: 1,
    sortable: false,
    renderCell: ({ value }) => renderMarkTextCell(value),
  },
  {
    field: 'webpageStats',
    headerName: 'Tags',
    align: 'left',
    renderCell: ({ value }) => {
      if (value) {
        return renderTagsCell(value);
      }
      return null;
    },
  },
  {
    field: 'sourceType',
    headerName: 'Source type',
    width: 120,
    headerAlign: 'left',
    sortable: false,
    align: 'left',
    renderCell: ({ value }) => SOURCE_STATUS_NAME[value as SourceType],
  },
  {
    field: 'updatedAt',
    headerName: 'Date of addition',
    width: 150,
    headerAlign: 'left',
    align: 'left',
    sortable: false,
    renderCell: ({ value }) => dayjs(value).format(DATE_FORMAT),
  },
  {
    field: 'preview',
    headerName: '',
    width: 50,
    headerAlign: 'right',
    align: 'right',
    renderCell: ({ value }) => <LinkPreview src={value} color="secondary" />,
    sortable: false,
  },
];

export const columnsWithTags: GridColDef<LinkType>[] = [
  {
    field: 'url',
    headerName: 'Link',
    flex: 1,
    minWidth: 250,
    sortable: false,
    renderCell: ({ value }) => renderMarkTextCell(value),
  },
  {
    field: 'atSourceInformation',
    headerName: 'Tags',
    align: 'left',
    renderCell: ({ value }) => {
      if (value?.aiMatches) {
        return renderTagsCell(value.aiMatches);
      }
      return null;
    },
  },
  {
    field: 'sourceType',
    headerName: 'Source type',
    width: 120,
    headerAlign: 'left',
    sortable: false,
    align: 'left',
    renderCell: ({ value }) => SOURCE_STATUS_NAME[value as SourceType],
  },
  {
    field: 'updatedAt',
    headerName: 'Date of addition',
    width: 150,
    headerAlign: 'left',
    align: 'left',
    sortable: false,
    renderCell: ({ value }) => dayjs(value).format(DATE_FORMAT),
  },
  {
    field: 'preview',
    headerName: '',
    width: 50,
    headerAlign: 'right',
    align: 'right',
    renderCell: ({ value }) => <LinkPreview src={value} color="secondary" />,
    sortable: false,
  },
];

export function isStatusRaw(): boolean {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('status') === 'raw';
}
