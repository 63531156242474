import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import { Axios } from '@helpers';
import { successMessage } from '@services';
import { useConfirmationModal } from '@hooks';
import DeleteIcon from '@mui/icons-material/Delete';

import { Button, SelectProps } from '@mui/material';
import { ConfirmModal } from '@organisms/Modals';

interface Props extends SelectProps {
  links: string[];
  refetch: () => void;
}

export const LinkDeleteSelected: FC<Props> = ({ links, refetch }) => {
  const { assetId } = useParams();

  const { mutate: deleteLinks } = useMutation<{ links: string[] }, unknown, { links: string[] }>(
    (data) => Axios.delete(`/admin/models/${assetId}/links`, { data }),
    {
      onSuccess: () => {
        successMessage('Links has been removed!');
        refetch();
      },
    },
  );

  const handleOnChange = () => {
    deleteLinks({ links });
  };

  const [open, onOpen, onCancel, onSubmit] = useConfirmationModal(false, handleOnChange);

  return (
    <>
      <Button
        onClick={onOpen}
        color="error"
        sx={{ textTransform: 'uppercase' }}
        startIcon={<DeleteIcon fontSize="small" />}
      >
        Delete
      </Button>
      <ConfirmModal
        open={open}
        onCancel={onCancel}
        onSubmit={onSubmit}
        title="Are you sure you want to delete the selected links?"
        submitText="Yes, delete"
      />
    </>
  );
};
