import { Box, ButtonGroup, Link, styled } from '@mui/material';
import styledc from 'styled-components';

export const BtnGroup = styled(ButtonGroup)`
  > button {
    border-radius: inherit;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const ContainerTemplates = styled(Box)`
  display: grid;
  padding-top: 32px;
  padding-bottom: 32px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 16px;
`;

export const EmptyTemplate = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > p {
    font-weight: 700;
    font-size: 24px;
  }
`;

export const PlusTemplate = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed black;
  border-radius: 4px;
  opacity: 0.2;
  transition: all 0.2s ease;
  cursor: pointer;
  height: 160px;
  color: black;

  &:hover {
    opacity: 1;
  }
`;

export const CardTemplate = styled(Link)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: 160px;
  background-color: white;
  border-radius: 4px;
  border: 1px dashed #00000000;
  color: black;
  text-decoration: none;

  &:hover {
    border-color: #44aefb;

    > div:nth-child(2) {
      opacity: 1;
    }
  }

  > div:first-child {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px 0px 24px 28px;

    > h1 {
      margin-top: 0;
      margin-bottom: auto;
      font-size: 1.25rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > p {
      margin: 0;
      display: flex;
      align-items: center;
      gap: 8px;

      &:first-child {
        padding-top: 16px;
      }
    }
  }

  > div:nth-child(2) {
    opacity: 0;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    padding: 16px 8px 16px 0px;
  }

  &.active {
    border-color: #44aefb;
  }
`;

export const TagSubmitter = styledc.span`
  font-size: 14px;
  line-height: 20px;
  padding: 2px 7px;
  margin-right: 8px;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  display: inline-block;
  border-radius: 4px;
`;

export const NoteModal = styled(Box)`
  padding: 12px;
  border-radius: 8px;
  background-color: #d3d3d31a;
  border: 1px solid #d3d3d34d;

  > h6 {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
  }

  li {
    font-size: 14px;
    margin-bottom: 6px;
  }
`;
