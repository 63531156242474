import { FC, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import isEqual from 'lodash.isequal';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReportIcon from '@mui/icons-material/Report';

import {
  BUSINESS_PLANS_OPTIONS,
  DATE_FORMAT,
  generatePayLinkValidation,
  PLANS_LABEL,
} from '@constants';
import { Axios } from '@helpers';
import { CreatorPaymentStatus, PaymentStatus, Plans, successMessage } from '@services';
import { useConfirmationModal, useModalState } from '@hooks';
import { useProfile } from '@hooks/useProfile';

import { Box, Divider, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@atoms';
import { SelectFabric } from '@molecules';
import { ConfirmModal, CopyBillingLinkModal } from '@organisms';

import { Pause, PlayArrow } from '@mui/icons-material';
import { toFixed, TransformedBusinessData } from './utils';

interface FormData {
  plan: Plans;
  slots: number;
  pricePerSlot: number | string;
}

export const BusinessBillingPlanForm: FC<{
  data: TransformedBusinessData;
  refetch: () => void;
}> = ({
  data: { companyName, plan, paymentInfo, nextPlanInformation, planInformation, status },
  refetch,
}) => {
  const { id } = useParams();
  const [open, , onOpen, onClose] = useModalState(false);
  const [link, setLink] = useState('');
  const { isAdmin } = useProfile();
  const disabledForm = !isAdmin;
  const hasPayment = !!paymentInfo;

  const queryClient = useQueryClient();

  const currentPlan = plan === 'free' ? undefined : plan;
  const currentSlots = planInformation?.quantity || 0;
  const currentPrice = planInformation?.recurringPrice.USD
    ? toFixed(Number(planInformation.recurringPrice.USD), currentSlots)
    : 0;

  const nextPlan = nextPlanInformation?.name || currentPlan;
  const nextSlots = nextPlanInformation?.quantity || currentSlots;
  const nextPrice = nextPlanInformation?.recurringPrice.USD || currentPrice;

  const shouldShowSlotsAlert =
    nextPlan !== currentPlan || nextSlots !== currentSlots || Number(nextPrice) !== currentPrice;

  const defaultValues = useMemo(
    () => ({
      plan: currentPlan,
      slots: currentSlots,
      pricePerSlot: currentPrice,
    }),
    [currentPlan, currentSlots, currentPrice],
  );

  // eslint-disable-next-line
  const { handleSubmit, control, watch, reset } = useForm<FormData>({
    resolver: yupResolver(generatePayLinkValidation),
    defaultValues,
    mode: 'onChange',
    delayError: 700,
  });

  const values = watch();
  // eslint-disable-next-line
  const isDirty = !isEqual(values, defaultValues);

  const { mutate: generatePayLink, isLoading: isGenerateLoading } = useMutation<
    { url: string },
    unknown,
    FormData
  >((data) => Axios.post(`/admin/accounts/business/${id}/pay-link`, data).then((res) => res.data), {
    onSuccess: ({ url }) => {
      setLink(url);
      onOpen();
      successMessage('Link has generated!');
    },
  });

  const { mutate: updatePayment, isLoading: isUpdateLoading } = useMutation<
    unknown,
    unknown,
    FormData
  >((data) => Axios.post(`/admin/accounts/business/${id}/plan-details`, data), {
    onSuccess: () => {
      reset(defaultValues);
      refetch();
      successMessage('Payment plan has been updated!');
    },
  });

  const updatePaymentStatusMutation = useMutation<unknown, unknown, { status: PaymentStatus }>(
    (requestBody) => Axios.put(`/admin/accounts/${id}/payment-status`, requestBody),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(`business-details/${id}`);
        successMessage(
          `${companyName}'s payment has been ${
            status === CreatorPaymentStatus.Paused ? 'Resumed' : 'Paused'
          }!`,
        );
      },
    },
  );

  const onFinalSubmit = (data: FormData) =>
    hasPayment ? updatePayment(data) : generatePayLink(data);

  const [openChangePlan, onOpenChangePlan, onChangePlanCancel, onChangePlanSubmit] =
    useConfirmationModal(false, () => handleSubmit(onFinalSubmit)());

  return (
    <Box component="form" width="664px" onSubmit={handleSubmit(onFinalSubmit)}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" mb="8px">
          Billing plan
        </Typography>

        <Typography variant="button" color="#DDBA00">
          Next billing date:{' '}
          {paymentInfo?.nextPayment && dayjs(paymentInfo.nextPayment.date).format(DATE_FORMAT)}
        </Typography>

        <LoadingButton
          disabled={
            status !== CreatorPaymentStatus.Paused &&
            status !== CreatorPaymentStatus.ActivePaid &&
            status !== CreatorPaymentStatus.ActiveFree
          }
          variant="contained"
          color={status === CreatorPaymentStatus.Paused ? 'success' : 'error'}
          sx={{ backgroundColor: '#FF4848' }}
          startIcon={status === CreatorPaymentStatus.Paused ? <PlayArrow /> : <Pause />}
          loading={
            updatePaymentStatusMutation.isLoading ||
            !!queryClient.isFetching(`business-details/${id}`)
          }
          onClick={() =>
            updatePaymentStatusMutation.mutate({
              status:
                status === CreatorPaymentStatus.Paused
                  ? PaymentStatus.Active
                  : PaymentStatus.Paused,
            })
          }
        >
          {status === CreatorPaymentStatus.Paused ? 'Resume' : 'Pause'}
        </LoadingButton>
      </Box>
      <Divider sx={{ mt: '12px', mb: '16px' }} />
      <Box display="flex" gap="24px">
        <SelectFabric
          label="Subscription plan"
          placeholder="No plan"
          control={control}
          name="plan"
          options={BUSINESS_PLANS_OPTIONS}
          disabled={disabledForm}
          required
        />
        <TextField
          control={control}
          label="Slots"
          name="slots"
          type="number"
          disabled={disabledForm}
          required
        />
        <TextField
          control={control}
          label="Price per slot"
          name="pricePerSlot"
          type={disabledForm ? 'string' : 'number'}
          disabled={disabledForm}
          required
        />
        <LoadingButton
          variant="contained"
          type={hasPayment ? 'button' : 'submit'}
          sx={{
            display: 'block',
            minWidth: '143px',
            alignSelf: 'start',
            px: '20px',
            marginTop: '30px',
          }}
          // stripe disable
          // disabled={!isValid || !isDirty || disabledForm}
          // disabled={!!true}
          loading={isGenerateLoading || isUpdateLoading}
          onClick={hasPayment ? onOpenChangePlan : undefined}
        >
          {hasPayment ? 'Update plan' : 'Generate link'}
        </LoadingButton>
        <CopyBillingLinkModal open={open} onClose={onClose} link={link} />
      </Box>
      {shouldShowSlotsAlert && (
        <Typography variant="body2" color="text.disabled" mt="12px">
          From next billing date Subscription Plan will be updated to{' '}
          {PLANS_LABEL[nextPlan as Plans]} with {nextSlots} slots and {nextPrice} price per slot.
        </Typography>
      )}
      <ConfirmModal
        open={openChangePlan}
        onCancel={onChangePlanCancel}
        onSubmit={onChangePlanSubmit}
        title=""
        helperText={
          <Typography textAlign="center" mt="8px" maxWidth="336px">
            User will have access to <b>{PLANS_LABEL[defaultValues.plan as Plans]}</b> Subscription
            Plan with <b>{defaultValues.slots} slots</b> and{' '}
            <b>{defaultValues.pricePerSlot} price per slot</b> until the end of the payment period.
            From next billing date Subscription Plan will be updated to{' '}
            <b>{PLANS_LABEL[values.plan as Plans]}</b> with <b>{values.slots} slots</b> and{' '}
            <b>{values.pricePerSlot} price per slot</b>.
          </Typography>
        }
        icon={<ReportIcon color="info" sx={{ fontSize: '100px' }} />}
        submitText="Save"
      />
    </Box>
  );
};
