import React, { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { AxiosResponse } from 'axios';
import { Axios, formatSortDirection, queryFormatted } from '@helpers';
import { CCModelDetailsType, AssetDetailsType } from '@services';

import UploadIcon from '@mui/icons-material/Upload';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

import { Box, Typography, Button, Divider } from '@mui/material';
import { GeneralLinkInfo } from '@molecules';
import { LinksTable } from '@organisms';
import { SubmitLinksModal } from '@organisms/Modals';

interface Props {
  queryKey: string;
}

export const LinksManagement: FC<Props> = ({ queryKey }) => {
  const [submitLinksOpen, setSubmitLinks] = useState(false);

  const { id, assetId } = useParams();
  const [searchParams] = useSearchParams();
  const currentQueries = Object.fromEntries([...searchParams]);
  const queryClient = useQueryClient();

  const accountData = queryClient.getQueryData<CCModelDetailsType | AssetDetailsType>(
    `${queryKey}/${id}`,
  );
  const accountName =
    (accountData as CCModelDetailsType).companyName || (accountData as AssetDetailsType).username;

  const { mutate: downloadCSV } = useMutation<
    AxiosResponse<ArrayBuffer>,
    unknown,
    { [key in string]: unknown }
  >(
    (queries) =>
      Axios.get(`/admin/models/${assetId}/links${queryFormatted(formatSortDirection(queries))}`, {
        responseType: 'blob',
      }),
    {
      onSuccess: async (res) => {
        fileDownload(res.data, 'links.csv');
      },
    },
  );

  const handleOnDownloadCSV = () => {
    downloadCSV({ ...currentQueries, exportToCsv: true, size: -1 });
  };

  const refetchAnalytic = () => {
    queryClient.refetchQueries(`content-creator-general/${assetId}`);
  };

  return (
    <>
      <Box display="flex" mb="16px" justifyContent="space-between">
        <Box>
          <Typography variant="h5" mb="8px">
            General information
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Below is general information about the user related links.
          </Typography>
        </Box>
        <Box display="flex" gap="9px" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="black"
            startIcon={<UploadIcon />}
            onClick={() => setSubmitLinks(true)}
          >
            Upload links
          </Button>
          <Button
            variant="outlined"
            color="black"
            startIcon={<SimCardDownloadIcon />}
            onClick={handleOnDownloadCSV}
          >
            Export to CSV
          </Button>
        </Box>
      </Box>
      <Divider sx={{ my: '16px' }} />
      <GeneralLinkInfo minDate={(accountData as CCModelDetailsType)?.joinDate} />
      <Typography variant="h5" mb="8px">
        User related links
      </Typography>
      <Typography variant="body2" color="text.secondary" mb="16px">
        Here is you can find all information about user related links.
      </Typography>
      {!submitLinksOpen && <LinksTable refetch={refetchAnalytic} />}
      {submitLinksOpen && (
        <SubmitLinksModal
          accountName={accountName}
          open={submitLinksOpen}
          onClose={() => {
            refetchAnalytic();
            setSubmitLinks(false);
          }}
        />
      )}
    </>
  );
};
