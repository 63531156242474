import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { MenuItem, Select } from '@mui/material';

export const DatePickerStyled = styled(DatePicker)`
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  text-indent: 40px;
  outline: none;
  color: ${({ theme }) => theme.palette.text.main};

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.38);
  }

  &:focus,
  &:active {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  &::placeholder {
    font-family: 'Plus Jakarta Sans';
    color: rgba(0, 0, 0, 0.38);
    font-weight: 400;
    font-size: 16px;
  }
`;

export const DatePickerWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    position: absolute;
    left: 13px;
    z-index: 100;
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const SelectStyled = styled(Select)`
  height: 50px;
  background-color: #fff;

  .MuiSelect-select {
    display: flex;
    align-items: center;
    color: ${({ theme, color }) => (color ? theme.palette[color].main : theme.palette.text.main)};
  }

  &.small .MuiSelect-select {
    padding: 4px 12px !important;
  }

  svg {
    margin-right: 8px;
    fill: ${({ theme, color }) => (color ? theme.palette[color].main : 'rgba(0, 0, 0, 0.54)')};
  }
`;

export const MenuItemStyled = styled(MenuItem)`
  align-items: center;
  display: flex;
  color: ${({ theme, color }) =>
    color ? theme.palette[color].main : theme.palette.text.main} !important;

  svg {
    margin-right: 8px;
    fill: ${({ theme, color }) => (color ? theme.palette[color].main : 'rgba(0, 0, 0, 0.54)')};
  }
`;
