import dayjs from 'dayjs';
import { GridCellParams, GridColDef, GridRowModel } from '@mui/x-data-grid';
import { MemberRole, MemberStatus } from '@services';
import { DATE_FORMAT, MEMBER_ROLE_LABEL } from '@constants';

import { MemberStatusChip } from '@atoms';
import { renderActionsButtonMenu } from '@molecules/Table/cells';

export interface MemberData {
  createdAt: string;
  invitedAt: string;
  email: string;
  name: string;
  id: string;
  role: MemberRole;
  status: MemberStatus;
}

const cellClassName = ({ row: { status } }: GridCellParams<MemberData>) =>
  status === MemberStatus.EmailSent ? 'disabled-row' : '';

interface Props {
  onEdit: (row: GridRowModel) => void;
  onBlock: (row: GridRowModel) => void;
}

export const getColumns = ({ onEdit, onBlock }: Props): GridColDef<MemberData>[] => {
  const actionsCreator = (row: GridRowModel) => [
    { name: 'Edit', onClick: () => onEdit(row) },
    ...((row as MemberData).status === MemberStatus.Active
      ? [{ name: 'Block', onClick: () => onBlock(row) }]
      : []),
  ];

  return [
    {
      field: 'name',
      headerName: 'Full name',
      minWidth: 190,
      flex: 1,
      cellClassName,
      renderCell: ({ value }) => <span className="ellipsis">{value || 'No name'}</span>,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 270,
      flex: 1,
      cellClassName,
    },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 170,
      flex: 1,
      cellClassName,
      renderCell: ({ row: { role } }) => MEMBER_ROLE_LABEL[role],
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 230,
      flex: 1,
      renderCell: ({ row: { status, invitedAt } }) => (
        <MemberStatusChip
          status={status}
          text={invitedAt && `(${dayjs(invitedAt).format(DATE_FORMAT)})`}
        />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Join date',
      flex: 2,
      minWidth: 110,
      cellClassName,
      renderCell: ({ row: { createdAt } }) => dayjs(createdAt).format(DATE_FORMAT),
    },
    {
      field: 'action',
      headerName: '',
      width: 54,
      flex: 1,
      renderCell: renderActionsButtonMenu(actionsCreator),
    },
  ];
};
