import React from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Box, ButtonProps, Tooltip } from '@mui/material';
import { Container, NameText, ExtensionText, CloseButton } from './FileItem.styles';

interface Props extends ButtonProps {
  name: string;
  fileIndex?: number;
  onRemove: (e: React.SyntheticEvent, fileIndex?: number) => void;
}

export const FileItem: React.FC<Props> = ({ name, onRemove, fileIndex, ...props }) => {
  const extension = (name.match(/\.[0-9a-z]+$/g)?.[0] || '.unknown').substring(1);

  return (
    <Container
      variant="outlined"
      color="black"
      {...props}
      startIcon={<AttachFileIcon />}
      endIcon={<CloseButton onClick={(e: React.SyntheticEvent) => onRemove(e, fileIndex)} />}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <Tooltip title={name} placement="top">
          <NameText variant="body2">{name}</NameText>
        </Tooltip>
        <ExtensionText variant="body2">{extension}</ExtensionText>
      </Box>
    </Container>
  );
};
