import { FC } from 'react';
import { Typography, Divider, Box, Button, Select, MenuItem } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';

import { Modal, TextField } from '@atoms';
import { BASE_URL, inviteValidation, USER_ROLES } from '@constants';
import { successMessage } from '@services';
import { Axios } from '@helpers';

import { SelectWrap, InputSelectWrap } from './styles';

interface InviteUserModalProps {
  onClose: () => void;
  open: boolean;
}

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  userRole: string;
}

export const InviteUserModal: FC<InviteUserModalProps> = ({ onClose, open }) => {
  const { mutate: invite, isLoading } = useMutation<unknown, unknown, unknown>(
    (data) => Axios.post('/admin/users/invite', data),
    {
      onSuccess: () => {
        onClose();
        successMessage('User has been invited!');
      },
    },
  );

  const { handleSubmit, control, register } = useForm<FormData>({
    resolver: yupResolver(inviteValidation),
    defaultValues: { userRole: USER_ROLES.Manager },
    mode: 'onChange',
    delayError: 700,
  });

  const onSubmit = (data: FormData) => invite({ ...data, redirectUrl: `${BASE_URL}/signup` });

  return (
    <Modal title="Invite user" open={open} onClose={onClose}>
      <Box component="form" display="flex" flexDirection="column" onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="body2" mt="8px" color="text.secondary" mb="24px">
          Invite your teammates to the Ceartas Admin panel to allow managing users and data
        </Typography>
        <Divider />
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gap="24px"
          my="24px"
          justifyContent="space-between"
        >
          <TextField
            name="firstName"
            label="First name"
            placeholder="First User name"
            control={control}
            required
          />
          <TextField
            name="lastName"
            label="Last name"
            placeholder="Last User name"
            control={control}
            required
          />
        </Box>
        <InputSelectWrap>
          <TextField
            name="email"
            label="Email"
            placeholder="contact@email.com"
            control={control}
            required
          />
          <SelectWrap>
            <Select {...register('userRole')} defaultValue={USER_ROLES.Manager}>
              <MenuItem value={USER_ROLES.Manager}>Manager</MenuItem>
              <MenuItem value={USER_ROLES.Admin}>Administrator</MenuItem>
            </Select>
          </SelectWrap>
        </InputSelectWrap>
        <Box display="flex" justifyContent="flex-end" mt="24px">
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>

          <Button
            variant="contained"
            type="submit"
            disabled={isLoading}
            sx={{
              '& svg': {
                marginRight: '9px',
              },
            }}
          >
            <PersonAddIcon /> Send invite
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
