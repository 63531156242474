import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import {
  AssignAssetsParams,
  AssignAssetType,
  successMessage,
  TableBusinessAccount,
  TableContentCreator,
  TableModelAccount,
  UserDetailsType,
} from '@services';
import { Axios, getParams, queryClient } from '@helpers';
import { ROUTES } from '@constants';
import CheckIcon from '@mui/icons-material/Check';

import { Divider, ToggleButton, ToggleButtonGroup, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Button, Modal } from '@atoms';
import {
  AssignAssetCCTable,
  AssignAssetBusinessTable,
  AssignAssetModelTable,
} from './AssignAssetTables';

export const AssignAssetModal: FC = () => {
  const { userId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams({ tab: AssignAssetType.ContentCreator });
  const [selectedMembersId, setSelectedMembersId] = useState<string[]>([]);
  const params = getParams(searchParams) as { tab: AssignAssetType };
  const [tab, setTab] = useState(params.tab || AssignAssetType.ContentCreator);
  const navigate = useNavigate();
  const [backPath, setBackPath] = useState(`/${ROUTES.Users}`);

  const { data } = useQuery<unknown, unknown, UserDetailsType>(`user-detailed/${userId}`, () =>
    Axios.get(`/admin/users/${userId}`).then((res) => res.data),
  );
  const fullName = `${data?.firstName || ''} ${data?.lastName || ''}`;

  const handleOnClose = () => {
    navigate(backPath);
  };

  const handleChangeTab = (event: React.MouseEvent<HTMLElement>, newTab: AssignAssetType) => {
    setTab(newTab);
    setSearchParams({ tab: newTab });
  };

  const getSelectedAccount = <T extends { id: string }>(_items: T[], selectedItemsId: string[]) => {
    setSelectedMembersId(selectedItemsId);
    return '';
  };

  const { mutate: onSubmit, isLoading } = useMutation<
    unknown,
    unknown,
    { managerId: string; selectedIds: string[] }
  >(
    ({ managerId, selectedIds }) => {
      const isTabModel = tab === AssignAssetType.Model;
      const submitParams: AssignAssetsParams = {};
      if (isTabModel) {
        submitParams.modelIds = selectedIds;
      } else {
        submitParams.companyIds = selectedIds;
      }
      return Axios.put(`/admin/users/${managerId}/assign${isTabModel ? '-models' : ''}`, {
        ...submitParams,
      });
    },
    {
      onSuccess: () => {
        const isTabModel = tab === AssignAssetType.Model;
        queryClient.refetchQueries('users');
        successMessage(isTabModel ? 'Assets have been assign!' : 'Accounts have been assign!');
        handleOnClose();
      },
    },
  );

  const handleOnSubmit = () => {
    onSubmit({
      managerId: userId || '',
      selectedIds: selectedMembersId,
    });
  };

  const renderTable = () => {
    switch (tab) {
      case AssignAssetType.Business:
        return (
          <AssignAssetBusinessTable getSelectedAccount={getSelectedAccount<TableBusinessAccount>} />
        );
      case AssignAssetType.ContentCreator:
        return <AssignAssetCCTable getSelectedAccount={getSelectedAccount<TableContentCreator>} />;
      case AssignAssetType.Model:
        return <AssignAssetModelTable getSelectedAccount={getSelectedAccount<TableModelAccount>} />;
      default:
        return <AssignAssetCCTable getSelectedAccount={getSelectedAccount<TableContentCreator>} />;
    }
  };

  const renderAssetType = () => {
    switch (tab) {
      case AssignAssetType.Business:
        return 'Business accounts';
      case AssignAssetType.ContentCreator:
        return 'Content creators';
      case AssignAssetType.Model:
        return 'Assets';
      default:
        return 'Assets';
    }
  };

  const isDisabled = !selectedMembersId.length;

  useEffect(() => {
    const storedPath = sessionStorage.getItem('assign-close-path');
    if (storedPath) {
      setBackPath(storedPath);
    }
  }, []);

  return (
    <Modal title={`Assign ${fullName} to users`} onClose={handleOnClose} width="800px" open>
      <Box display="flex" flexDirection="column">
        <Typography variant="body2" color="text.secondary">
          Here you can assign or reassign manager to the {renderAssetType()}.
        </Typography>
        <Divider sx={{ my: '24px', mx: '-32px' }} />
        <ToggleButtonGroup
          color="primary"
          value={tab}
          exclusive
          onChange={handleChangeTab}
          sx={{ pb: '24px' }}
          fullWidth
        >
          <ToggleButton value={AssignAssetType.ContentCreator}>Content Creator</ToggleButton>
          <ToggleButton value={AssignAssetType.Business}>Business</ToggleButton>
          <ToggleButton value={AssignAssetType.Model}>Assets</ToggleButton>
        </ToggleButtonGroup>
        {renderTable()}
        <Box display="flex" ml="auto" mt="24px">
          <Button variant="text" color="secondary" onClick={handleOnClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="black"
            startIcon={<CheckIcon />}
            disabled={isDisabled}
            loading={isLoading}
            onClick={handleOnSubmit}
          >
            Assign
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};
