import { useState } from 'react';
import { Auth } from 'aws-amplify';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';

export const useSignIn = () => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');

  const signIn = (email: string, password: string) => {
    setIsFetching(true);
    Auth.signIn(email, password)
      .then(() => navigate(`/${ROUTES.Users}`))
      .catch(
        () =>
          setError(
            "That email and password combination didn't match our records. Please try again.",
          ), // this message hardcoded because we should`t show to customer what currently invalid or user with this this email is not created
      )
      .finally(() => setIsFetching(false));
  };

  const signInWithGoogle = () => {
    setIsFetching(true);
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
      .catch((errorM: any) => {
        console.log(errorM);
        setError("Google doesn't work.");
      })
      .finally(() => setIsFetching(false));
  };

  return {
    signIn,
    signInWithGoogle,
    isFetching,
    error,
  };
};
