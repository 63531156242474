import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from '@constants';
import { AppLayout } from '@templates';
import { Content, Inner, Wrap } from '@templates/AppLayout/styles';

export const ProtectedRoute: FC<{ isAuthorized: boolean; noLayout?: boolean }> = ({
  isAuthorized,
  noLayout,
}) => {
  if (!isAuthorized) {
    return <Navigate to={ROUTES.Login} />;
  }

  if (noLayout) {
    return (
      <Wrap>
        <Content>
          <Inner style={{ height: '100%' }}>
            <Outlet />
          </Inner>
        </Content>
      </Wrap>
    );
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};
