import React, { FC } from 'react';
import {
  TableBusinessAccount,
  TableContentCreator,
  TableModelAccount,
  TableSelectType,
} from '@services';
import { Box } from '@mui/material';
import { assignStatusFilter, joinDateFilter, planFilter } from '@molecules/Table/filters';
import { Table } from '@molecules';
import { columns, modelColumns } from '@organisms/Modals/AssignAssetModal/utils';

interface AssignAssetCCTableProps {
  getSelectedAccount: (_items: TableContentCreator[], selectedItemsId: string[]) => string;
}

export const AssignAssetCCTable: FC<AssignAssetCCTableProps> = ({ getSelectedAccount }) => (
  <Box>
    <Table<TableContentCreator>
      searchPlaceholder="Search User by name or Email"
      queryName="modal-assign-model-content-creator"
      apiRoute="/admin/accounts/content-creator"
      columns={columns}
      getSelectedValue={getSelectedAccount}
      selectedRowType={TableSelectType.Assign}
      filtersList={[planFilter(), assignStatusFilter]}
      tableSx={{ maxHeight: '368px' }}
      checkboxSelection
    />
  </Box>
);

interface AssignAssetBusinessTableProps {
  getSelectedAccount: (_items: TableBusinessAccount[], selectedItemsId: string[]) => string;
}

export const AssignAssetBusinessTable: FC<AssignAssetBusinessTableProps> = ({
  getSelectedAccount,
}) => (
  <Box>
    <Table<TableBusinessAccount>
      searchPlaceholder="Search User by name or Email"
      queryName="modal-assign-model-business"
      apiRoute="/admin/accounts/business"
      columns={columns}
      getSelectedValue={getSelectedAccount}
      selectedRowType={TableSelectType.Assign}
      filtersList={[planFilter(true), assignStatusFilter]}
      tableSx={{ maxHeight: '368px' }}
      checkboxSelection
    />
  </Box>
);

interface AssignAssetModelTableProps {
  getSelectedAccount: (_items: TableModelAccount[], selectedItemsId: string[]) => string;
}

export const AssignAssetModelTable: FC<AssignAssetModelTableProps> = ({ getSelectedAccount }) => (
  <Box>
    <Table<TableModelAccount>
      searchPlaceholder="Search asset by name"
      queryName="modal-assign-model"
      apiRoute="/admin/models"
      columns={modelColumns}
      getSelectedValue={getSelectedAccount}
      selectedRowType={TableSelectType.Assign}
      filtersList={[assignStatusFilter, joinDateFilter]}
      tableSx={{ maxHeight: '368px' }}
      checkboxSelection
    />
  </Box>
);
