import { FC } from 'react';

import { ReactComponent as Logo } from '@assets/logo.svg';
import { AuthContainerInner, AuthContainerWrap, LogoWrap } from './styles';

export const AuthContainer: FC = ({ children }) => (
  <AuthContainerWrap>
    <LogoWrap>
      <Logo />
    </LogoWrap>
    <AuthContainerInner>{children}</AuthContainerInner>
  </AuthContainerWrap>
);
