/* eslint-disable react/destructuring-assignment */
import { FC, useState, SyntheticEvent, useEffect } from 'react';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { DATE_FORMAT } from '@constants';

import { Tabs as MuiTabs, Tab, Typography, Box, tabsClasses } from '@mui/material';

interface LinkTabProps {
  label?: string;
  to: string;
}

interface TabProps {
  links: LinkTabProps[];
  modified?: string;
}

const LinkTab: FC<LinkTabProps> = (props: LinkTabProps) => {
  const navigate = useNavigate();

  return <Tab onClick={() => navigate(props.to)} {...props} />;
};

export const Tabs: FC<TabProps> = ({ links, modified }) => {
  const { pathname } = useLocation();
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const newValue = links.findIndex(({ to }) => pathname.includes(to));
    if (newValue !== value) {
      setValue(newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [links, pathname]);

  return (
    <Box display="flex" position="relative">
      <MuiTabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="nav tabs"
        sx={{
          width: '100%',
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        {links.map(({ label, to }) => (
          <LinkTab label={label} key={label} to={to} />
        ))}
      </MuiTabs>
      {modified && (
        <Typography
          variant="button"
          color="#DDBA00"
          sx={{
            borderBottom: '1px solid #E0E0E0',
            width: '280px',
            padding: '12px 0',
            textAlign: 'right',
          }}
        >
          Last modified: {dayjs(modified).format(DATE_FORMAT)}
        </Typography>
      )}
    </Box>
  );
};
