import { FC, ReactNode, useCallback, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { FilterItem, ActiveStatus, TableBusinessAccount } from '@services';
import { ROUTES } from '@constants';

import { SelectChangeEvent } from '@mui/material';
import { Table, StatusFilter } from '@molecules';
import { lastScanningFilter, planFilter } from '@molecules/Table/filters';
import { InnerView } from '@templates';

import { getColumns } from './utils';

const FILTERS_LIST: FilterItem[] = [
  planFilter(true),
  {
    name: 'Status',
    key: 'status',
    getFilter: (onChange, onClose, setFilters, filter): ReactNode => (
      <StatusFilter
        key="status"
        name="Status"
        value={filter.status as ActiveStatus}
        onChange={(event: SelectChangeEvent<unknown>) =>
          setFilters({ ...filter, status: event.target.value as string })
        }
        onClose={onClose}
      />
    ),
  },
  lastScanningFilter,
];

export const BusinessAccountsTable: FC = () => {
  const navigate = useNavigate();
  const onEdit = useCallback(({ id, isRemoved }) => {
    if (id && !isRemoved) {
      sessionStorage.setItem('business-accounts-table', window.location.search || '');
      navigate(`/${ROUTES.BusinessAccounts}/${id}/${ROUTES.AccountDetails}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMoveAssets = useCallback(({ id }) => {
    sessionStorage.setItem(
      'move-assets-close-path',
      `/${ROUTES.Accounts}/${ROUTES.BusinessAccounts}${window.location.search || ''}`,
    );
    navigate(`${id}/${ROUTES.Move}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () =>
      getColumns({
        onEdit,
        onMoveAssets,
      }),
    [onEdit, onMoveAssets],
  );
  return (
    <InnerView>
      <Table<TableBusinessAccount>
        searchPlaceholder="Search for Company name or Email"
        queryName="business-accounts"
        columns={columns}
        apiRoute="/admin/accounts/business"
        onRowClick={({ id, isRemoved }) => {
          if (id && !isRemoved) {
            sessionStorage.setItem('business-accounts-table', window.location.search || '');
            navigate(`/${ROUTES.BusinessAccounts}/${id}/${ROUTES.AccountDetails}`);
          }
        }}
        filtersList={FILTERS_LIST}
      />
      <Outlet />
    </InnerView>
  );
};
