import { FC, ReactNode } from 'react';

import { Box, Typography } from '@mui/material';

import { CardWrap } from './styles';

interface CardProps {
  number: number;
  label: string;
  icon: ReactNode;
}

export const Card: FC<CardProps> = ({ number, label, icon }) => (
  <CardWrap>
    {icon}
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      <Typography variant="h6">{label}</Typography>
      <Typography variant="h4" color="text.secondary">
        {number}
      </Typography>
    </Box>
  </CardWrap>
);
