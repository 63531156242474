import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';
import { DATE_FORMAT } from '@constants';
import { Link, Tooltip } from '@mui/material';

const getInvoiceName = (url: string) => {
  const lastIndex = url.lastIndexOf('/');
  const substringAfterLastSlash = url.substring(lastIndex + 1);
  return substringAfterLastSlash;
};

export const getColumns = (): GridColDef[] => [
  {
    field: 'url',
    headerName: 'Url',
    flex: 1,
    minWidth: 400,
    renderCell: ({ value, row: { url } }) => (
      <Tooltip placement="top" title={value}>
        <Link className="ellipsis" href={url} target="_blank">
          {getInvoiceName(value)}
        </Link>
      </Tooltip>
    ),
  },
  {
    field: 'updatedAt',
    headerName: 'Update date',
    flex: 2,
    minWidth: 170,
    renderCell: ({ row: { updatedAt } }) => dayjs(updatedAt).format(DATE_FORMAT),
  },
  {
    field: 'createdAt',
    headerName: 'Date of addition',
    flex: 2,
    minWidth: 170,
    renderCell: ({ row: { createdAt } }) => dayjs(createdAt).format(DATE_FORMAT),
  },
];
