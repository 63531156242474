import { FC } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LicenseInfo } from '@mui/x-license-pro';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { AppRouter, ErrorBoundary } from '@common';
import { theme } from '@styles';
import { ThemeProvider } from 'styled-components';
import { queryClient } from '@helpers';
import { Helmet } from 'react-helmet';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MIU_CODE || '');
dayjs.extend(relativeTime);

const App: FC = () => (
  <BrowserRouter>
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <Helmet>
              <title>Ceartas Admin</title>
            </Helmet>
            <AppRouter />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              transition={Slide}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              toastClassName="toast-customize"
            />
          </QueryClientProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </ErrorBoundary>
  </BrowserRouter>
);

// ci cd

export default App;
