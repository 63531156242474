import { AssetsType, SocialMediaType } from '@services';
import { assetsConfig, ccMusicAssetConfig } from '@constants';

export const getConfig = (isBusiness: boolean) =>
  isBusiness
    ? assetsConfig
    : {
        ...assetsConfig,
        [AssetsType.Music]: ccMusicAssetConfig,
      };

export const convertSocialMedia = (rawSocialMedias: any) =>
  (rawSocialMedias?.map((item: any) => ({
    nickname: item?.nickname || '',
    fullLink: item?.redirectLink || '',
  })) as SocialMediaType[]) || [];
