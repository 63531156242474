import { FC } from 'react';
import dayjs from 'dayjs';
import { UserActivityType } from '@services';
import { Typography, BoxProps, Tooltip } from '@mui/material';
import { StyledContainer } from './styles';

type Props = BoxProps & UserActivityType;

export const NotificationMessage: FC<Props> = ({ text, createdAt, modelUsername, ...props }) => (
  <StyledContainer {...props}>
    <Typography>{text}</Typography>
    <Tooltip title={dayjs(createdAt).format('DD.MM.YYYY HH:mm')} placement="right-end">
      <Typography
        variant="caption"
        sx={{ display: 'inline-block', marginTop: '8px', color: '#A5A5A6' }}
      >
        {modelUsername && `${modelUsername} - `}
        {dayjs(createdAt).fromNow()}
      </Typography>
    </Tooltip>
  </StyledContainer>
);
