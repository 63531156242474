import styled from 'styled-components';

import { ActiveStatus } from '@services';

export interface UserStatusWrapProps {
  status: ActiveStatus;
  color?: string;
}

export const UserStatusWrap = styled.div<UserStatusWrapProps>`
  height: 32px;
  padding-right: 10px;
  padding-left: 7px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.status[props.color || props.status]};
  border-radius: 16px;
  color: ${(props) => props.theme.palette.common.white};

  svg {
    margin-right: 9px;
  }
`;
