import { Box, styled } from '@mui/material';

export const InputBorder = styled(Box)`
  display: flex;
  padding: 13px 12px;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  height: 50px;
  color: rgba(0, 0, 0, 0.54);

  svg path {
    fill: rgba(0, 0, 0, 0.54);
  }
`;
