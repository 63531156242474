import { FC, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';

import { AssetsType, BusinessDetailsType } from '@services';
import { useSubmitButtonState } from '@contexts/SubmitButton';
import { Axios, onSubmitButtonClick } from '@helpers';
import { ROUTES } from '@constants';
import { useModalState } from '@hooks';

import { Typography, Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { Add } from '@mui/icons-material';
import { AddAssetModal } from '@organisms';

import { BusinessAccountsDetailsForm } from './BusinessAccountsDetailsForm';
import { BusinessBillingPlanForm } from './BusinessBillingPlanForm';
import { Slots } from './Slots';
import { AccountsTable } from './AccountsTable';
import { transformBusinessData } from './utils';

export const BusinessAccountsDetails: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { disabled, loading } = useSubmitButtonState();
  const [isOpen, , onOpen, onClose] = useModalState(false);

  const { data, refetch } = useQuery<unknown, unknown, BusinessDetailsType>(
    `business-details/${id}`,
    () => Axios.get(`/admin/accounts/${id}`).then((res) => res.data),
  );

  const companyInfo = useMemo(() => data && transformBusinessData(data), [data]);

  const onMoveAssets = useCallback(() => {
    sessionStorage.setItem(
      'move-assets-close-path',
      `${window.location.pathname || ''}${window.location.search || ''}`,
    );
    navigate(`${ROUTES.Move}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box mb="16px" display="flex" justifyContent="space-between" alignItems="end">
        <Typography variant="h5" mb="8px">
          General information
        </Typography>
        <LoadingButton
          variant="contained"
          onClick={onSubmitButtonClick}
          disabled={disabled}
          startIcon={<SaveIcon />}
          loading={loading}
        >
          Save changes
        </LoadingButton>
      </Box>
      <Typography mb="16px" variant="body2" color="text.secondary">
        Below is general information about the user and their account.
      </Typography>
      {companyInfo && <BusinessAccountsDetailsForm data={companyInfo} />}
      {companyInfo && <BusinessBillingPlanForm data={companyInfo} refetch={refetch} />}
      <Box mb="16px" display="flex" justifyContent="space-between" alignItems="end">
        <Box width="664px">
          <Typography variant="h5" mt="40px" mb="8px">
            Slots
          </Typography>
          <Typography mb="16px" variant="body2" color="text.secondary">
            Here is you can find the information about all and available slots and list of users
            connected to slots.
          </Typography>
          {companyInfo && <Slots {...companyInfo.slots} />}
        </Box>
        <Box display="flex" gap={3}>
          <Button variant="contained" onClick={onOpen} startIcon={<Add />}>
            Add asset
          </Button>
          <Button variant="contained" onClick={onMoveAssets} startIcon={<DriveFileMoveIcon />}>
            Move assets
          </Button>
        </Box>
      </Box>
      <AccountsTable />
      <Outlet />
      <AddAssetModal
        open={isOpen}
        onClose={onClose}
        assetType={companyInfo?.assetType || AssetsType.Models}
      />
    </>
  );
};
