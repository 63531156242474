import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export const useStateWithReset = <T>(
  initValue: T,
  resetValue: T,
): [T, Dispatch<SetStateAction<T>>, () => void] => {
  const [state, setState] = useState(initValue);

  const resetState = useCallback(() => setState(resetValue), [resetValue]);

  return [state, setState, resetState];
};
