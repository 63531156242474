import React, { FC, ReactNode, SyntheticEvent, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { SOURCE_STATUS_NAME } from '@constants';
import { getParams } from '@helpers';
import { useQueryParams } from '@hooks';
import {
  LinkStatusFilter,
  SearchOptionsCheckbox,
  SubmitInfringingLinkButton,
  Table,
} from '@molecules';
import { SUBMIT_INFRINGING_QUERY_NAME } from '@molecules/SubmitInfringingLinkButton/SubmitInfringingLinkButton';
import { renderDateFilter } from '@molecules/Table/filters';
import { Box, Checkbox, SelectChangeEvent, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FilterItem, LinkManagementType, LinkSourceType, LinkStatuses } from '@services';

import { getColumns } from './utils';

const FILTERS_LIST: FilterItem[] = [
  {
    name: 'Status',
    key: 'status',
    getFilter: (onChange, onClose, setFilters, filter): ReactNode => (
      <LinkStatusFilter
        key="status"
        name="status"
        value={filter.status as LinkStatuses}
        onChange={(event: SelectChangeEvent<unknown>) =>
          setFilters({ ...filter, status: event.target.value as string })
        }
        onClose={onClose}
      />
    ),
  },
  {
    name: 'Period',
    key: 'lastModified',
    resetFields: ['updatedStartDate', 'updatedEndDate'],
    getFilter: renderDateFilter({
      label: 'Period',
      key: 'lastModified',
      resetFields: ['updatedStartDate', 'updatedEndDate'],
    }),
  },
  {
    name: 'Search filter',
    key: 'searchFilter',
    getFilter: (onChange, onClose, setFilters, filter): ReactNode => (
      <SearchOptionsCheckbox
        key="isOnlyOneSpaceSearch"
        name="isOnlyOneSpaceSearch"
        control={<Checkbox inputProps={{ 'aria-label': 'controlled' }} />}
        label="Search with one space"
        value={filter.isOnlyOneSpaceSearch}
        onChange={(event: SyntheticEvent<unknown>, checked: boolean) => {
          setFilters({ ...filter, isOnlyOneSpaceSearch: String(checked) });
        }}
        onClose={() => {
          onClose();
          setFilters({ ...filter, isOnlyOneSpaceSearch: String(false) });
        }}
      />
    ),
  },
];

enum LinkAdminTabs {
  All = 'all',
  GoogleWeb = 'googleWeb',
  GoogleImages = 'googleImages',
  AtSource = 'atSource',
  SubmittedManually = 'submittedManually',
}

export const LinksTable: FC<{ refetch?: () => void }> = (props) => {
  const { assetId, id } = useParams();
  const query = useQueryParams();
  const [searchParams] = useSearchParams();
  const params = getParams(searchParams);
  const [activeTab, setActiveTab] = useState(
    (params as { sourceType?: LinkSourceType })?.sourceType || LinkSourceType.All,
  );
  const onChangeTab = (_e: React.SyntheticEvent, newTab: LinkSourceType) => {
    setActiveTab(newTab);
  };

  const getSelectedValue = (items: LinkManagementType[], selectedItemsId: string[]) => {
    const formatSelectedId = selectedItemsId.flat();
    const selectedItems = items.filter((item) =>
      formatSelectedId.some((_id) =>
        typeof item.id === 'string' ? item.id === _id : item.id.includes(_id),
      ),
    );
    const uniqStatuses = selectedItems.reduce(
      (statuses: string[], { status }) =>
        statuses.includes(status) ? statuses : [...statuses, status],
      [],
    );
    const status = uniqStatuses.length > 1 ? 'mixed' : uniqStatuses[0];
    return status;
  };

  const getSelectedMultipleValue = (items: LinkManagementType[], selectedItemsId: string[]) => {
    const selectedItems = items.filter((item) =>
      selectedItemsId.includes((item as { id: string })?.id),
    );
    const uniqStatuses = selectedItems.reduce(
      (statuses: string[], { status }) =>
        statuses.includes(status) ? statuses : [...statuses, status],
      [],
    );
    const status = uniqStatuses.length > 1 ? 'mixed' : uniqStatuses[0];
    const uniqSourceType = selectedItems.reduce(
      (sourceTypes: (string | undefined)[], { sourceType }) =>
        sourceTypes.includes(sourceType) ? sourceTypes : [...sourceTypes, sourceType],
      [],
    );
    const sourceType = uniqSourceType.length > 1 ? 'mixed' : uniqSourceType[0];
    return { status, sourceType };
  };

  const filterText = query.get('filterText') || '';
  const columns = useMemo(() => getColumns(filterText), [filterText]);
  const preSelectedFilters = useMemo(
    () => ({
      tab: activeTab === LinkSourceType.All ? undefined : activeTab,
    }),
    [activeTab],
  );
  const groupDuplicateLink = (linkArray?: LinkManagementType[]) => {
    const groupedLink =
      (linkArray &&
        linkArray.reduce((acc: Record<string, LinkManagementType[]>, cur: LinkManagementType) => {
          const { url, sourceType, status } = cur;
          const key = `${url}_${sourceType}_${status}`;
          if (!acc[key]) {
            acc[key] = [cur];
          } else {
            acc[key].push(cur);
          }
          return acc;
        }, {})) ||
      {};

    const groupedArray = Object.entries(groupedLink) || [];
    const newLinkData: LinkManagementType[] = [];
    groupedArray.forEach(([, object]) => {
      let mergedObject = object.at(-1);
      const listId = object.map((item) => item.id);

      if (mergedObject) {
        mergedObject = { ...mergedObject, id: JSON.stringify(listId) };
        newLinkData.push(mergedObject);
      }
    });

    return newLinkData;
  };
  return (
    <Box>
      <ToggleButtonGroup
        value={activeTab}
        onChange={onChangeTab}
        color="primary"
        sx={{ marginBottom: '16px', width: 'calc(100% - 204px)' }}
        exclusive
        fullWidth
      >
        <ToggleButton value={LinkAdminTabs.All}>
          {SOURCE_STATUS_NAME[LinkSourceType.All]}
        </ToggleButton>
        <ToggleButton value={LinkAdminTabs.SubmittedManually}>
          {SOURCE_STATUS_NAME[LinkSourceType.SubmittedManually]}
        </ToggleButton>
        <ToggleButton value={LinkAdminTabs.GoogleImages}>
          {SOURCE_STATUS_NAME[LinkSourceType.GoogleImages]}
        </ToggleButton>
        <ToggleButton value={LinkAdminTabs.GoogleWeb}>
          {SOURCE_STATUS_NAME[LinkSourceType.GoogleWeb]}
        </ToggleButton>
        <ToggleButton value={LinkAdminTabs.AtSource}>
          {SOURCE_STATUS_NAME[LinkSourceType.AtSource]}
        </ToggleButton>
      </ToggleButtonGroup>
      <Table
        searchPlaceholder="Search for Link address"
        queryName={`asset-links-${assetId}`}
        columns={columns}
        apiRoute={`/admin/models/${assetId}/links`}
        filtersList={FILTERS_LIST}
        getSelectedValue={getSelectedValue}
        getSelectedMultipleValue={getSelectedMultipleValue}
        checkboxSelection
        preSelectedFilters={preSelectedFilters}
        renderCustomData={groupDuplicateLink}
        renderAdditionalControl={
          <SubmitInfringingLinkButton
            assetId={assetId}
            id={id}
            refetchQueryName={`asset-links-${assetId}`}
          />
        }
        refetchQueryName={SUBMIT_INFRINGING_QUERY_NAME(assetId)}
        {...props}
      />
    </Box>
  );
};
