/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Axios } from '@helpers';
import {
  FieldMode,
  FieldType,
  Field,
  PayloadUpdateTemplates,
  ContractTemplate,
  Type as fType,
  dataFieldType,
} from '@services/types/contracts';
import {
  Box,
  FormControlLabel,
  IconButton,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import { Button, Modal } from '@atoms';
import AddIcon from '@mui/icons-material/Add';
import { successMessage } from '@services';
import {
  CardTemplate,
  ContainerTemplates,
  NoteModal,
  PlusTemplate,
  TagSubmitter,
} from './ESignPage.styles';

interface CustomField extends Field {
  fieldMode: FieldMode;
  fieldType: FieldType | null;
}

const docusealUrl = process.env.REACT_APP_DOCUSEAL_URL;

const BtnActive: FC<{
  data: ContractTemplate;
  onChange: () => void;
  onConfiguration: () => void;
}> = ({ data, onChange, onConfiguration }) => {
  const tooltip = data.active ? 'This template is active' : 'Click to set as contract template';
  const [modal, setModal] = useState(false);
  const [modalConfig, setModalConfig] = useState(false);

  const { mutate: handleActive, isLoading } = useMutation<unknown, unknown, unknown>(
    () =>
      Axios.put(`/admin/contracts/templates/${data.id}`, {
        name: data.name,
        description: data.description,
        config: data.config || [],
        active: true,
      } as PayloadUpdateTemplates),
    {
      onSuccess: () => {
        setModal(false);
        onChange();
        successMessage('Set the template as the default contract successfully!');
      },
    },
  );

  const handleOpenModal = () => {
    if (data.active) return;
    if (data.config === null) {
      setModalConfig(true);
    } else {
      setModal(true);
    }
  };

  return (
    <>
      <Tooltip title={tooltip} placement="left">
        <IconButton onClick={handleOpenModal}>
          {data.active ? <RadioButtonCheckedOutlinedIcon /> : <RadioButtonUncheckedOutlinedIcon />}
        </IconButton>
      </Tooltip>

      <Modal open={modalConfig} onClose={() => setModalConfig(false)}>
        <Typography variant="h6" marginBottom="16px">
          Please configure the template before activating this template!
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            setModalConfig(false);
            onConfiguration();
          }}
        >
          Configuration
        </Button>
      </Modal>

      <Modal
        open={modal}
        onClose={() => !isLoading && setModal(false)}
        title="Set the template as the default contract"
      >
        <Box>
          <p>Are you sure you want to use this form as the default contract for models?</p>
          <Button variant="contained" onClick={handleActive} disabled={isLoading}>
            Agree
          </Button>
        </Box>
      </Modal>
    </>
  );
};

const BtnConfig = forwardRef(
  (
    {
      data,
      comingSoon,
      onSave,
    }: { data: ContractTemplate; comingSoon?: boolean; onSave: () => void },
    ref,
  ) => {
    const initData = (() => {
      // Only take the first submitter
      const submitter = data.docuSealData.submitters[0];
      let fields = data.docuSealData.fields;

      // Filter and remove fields with duplicate names
      const arr = fields.map((field) => field.name);
      fields = fields.filter((field, i) => arr.indexOf(field.name) === i);

      // Filter and remove unsupported fields
      fields = fields.filter((field) => dataFieldType.map((e) => e.type).includes(field.type));

      return {
        ...submitter,
        fields: fields
          .filter((field) => field.submitter_uuid === submitter.uuid && !!field.name)
          .map((field) => {
            const config = data.config?.find((cf) => cf.name === field.name);
            if (!config) {
              return { ...field, fieldMode: FieldMode.Manual, fieldType: null } as CustomField;
            }
            return { ...field, fieldMode: config.mode, fieldType: config.type } as CustomField;
          }),
      };
    })();

    const [modal, setModal] = useState(false);
    const [submitter, setSubmitters] = useState(initData);
    const otherSubmitters = (() => data.docuSealData.submitters.slice(1))();

    const { mutate: handleActive, isLoading } = useMutation<unknown, unknown, unknown>(
      () =>
        Axios.put(`/admin/contracts/templates/${data.id}`, {
          name: data.name,
          description: data.description,
          config: submitter.fields.map((field) => ({
            mode: field.fieldMode,
            name: field.name,
            type: field.fieldType,
          })),
          active: data.active,
        } as PayloadUpdateTemplates),
      {
        onSuccess: () => {
          setModal(false);
          onSave();
          successMessage('Configure the default value of the contract has been updated!');
        },
      },
    );

    const changeFieldMode = (mode: FieldMode, idField: string) => {
      const newSubmitter = submitter;
      const indexField = newSubmitter.fields?.findIndex((field) => field.uuid === idField);
      if (indexField <= -1) return;
      newSubmitter.fields[indexField].fieldMode = mode;
      newSubmitter.fields[indexField].fieldType =
        mode === FieldMode.Manual
          ? null
          : getOptions(newSubmitter.fields[indexField].type)[0].field;
      setSubmitters(() => ({ ...newSubmitter }));
    };

    const changeFieldType = (type: FieldType, idField: string) => {
      const newSubmitter = submitter;
      const indexField = newSubmitter.fields?.findIndex((field) => field.uuid === idField);
      if (indexField <= -1) return;
      newSubmitter.fields[indexField].fieldType = type;
      setSubmitters(() => ({ ...newSubmitter }));
    };

    const replateEnum = (text: string | null | undefined) => {
      if (!text) return '';
      const result = text.split('_').join(' ');
      return result.charAt(0).toUpperCase() + result.slice(1);
    };

    const getOptions = (type: fType) => {
      const options = dataFieldType.find((t) => t.type === type);
      if (!options) return [];
      return options.fieldType.map((field) => ({ field, label: replateEnum(field) }));
    };

    useImperativeHandle(ref, () => ({
      openModal: () => setModal(true),
    }));

    return (
      <>
        <Tooltip
          title={comingSoon ? 'Coming soon' : 'Edit the default value of the contract'}
          placement="left"
        >
          <IconButton onClick={() => !comingSoon && setModal(true)}>
            <DrawOutlinedIcon />
          </IconButton>
        </Tooltip>

        <Modal
          open={modal}
          onClose={() => setModal(false)}
          title="Configure to automatically fill in information into the contract"
        >
          <Box sx={{ marginTop: '16px', marginBottom: '16px' }}>
            <Typography fontWeight={700}>
              First submitter: <TagSubmitter>{submitter.name}</TagSubmitter>
            </Typography>
            <Box sx={{ paddingLeft: '16px' }}>
              {submitter.fields.map((field) => (
                <Box key={field.uuid} sx={{ marginBottom: '8px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography sx={{ flex: '1', lineHeight: '38px' }}>
                      {field.name || field.uuid}
                    </Typography>
                    <RadioGroup row>
                      {Object.values(FieldMode).map((mode) => (
                        <FormControlLabel
                          key={mode}
                          checked={field.fieldMode === mode}
                          control={<Radio size="small" />}
                          label={replateEnum(mode)}
                          onClick={() => changeFieldMode(mode, field.uuid)}
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                  {field.fieldMode !== FieldMode.Manual && (
                    <Select
                      value={field.fieldType}
                      onChange={(e) => changeFieldType(e.target.value as FieldType, field.uuid)}
                      size="small"
                    >
                      {getOptions(field.type).map((o) => (
                        <MenuItem key={o.field} value={o.field}>
                          {o.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Box>
              ))}
              {!submitter.fields.length && (
                <Typography>
                  No fields are defined <br /> Go back to the Docuseal panel and name your fields{' '}
                  <br />{' '}
                  <span style={{ fontSize: '14px', fontWeight: '600', fontStyle: 'italic' }}>
                    *Note: field names must be unique
                  </span>
                </Typography>
              )}
            </Box>
          </Box>

          {otherSubmitters.length ? (
            <Box>
              <Typography fontWeight={700}>
                Other submitter:{' '}
                {otherSubmitters.map((sub) => (
                  <TagSubmitter key={sub.uuid}>{sub.name}</TagSubmitter>
                ))}
              </Typography>
            </Box>
          ) : null}

          <Button
            variant="contained"
            onClick={handleActive}
            disabled={isLoading}
            sx={{ margin: '16px 0' }}
          >
            Save
          </Button>

          <NoteModal>
            <h6>Notes</h6>
            <ul>
              <li>The first submitter is the admin/representative of Ceartas</li>
              <li>
                In case the first submitter has no defined fields, it can still be saved and
                activated
              </li>
              <li>
                For fields designated as Manual, you need to go into the contract to fill in the
                fields contained therein every time a model creates a contract with Ceartas
              </li>
              <li>
                For fields designated as Auto, you will need to select the type of data that the
                system will automatically fill in on your behalf
              </li>
              <li>
                Fields currently supported for auto-input:{' '}
                {dataFieldType.map((type) => type.type).join(', ')} <br />
                Unsupported fields will not be displayed here, so you also need to go into the
                contract to do it manually!
              </li>
            </ul>
          </NoteModal>
        </Modal>
      </>
    );
  },
);

const Template: FC<{ data: ContractTemplate; refresh: () => void }> = ({ data, refresh }) => {
  const {
    active,
    docuSealData: {
      name,
      created_at,
      author: { first_name, last_name, email },
      id,
    },
  } = data;

  const createdAt = dayjs(created_at).format('LLL');
  const author = (() => {
    const fullName = [first_name, last_name].join(' ').trim();
    if (fullName.length) return fullName;
    return email;
  })();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refConfig = useRef<any>();

  const openConfig = () => {
    if (refConfig.current) refConfig.current.openModal();
  };

  return (
    <CardTemplate
      className={active ? 'active' : ''}
      href={`${docusealUrl}/templates/${id}`}
      target="_blank"
    >
      <Box>
        <h1 style={{ lineHeight: '28px', wordBreak: 'break-word' }}>{name}</h1>
        <p>
          <PersonOutlineOutlinedIcon fontSize="small" /> {author}
        </p>
        <p>
          <CalendarMonthOutlinedIcon fontSize="small" /> {createdAt}
        </p>
      </Box>
      <Box
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <BtnActive data={data} onChange={refresh} onConfiguration={openConfig} />
        <BtnConfig data={data} ref={refConfig} onSave={refresh} />
      </Box>
    </CardTemplate>
  );
};

const ESignTemplates: FC<unknown> = () => {
  const { data, isLoading, refetch } = useQuery<unknown, unknown, ContractTemplate[]>(
    ['eSign'],
    () => Axios.get('/admin/contracts/templates').then((res) => res.data),
  );

  return isLoading ? (
    <ContainerTemplates>
      <Skeleton variant="rounded" height={160} />
      <Skeleton variant="rounded" height={160} />
      <Skeleton variant="rounded" height={160} />
      <Skeleton variant="rounded" height={160} />

      <Skeleton variant="rounded" height={160} />
      <Skeleton variant="rounded" height={160} />
      <Skeleton variant="rounded" height={160} />
      <Skeleton variant="rounded" height={160} />

      <Skeleton variant="rounded" height={160} />
      <Skeleton variant="rounded" height={160} />
      <Skeleton variant="rounded" height={160} />
      <Skeleton variant="rounded" height={160} />
    </ContainerTemplates>
  ) : (
    <ContainerTemplates>
      {data?.map((el) => <Template key={el.id} data={el} refresh={refetch} />)}
      <Link href={docusealUrl} target="_blank">
        <PlusTemplate>
          <AddIcon fontSize="large" />
        </PlusTemplate>
      </Link>
    </ContainerTemplates>
  );
};

export default ESignTemplates;
