import { Dispatch, SetStateAction } from 'react';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { TableBusinessAccount, TableContentCreator } from '@services';
import { removedCellClassName } from '@helpers';

import { InfoCell } from '@atoms';

export const columns: GridColDef<TableContentCreator | TableBusinessAccount>[] = [
  {
    field: 'contentCreator',
    headerName: 'Name',
    minWidth: 230,
    flex: 1,
    cellClassName: removedCellClassName,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.contentCreator || params.row.companyName,
    renderCell: ({ value, row: { email, logo } }) => (
      <InfoCell name={value} email={email} avatarUrl={logo} />
    ),
  },
];

export const validateLink =
  (setErrors: Dispatch<SetStateAction<number[]>>) => (link: string, index: number) => {
    if (link.length < 2) {
      setErrors((errors) => (errors.includes(index + 1) ? errors : [...errors, index + 1]));
    }
  };

export interface FinalFormData {
  companyIds: string[];
  domains?: string[];
  file?: File | null;
}
