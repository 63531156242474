import { ReactNode } from 'react';
import { ActiveStatus, FilterItem } from '@services';
import { SelectChangeEvent } from '@mui/material';
import { StatusFilter } from '@molecules';

export const paymentStatusFilter: FilterItem = {
  name: 'Payment status',
  key: 'status',
  getFilter: (onChange, onClose, setFilters, filter): ReactNode => (
    <StatusFilter
      label="Payment status"
      key="status"
      name="Payment status"
      value={filter.status as ActiveStatus}
      onChange={(event: SelectChangeEvent<unknown>) =>
        setFilters({ ...filter, status: event.target.value as string })
      }
      onClose={onClose}
    />
  ),
};
