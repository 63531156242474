import { FC } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { ROUTES } from '@constants';
import { useModalState } from '@hooks';
import { CompanyType } from '@services';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import UploadIcon from '@mui/icons-material/Upload';

import { Box, Button, Typography } from '@mui/material';
import { Tabs } from '@molecules/Tabs';
import { CreateAccountModal } from '@organisms';

const links = [
  { label: 'Content creators', to: ROUTES.ContentCreators },
  { label: 'Business accounts', to: ROUTES.BusinessAccounts },
];

interface Props {
  title: string;
  hasCreateAccount?: boolean;
  hasAddWhitelist?: boolean;
}

export const AccountsTabs: FC<Props> = ({ title, hasCreateAccount, hasAddWhitelist }) => {
  const [isOpenCreateAcc, , openCreateAccModal, closeCreateAccModal] = useModalState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isBusiness = pathname.includes(links[1].to);

  const handleOpenWhitelistModal = () => {
    const companyRoute = isBusiness ? ROUTES.BusinessAccounts : ROUTES.ContentCreators;
    sessionStorage.setItem(
      'add-whitelist-close-path',
      `/${ROUTES.Whitelist}/${companyRoute}${window.location.search || ''}`,
    );
    navigate(`${companyRoute}/${ROUTES.Add}`);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">{title}</Typography>
        {hasCreateAccount && (
          <Button
            variant="contained"
            type="submit"
            startIcon={<PersonAddIcon />}
            onClick={openCreateAccModal}
          >
            Add business account
          </Button>
        )}
        {hasAddWhitelist && (
          <Button
            variant="contained"
            type="submit"
            startIcon={<UploadIcon />}
            onClick={handleOpenWhitelistModal}
          >
            Upload links
          </Button>
        )}
      </Box>
      <Box mt="20px" mb="24px">
        <Tabs links={links} />
      </Box>
      <Outlet />
      {hasCreateAccount && isOpenCreateAcc && (
        <CreateAccountModal
          open={isOpenCreateAcc}
          onClose={closeCreateAccModal}
          companyType={CompanyType.Business}
          isBusiness={isBusiness}
        />
      )}
    </>
  );
};
