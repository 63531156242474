import { FC } from 'react';

import { Header, Sidebar } from '@organisms';

import { Content, Wrap, Inner } from './styles';

export const AppLayout: FC = ({ children }) => (
  <Wrap>
    <Sidebar />
    <Content>
      <Header /> <Inner id="pageInner">{children}</Inner>
    </Content>
  </Wrap>
);
