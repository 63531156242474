import { FilterItem } from '@services';
import { renderDateFilter } from './renderDateFilter';

export const lastScanningFilter: FilterItem = {
  name: 'Last scanning',
  key: 'lastScanningFilter',
  resetFields: ['scanningStartDate', 'scanningEndDate'],
  getFilter: renderDateFilter({
    label: 'Last scanning',
    key: 'lastScanningFilter',
    resetFields: ['scanningStartDate', 'scanningEndDate'],
  }),
};
