/* eslint-disable */
import { Box, Checkbox, Collapse, Typography } from '@mui/material';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { StyledCollapseTableChildWrapper } from './CollapseTableRow.styles';
import { GridColumns } from '@mui/x-data-grid';
import CollapseTableChild from './CollapseTableChild';
import useShiftKeyDetector from './hook';

type Props = {
  row: {
    label: string;
    id: any;
    child: any[];
  };
  columns: GridColumns;
  onRowClick?: (row: any) => void;
  checkboxSelection?: boolean;
  setSelectedItemsRecord?: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
  selectedItems?: string[];
  selectedRowIndex: number;
  items: {
    label: string;
    id: any;
    child: any[];
  }[];
  selectedItemsRecord: Record<string, string[]>;
};

const CollapseTableRow: React.FC<Props> = memo(
  ({
    row,
    columns,
    onRowClick,
    checkboxSelection,
    selectedItems,
    setSelectedItemsRecord,
    selectedRowIndex,
    items,
    selectedItemsRecord,
  }) => {
    const { label, child, id: rowId } = row;
    const [isExpanded, setExpanded] = useState<boolean>(true);
    const id = useMemo(() => rowId, []);
    const checked = useMemo(
      () => !!selectedItems && !!(selectedItems?.length > 0),
      [selectedItems, id],
    );
    const isShiftPressed = useShiftKeyDetector();

    const setSelectedItems: (value: string[]) => void = useCallback((selected: string[]) => {
      setSelectedItemsRecord?.((prev) => {
        if (typeof prev !== 'function') prev[id] = selected ?? [];
        return { ...prev };
      });
    }, []);

    const onCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!checkboxSelection || !setSelectedItems) return;
      if (isShiftPressed && Object.keys(selectedItemsRecord).length) {
        const selectedItemsRecordKeys = Object.keys(selectedItemsRecord).map((key) =>
          parseInt(key, 10),
        );
        const maxSelectedRowIndex = Math.max(...selectedItemsRecordKeys);
        const minSelectedRowIndex = Math.min(...selectedItemsRecordKeys);
        const closestFirstItemIndex =
          Object.keys(selectedItemsRecord)
            .filter((item) => parseInt(item) < selectedRowIndex)
            .reduce<string | undefined>(
              (prev, curr) =>
                prev === undefined ||
                selectedRowIndex - parseInt(curr) < selectedRowIndex - parseInt(prev)
                  ? curr
                  : prev,
              undefined,
            ) || null;

        if (selectedRowIndex < minSelectedRowIndex) {
          const minSelectedRows = items.filter(
            (item) => item.id >= selectedRowIndex && item.id < minSelectedRowIndex,
          );
          setSelectedItemsRecord?.((prev) => {
            if (event.target.checked) {
              const newState = minSelectedRows.reduce((acc: { [key: number]: string[] }, curr) => {
                acc[curr.id] = curr.child.map((item) => item.id);
                return acc;
              }, {});

              return { ...prev, ...newState };
            } else {
              delete prev[id];
            }
            return { ...prev };
          });
        } else if (selectedRowIndex > maxSelectedRowIndex) {
          const maxSelectedRows = items.filter(
            (item) => item.id > maxSelectedRowIndex && item.id <= selectedRowIndex,
          );
          setSelectedItemsRecord?.((prev) => {
            if (event.target.checked) {
              const newState = maxSelectedRows.reduce((acc: { [key: number]: string[] }, curr) => {
                acc[curr.id] = curr.child.map((item) => item.id);
                return acc;
              }, {});

              return { ...prev, ...newState };
            } else {
              delete prev[id];
            }
            return { ...prev };
          });
        } else if (closestFirstItemIndex) {
          const closestSelectedRows = items.filter(
            (item) => item.id > closestFirstItemIndex && item.id <= selectedRowIndex,
          );
          setSelectedItemsRecord?.((prev) => {
            if (event.target.checked) {
              const newState = closestSelectedRows.reduce(
                (acc: { [key: number]: string[] }, curr) => {
                  acc[curr.id] = curr.child.map((item) => item.id);
                  return acc;
                },
                {},
              );

              return { ...prev, ...newState };
            } else {
              delete prev[id];
            }
            return { ...prev };
          });
        }
      } else {
        setSelectedItemsRecord?.((prev) => {
          if (event.target.checked) {
            prev[id] = child.map((item) => item.id);
          } else {
            delete prev[id];
          }
          return { ...prev };
        });
      }
    };

    return (
      <Box
        sx={{
          marginRight: '8px',
        }}
      >
        <Box
          sx={{
            cursor: 'pointer',
            background: 'rgba(0, 0, 0, 0.04)',
            padding: '4px 8px',
            borderRadius: '8px',
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
          }}
          onClick={() => setExpanded(!isExpanded)}
        >
          {checkboxSelection && (
            <Checkbox
              checked={checked}
              onChange={onCheckAll}
              onClick={(e) => e.stopPropagation()}
            />
          )}
          <Typography sx={{ fontWeight: 700 }}>
            {label}
            <Typography component={'span'}> ({child?.length})</Typography>
          </Typography>
        </Box>
        <Collapse in={isExpanded} unmountOnExit>
          <StyledCollapseTableChildWrapper>
            {child.map((item) => (
              <CollapseTableChild
                key={item?.id}
                row={child}
                value={item}
                columns={columns}
                checkboxSelection={checkboxSelection}
                onRowClick={onRowClick}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
              />
            ))}
          </StyledCollapseTableChildWrapper>
        </Collapse>
      </Box>
    );
  },
);

export default CollapseTableRow;
