import { FC } from 'react';
import { TableModelAccount, TableSelectType } from '@services';
import { Box } from '@mui/material';
import { assignStatusFilter, joinDateFilter } from '@molecules/Table/filters';
import { Table } from '@molecules';
import { columns } from '@organisms/Modals/MoveAssetModal/utils';

interface AssignAssetModelTableProps {
  getSelectedAccount: (_items: TableModelAccount[], selectedItemsId: string[]) => string;
}

export const MoveAssetModelTable: FC<AssignAssetModelTableProps> = ({ getSelectedAccount }) => (
  <Box>
    <Table<TableModelAccount>
      searchPlaceholder="Search asset by name"
      queryName="modal-assign-model"
      apiRoute="/admin/models"
      columns={columns}
      getSelectedValue={getSelectedAccount}
      selectedRowType={TableSelectType.Assign}
      filtersList={[assignStatusFilter, joinDateFilter]}
      tableSx={{ maxHeight: '368px' }}
      checkboxSelection
    />
  </Box>
);
